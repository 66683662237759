import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";

import { User } from "@/DatabaseControls/DataTypes";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { getDefaultUser } from "@/DatabaseControls/DataDefaultValues";

import UserInfo from "./UserInfo";

import _ from "lodash";

const UserWindow = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [userState, setUserState] = useState<User | null>(null);
  const [passwordInput, setPasswordInput] = useState<string>("");

  useEffect(() => {
    if (params.id) {
      if (location.state?.user) {
        setUserState(location.state.user);
      } else {
        getUser().then((result) => {
          if (result.status) {
            setUserState(result.data);
          } else {
            navigate("/users");
          }
        });
      }
    } else {
      setUserState(getDefaultUser());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.state]);

  const getUser = async () => {
    return await firebaseSingleton.getUser({ id: params.id });
  };

  const createNewUser = async () => {
    return await firebaseSingleton
      .createAuthAndUser({ user: userState, password: passwordInput })
      .then((res) => {
        if (res.status) {
          return { status: true, message: "Account created successfully!" };
        } else {
          return { status: false, message: "Account creation failed! (1)" };
        }
      })
      .catch(() => {
        return { status: false, message: "Account create failed! (2)" };
      });
  };

  const saveUser = async () => {
    setIsLoading(true);
    resetSnackbarProps();

    let status = false;
    let message = "User record update failed!";

    if (params.id) {
      if (userState.Username) {
        await firebaseSingleton.updateUser(userState).then(() => {
          navigate("/users");
          status = true;
          message = "User record updated successfully.";
        });
      } else {
        message = "Please fill in all the requried (*) fields.";
      }
    } else {
      if (userState.Username && userState.Email && passwordInput) {
        if (!userState.Email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          message = "Invalid email format!";
        } else if (passwordInput.length < 8) {
          message =
            "Invalid password! Password must be at least 8 characters in length.";
        } else {
          await createNewUser().then((res) => {
            if (res.status) navigate("/users");
            status = res.status;
            message = res.message;
          });
        }
      } else {
        message = "Please fill in all the requried (*) fields.";
      }
    }

    setSnackbarProps({
      open: true,
      icon: status ? "notifications" : "report",
      iconColor: status ? "info" : "error",
      content: message,
    });

    setIsLoading(false);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {userState && (
          <VuiBox my={3} mb="30px">
            <VuiBox mx={1} mt={1} mb={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <VuiTypography variant="h4" fontWeight="medium" color="white">
                    {userState?.Username || "New User"}
                  </VuiTypography>
                  <VuiBox mt={1} mb={2}>
                    <VuiTypography variant="body2" color="text">
                      {t("common.pages.edit_description", {
                        title: _.toLower(t("modules.users.title_singular")),
                      })}
                    </VuiTypography>
                  </VuiBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VuiBox display="flex" justifyContent="flex-end">
                    <VuiButton
                      variant="contained"
                      color="info"
                      onClick={saveUser}
                      disabled={isLoading}
                    >
                      {t("common.buttons.save")}
                    </VuiButton>
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12}>
                <UserInfo
                  userState={userState}
                  setUserState={setUserState}
                  passwordInput={passwordInput}
                  setPasswordInput={setPasswordInput}
                  isEdit={!!params.id}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </VuiBox>
        )}
      </Grid>
    </Grid>
  );
};

export default UserWindow;

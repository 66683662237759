import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiButton from "@/Components/VuiButton";
import VuiTypography from "@/Components/VuiTypography";

import CatalogInfo from "./CatalogInfo";
import FurnituresModal from "./Modal/FurnituresModal";
import LightsModal from "./Modal/LightsModal";
import MaterialsModal from "./Modal/MaterialsModal";
import ElectricalSwitchModal from "./Modal/ElectricalSwitchModal";

import {
  Catalog,
  Furniture,
  ElectricalSwitch,
  Light,
  Material,
  ModelBrand,
} from "@/DatabaseControls/DataTypes";

import { getDefaultCatalog } from "@/DatabaseControls/DataDefaultValues";

import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import { useAtomValue } from "jotai";
import { currentUserRoleState } from "@/states/auth";

import _ from "lodash";

const CatalogWindow = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [catalogState, setCatalogState] = useState<Catalog | null>(null);
  const [modelBrandList, setModelBrandList] = useState([] as ModelBrand[]);
  const [furnitureList, setFurnitureList] = useState([] as Furniture[]);
  const [electricalSwitchList, setElectricalSwitchList] = useState(
    [] as ElectricalSwitch[],
  );
  const [lightList, setLightList] = useState([] as Light[]);
  const [wallMaterialList, setWallMaterialList] = useState([] as Material[]);
  const [floorMaterialList, setFloorMaterialList] = useState([] as Material[]);
  const [constructionMaterialList, setConstructionMaterialList] = useState(
    [] as Material[],
  );
  const [otherMaterialList, setOtherMaterialList] = useState([] as Material[]);

  const currentUserRole = useAtomValue(currentUserRoleState);

  const isEdit = !!catalogState?.id;

  useEffect(() => {
    if (params.id) {
      getCatalog().then((result) => {
        if (result.status) {
          setCatalogState(result.data);
        } else {
          navigate("/catalogs");
        }
      });
    } else {
      setCatalogState(getDefaultCatalog());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.state]);

  useEffect(() => {
    if (catalogState) {
      refreshData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogState]);

  const refreshData = () => {
    Promise.all([
      getDataList<Furniture>("furniture", catalogState.Furnitures),
      getDataList<ElectricalSwitch>(
        "electricalSwitch",
        catalogState.ElectricalSwitches,
      ),
      getDataList<Light>("light", catalogState.Lights),
      getDataList<Material>("material", catalogState.WallMaterials),
      getDataList<Material>("material", catalogState.FloorMaterials),
      getDataList<Material>("material", catalogState.ConstructionMaterials),
      getDataList<Material>("material", catalogState.OtherMaterials),
      getDataList<ModelBrand>("modelBrand"),
    ]).then((data) => {
      const [
        furnitureList,
        electricalSwitchList,
        lightList,
        wallMaterialList,
        floorMaterialList,
        constructionMaterialList,
        otherMaterialList,
        modelBrandList,
      ] = data;

      setFurnitureList(furnitureList);
      setElectricalSwitchList(electricalSwitchList);
      setLightList(lightList);
      setWallMaterialList(wallMaterialList);
      setFloorMaterialList(floorMaterialList);
      setConstructionMaterialList(constructionMaterialList);
      setOtherMaterialList(otherMaterialList);
      setModelBrandList(modelBrandList);
    });
  };

  const getDataList = async <
    T extends Furniture | Light | Material | ModelBrand | ElectricalSwitch,
  >(
    name:
      | "furniture"
      | "light"
      | "material"
      | "modelBrand"
      | "electricalSwitch",
    ids?: T["id"][],
  ): Promise<T[]> => {
    const rawList: Map<string, T> = await firebaseSingleton.getDatabaseList(
      name,
    );

    const list: T[] = Array.from(rawList, ([_, item]) => item);

    const filteredList = ids
      ? list.filter((item) => {
          return ids?.includes(item.id);
        })
      : list;

    return filteredList;
  };

  const getCatalog = async () => {
    return await firebaseSingleton.getCatalog(params.id);
  };

  const assignToCompany = async (id: Catalog["id"]): Promise<void> => {
    const userRoleCompany = currentUserRole.AssociatedCompany;

    return await firebaseSingleton
      .getCompany(userRoleCompany.id)
      .then(async (result) => {
        if (result.status) {
          const company = result.data;

          company.AccessibleCatalogues = _.sortBy(
            _.uniq([...(company.AccessibleCatalogues || []), id]),
          );

          return await firebaseSingleton.updateCompany(company);
        }
      });
  };

  const saveCatalog = async () => {
    setIsLoading(true);
    resetSnackbarProps();

    const newCatalog = {
      ...catalogState,
      id: catalogState.id || firebaseSingleton.getNewDocumentId("catalog"),
    };

    if (params.id) {
      await firebaseSingleton.updateCatalog(newCatalog).then(async () => {
        await assignToCompany(newCatalog.id);

        navigate("/catalogs");

        setIsLoading(false);
        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: "Catalog record updated successfully.",
        });
      });
    } else {
      await firebaseSingleton
        .addCatalog(newCatalog, newCatalog.id)
        .then(async () => {
          await assignToCompany(newCatalog.id);

          navigate("/catalogs");

          setIsLoading(false);
          setSnackbarProps({
            open: true,
            icon: "check_circle",
            content: "Catalog record created successfully.",
          });
        });
    }
  };

  const deleteCatalog = async () => {
    setIsLoading(true);
    resetSnackbarProps();

    await firebaseSingleton.deleteCatalog(catalogState.id).then(async () => {
      navigate("/catalogs");
      setIsLoading(false);

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Catalog record deleted successfully.",
      });
    });
  };

  return (
    <>
      <FurnituresModal
        catalogState={catalogState}
        setCatalogState={setCatalogState}
        refreshData={refreshData}
        modelBrandList={modelBrandList}
      />

      <LightsModal
        catalogState={catalogState}
        setCatalogState={setCatalogState}
        refreshData={refreshData}
        modelBrandList={modelBrandList}
      />

      <MaterialsModal
        catalogState={catalogState}
        setCatalogState={setCatalogState}
        refreshData={refreshData}
        modelBrandList={modelBrandList}
      />

      <ElectricalSwitchModal
        catalogState={catalogState}
        setCatalogState={setCatalogState}
        refreshData={refreshData}
        modelBrandList={modelBrandList}
      />

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {catalogState && (
            <VuiBox my={3} mb="30px">
              <VuiBox mx={1} mt={1} mb={3}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <VuiTypography
                      variant="h4"
                      fontWeight="medium"
                      color="white"
                    >
                      {catalogState?.Name || "New Catalog"}
                    </VuiTypography>
                    <VuiBox mt={1} mb={2}>
                      <VuiTypography variant="body2" color="text">
                        {t("common.pages.edit_description", {
                          title: _.toLower(
                            t("modules.catalogs.title_singular"),
                          ),
                        })}
                      </VuiTypography>
                    </VuiBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <VuiBox display="flex" justifyContent="flex-end" gap={1}>
                      {isEdit && (
                        <VuiButton
                          variant="contained"
                          color="error"
                          onClick={deleteCatalog}
                        >
                          {t("common.buttons.delete")}
                        </VuiButton>
                      )}

                      <VuiButton
                        variant="contained"
                        color="info"
                        onClick={saveCatalog}
                        disabled={isLoading}
                      >
                        {t("common.buttons.save")}
                      </VuiButton>
                    </VuiBox>
                  </Grid>
                </Grid>
              </VuiBox>
              <Grid container spacing={3} justifyContent="space-between">
                <Grid item xs={12}>
                  <CatalogInfo
                    catalogState={catalogState}
                    setCatalogState={setCatalogState}
                    furnitureList={furnitureList}
                    electricalSwitchList={electricalSwitchList}
                    lightList={lightList}
                    wallMaterialList={wallMaterialList}
                    floorMaterialList={floorMaterialList}
                    constructionMaterialList={constructionMaterialList}
                    otherMaterialList={otherMaterialList}
                    isEdit={!!params.id}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </Grid>
              </Grid>
            </VuiBox>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CatalogWindow;

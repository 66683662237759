import React from "react";

import Card from "@mui/material/Card";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiFormField from "@/Components/VuiFormField";
import VuiSelect from "@/Components/VuiSelect";

import {
  Light,
  ModelBrand,
  ModelVariantMapper,
} from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

function LightInfo({
  light,
  setLightState,
  modelBrandList,
}: {
  light?: Light;
  setLightState: (x: Light) => void;
  modelBrandList: ModelBrand[];
}) {
  const { t } = useTranslation();

  const handleSetName = (e) => {
    const { Name, ...rest } = light;
    setLightState({ Name: e.target.value, ...rest });
  };

  const handleSetCost = (e) => {
    const { Cost, ...rest } = light;
    setLightState({ Cost: e.target.value, ...rest });
  };

  const handleSetPurchaseURL = (e) => {
    const { PurchaseURL, ...rest } = light;
    setLightState({ PurchaseURL: e.target.value, ...rest });
  };

  const handleSetVariant = (e) => {
    const { Variant, ...rest } = light;
    setLightState({ Variant: e.value, ...rest });
  };

  const handleSetBrand = (e) => {
    const { Brand, ...rest } = light;
    setLightState({ Brand: e.value, ...rest });
  };

  const brandOptions = modelBrandList.map((modelBrand) => ({
    value: modelBrand.id,
    label: modelBrand.Name,
  }));

  return (
    <Card sx={{ overflow: "visible" }}>
      <VuiBox>
        <VuiTypography mb={1.5} variant="h5" color="white">
          {t("modules.lights.section_titles.information")}
        </VuiTypography>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.data.name")}
            defaultValue={light?.Name}
            onChange={handleSetName}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.data.cost")}
            defaultValue={light?.Cost}
            onChange={handleSetCost}
          />
        </VuiBox>

        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.light.data.variant")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={{
              value: light?.Variant || "0",
              label: ModelVariantMapper[light?.Variant || "0"],
            }}
            onChange={handleSetVariant}
            options={Object.keys(ModelVariantMapper).map((key) => ({
              value: key,
              label: ModelVariantMapper[key],
            }))}
          />
        </VuiBox>

        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.lights.data.brand")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              light?.Brand
                ? brandOptions.find((brand) => brand.value === light?.Brand) ||
                  null
                : null
            }
            onChange={handleSetBrand}
            options={brandOptions}
          />
        </VuiBox>

        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.data.purchase_link")}
            defaultValue={light?.PurchaseURL}
            onChange={handleSetPurchaseURL}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default LightInfo;

import React from "react";

import { Outlet } from "react-router-dom";

import { useLayout } from "@/hooks/useLayout";

export const USER_ROLE_LABEL_MAPPER = {
  "0": "Full",
  "1": "View Only",
  "2": "None",
  "3": "Admin",
};

const UserRoleManagementWindow = (props) => {
  useLayout(props.routeInfo);

  return <Outlet />;
};

export default UserRoleManagementWindow;

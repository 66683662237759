import { atomWithReset } from "jotai/utils";

import {
  Furniture,
  Light,
  LightData,
  ElectricalSwitch,
  Material,
  MaterialData,
  MaterialTypesType,
} from "@/DatabaseControls/DataTypes";

export interface FurnituresModalType {
  isShow: boolean;
  props:
    | {
        selectedFurniture: Furniture;
      }
    | Record<string, never>;
}

export interface ElectricalSwitchModalType {
  isShow: boolean;
  props:
    | {
        selectedElectricalSwitch: ElectricalSwitch;
      }
    | Record<string, never>;
}

export interface LightsModalType {
  isShow: boolean;
  props:
    | {
        selectedLight: Light;
      }
    | Record<string, never>;
}

export interface MaterialsModalType {
  isShow: boolean;
  props: {
    type: MaterialTypesType;
    selectedMaterial?: Material;
    selectedMaterialData?: MaterialData;
  };
}

export const furnituresModalState = atomWithReset<FurnituresModalType>({
  isShow: false,
  props: {},
});

export const electricalSwitchModalState =
  atomWithReset<ElectricalSwitchModalType>({
    isShow: false,
    props: {},
  });

export const lightsModalState = atomWithReset<LightsModalType>({
  isShow: false,
  props: {},
});

export const materialsModalState = atomWithReset<MaterialsModalType>({
  isShow: false,
  props: {
    type: "Wall",
  },
});

import { useEffect } from "react";
import { routeInfoState } from "@/states/navbar";
import { useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";

export const useLayout = (routeInfo) => {
  const setRouteInfo = useSetAtom(routeInfoState);
  const resetRouteInfo = useResetAtom(routeInfoState);

  useEffect(() => {
    setRouteInfo(routeInfo);

    return () => {
      resetRouteInfo();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeInfo.key]);

  return [routeInfo];
};

import React from "react";

import VuiSnackbar from "@/Components/VuiSnackbar";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

const Snackbar = () => {
  const { snackbarProps, resetSnackbarProps } = useSnackbar();

  const handleClose = (e, reason) => {
    if (reason !== "escapeKeyDown" && reason !== "clickaway") {
      resetSnackbarProps();
    }
  };

  return (
    <VuiSnackbar {...snackbarProps} close={handleClose} onClose={handleClose} />
  );
};

export default Snackbar;

import { RouteInfoType } from "@/Components/Routes/Routes";
import { atomWithReset } from "jotai/utils";

export const routeInfoState = atomWithReset<RouteInfoType>({
  key: "",
  localeKey: "",
  title: "",
  name: "",
  icon: "",
});

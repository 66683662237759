import React, { useState } from "react";

import { Grid } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";
import { Furniture } from "@/DatabaseControls/DataTypes";

import ProjectMedia, {
  DropzoneContent,
  convertUrlToBlobUrl,
  extractExtension,
} from "../ProjectManagement/ProjectMedia";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import { useTranslation } from "react-i18next";

const FurnitureImage = ({
  furniture,
}: {
  furniture?: Furniture;
  setFurnitureState: (x: Furniture) => void;
}) => {
  const { t } = useTranslation();

  const [editingThumbnail, setEditingThumbnail] = useState(false);
  const [editingFbx, setEditingFbx] = useState(false);
  const [updatingProject, setUpdatingProject] = useState(false);

  const thumbnailState = useState<DropzoneContent>();
  const [thumbnail, setThumbnailState] = thumbnailState;
  if (furniture && furniture.ThumbnailURL != "" && thumbnail == null) {
    convertUrlToBlobUrl(furniture.ThumbnailURL, setThumbnailState);
  }

  const modelFbxState = useState<DropzoneContent>();
  const [modelFbx, setModelFbxState] = modelFbxState;
  if (furniture && furniture.ModelURL != "" && modelFbx == null) {
    convertUrlToBlobUrl(furniture.ModelURL, setModelFbxState);
  }

  const handleMediaUpdate = async (
    dropzoneContent: DropzoneContent,
    imageLocation: string,
    imageName: string,
    propertyName: string,
  ): Promise<void> => {
    if (dropzoneContent.file != null) {
      const extension = extractExtension(dropzoneContent?.file?.name);
      const fileName = imageName + "-" + furniture.id + extension;
      return new Promise<void>((res, rej) => {
        firebaseSingleton
          .uploadMedia(imageLocation, fileName, dropzoneContent.file)
          .then(() => {
            firebaseSingleton
              .getMediaUrl(imageLocation, fileName)
              .then((newUrl: string) => {
                furniture[propertyName] = newUrl;
                res();
              });
          })
          .catch(() => {
            rej();
          });
      });
    } else if (furniture[propertyName] != "") {
      const extension = extractExtension(furniture[propertyName]);
      const fileName = imageName + "-" + furniture.id + extension;
      furniture[propertyName] = "";
      return firebaseSingleton.deleteMedia(imageLocation, fileName);
    }
  };

  const handleEditThumbnailClicked = () => {
    if (editingThumbnail && !updatingProject) {
      setEditingThumbnail(false);
      setUpdatingProject(true);
      if (thumbnail && thumbnail.hasNewFile) {
        handleMediaUpdate(
          thumbnail,
          "FurnitureImage",
          "thumbnail",
          "ThumbnailURL",
        ).then(() => {
          firebaseSingleton.updateFurniture(furniture).then(() => {
            setEditingThumbnail(false);
            setUpdatingProject(false);
          });
        });
      }
    } else if (!updatingProject) {
      setEditingThumbnail(true);
    }
  };

  const handleEditFbxClicked = () => {
    if (editingFbx && !updatingProject) {
      setEditingFbx(false);
      setUpdatingProject(true);
      if (modelFbx && modelFbx.hasNewFile) {
        handleMediaUpdate(
          modelFbx,
          "FurnitureModels",
          "model",
          "ModelURL",
        ).then(() => {
          firebaseSingleton.updateFurniture(furniture).then(() => {
            setEditingFbx(false);
            setUpdatingProject(false);
          });
        });
      }
    } else if (!updatingProject) {
      setEditingFbx(true);
    }
  };

  const ThumbnailImage = furniture?.ThumbnailURL ? (
    <Grid container justifyContent="center" alignItems="center">
      <VuiBox
        component="img"
        src={furniture?.ThumbnailURL}
        alt="Product Image"
        borderRadius="lg"
        shadow="lg"
        width="30%"
        my={3}
      />
    </Grid>
  ) : (
    <VuiTypography my={3} variant="body2" color="white">
      No image provided
    </VuiTypography>
  );

  const hasModel =
    modelFbx?.info != undefined ? modelFbx.info.url : furniture.ModelURL;

  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <VuiBox px={3}>
          <VuiTypography mb={1.5} variant="h5" color="white">
            {t("modules.furnitures.section_titles.furniture_thumbnail")}
          </VuiTypography>
          {!editingThumbnail ? (
            ThumbnailImage
          ) : (
            <VuiBox mt={3} mb={5}>
              <ProjectMedia
                title={""}
                label={""}
                dropzoneContentState={thumbnailState}
              />
            </VuiBox>
          )}
          <VuiBox display="flex">
            <VuiButton
              disabled={!!!furniture.id}
              variant="contained"
              color={!!furniture.id ? "info" : "dark"}
              size="small"
              sx={{ mr: "8px" }}
              onClick={handleEditThumbnailClicked}
            >
              {editingThumbnail
                ? t("common.buttons.save")
                : t("common.buttons.edit")}
            </VuiButton>
            {editingThumbnail && (
              <VuiButton
                variant="contained"
                color="error"
                size="small"
                sx={{ mr: "8px" }}
                onClick={() => setEditingThumbnail(false)}
              >
                {t("common.buttons.cancel")}
              </VuiButton>
            )}
          </VuiBox>
        </VuiBox>
      </Grid>

      <Grid item xs={6}>
        <VuiBox px={3}>
          <VuiTypography mb={1.5} variant="h5" color="white">
            {t("modules.furnitures.section_titles.model_fbx")}
          </VuiTypography>
          {!editingFbx ? (
            <VuiTypography my={3} variant="body2" color="white">
              {hasModel ? "Model FBX Provided" : "No Model FBX Provided"}
            </VuiTypography>
          ) : (
            <VuiBox mt={3} mb={5}>
              <ProjectMedia
                title={""}
                label={""}
                dropzoneContentState={modelFbxState}
              />
            </VuiBox>
          )}
          <VuiBox display="flex">
            <VuiButton
              disabled={!!!furniture.id}
              variant="contained"
              color={!!furniture.id ? "info" : "dark"}
              size="small"
              sx={{ mr: "8px" }}
              onClick={handleEditFbxClicked}
            >
              {editingFbx ? t("common.buttons.save") : t("common.buttons.edit")}
            </VuiButton>
            {editingFbx && (
              <VuiButton
                variant="contained"
                color="error"
                size="small"
                sx={{ mr: "8px" }}
                onClick={() => setEditingFbx(false)}
              >
                {t("common.buttons.cancel")}
              </VuiButton>
            )}
          </VuiBox>
        </VuiBox>
      </Grid>
    </Grid>
    // <Card sx={{ height: "100%", p: "10px" }}>

    // <VuiBox p={3}>
    //   <VuiTypography variant="h5" fontWeight="bold" color="white">
    //     Model FBX
    //   </VuiTypography>
    //   {!editingFbx ? (
    //     <VuiTypography my={3} variant="body2" color="white">
    //       {hasModel ? "Model FBX Provided" : "No Model FBX Provided"}
    //     </VuiTypography>
    //   ) : (
    //     <VuiBox mt={3} mb={5}>
    //       <ProjectMedia
    //         title={""}
    //         label={""}
    //         dropzoneContentState={modelFbxState}
    //       />
    //     </VuiBox>
    //   )}
    //   <VuiBox display="flex">
    //     <VuiButton
    //       variant="contained"
    //       color="info"
    //       size="small"
    //       sx={{ mr: "8px" }}
    //       onClick={handleEditFbxClicked}
    //     >
    //       {editingFbx ? "save" : "edit"}
    //     </VuiButton>
    //     {editingFbx && (
    //       <VuiButton
    //         variant="contained"
    //         color="error"
    //         size="small"
    //         sx={{ mr: "8px" }}
    //         onClick={() => setEditingFbx(false)}
    //       >
    //         cancel
    //       </VuiButton>
    //     )}
    //   </VuiBox>
    // </VuiBox>
    // </Card>
  );
};

export default FurnitureImage;

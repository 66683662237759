import React, { useState } from "react";

import { Grid } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";
import { Light } from "@/DatabaseControls/DataTypes";

import ProjectMedia, {
  DropzoneContent,
  convertUrlToBlobUrl,
  extractExtension,
} from "../ProjectManagement/ProjectMedia";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import { useTranslation } from "react-i18next";

const LightImage = ({
  light,
}: {
  light?: Light;
  setLightState: (x: Light) => void;
}) => {
  const { t } = useTranslation();

  const [editingThumbnail, setEditingThumbnail] = useState(false);
  const [editingFbx, setEditingFbx] = useState(false);
  const [updatingProject, setUpdatingProject] = useState(false);

  const thumbnailState = useState<DropzoneContent>();
  const [thumbnail, setThumbnailState] = thumbnailState;
  if (light && light.ThumbnailURL != "" && thumbnail == null) {
    convertUrlToBlobUrl(light.ThumbnailURL, setThumbnailState);
  }

  const modelFbxState = useState<DropzoneContent>();
  const [modelFbx, setModelFbxState] = modelFbxState;
  if (light && light.ModelURL != "" && modelFbx == null) {
    convertUrlToBlobUrl(light.ModelURL, setModelFbxState);
  }

  const handleMediaUpdate = async (
    dropzoneContent: DropzoneContent,
    imageLocation: string,
    imageName: string,
    propertyName: string,
  ): Promise<void> => {
    const extension = extractExtension(dropzoneContent?.file?.name);
    const fileName = imageName + "-" + light.id + extension;
    if (dropzoneContent.file != null) {
      return new Promise<void>((res, rej) => {
        firebaseSingleton
          .uploadMedia(imageLocation, fileName, dropzoneContent.file)
          .then(() => {
            firebaseSingleton
              .getMediaUrl(imageLocation, fileName)
              .then((newUrl: string) => {
                light[propertyName] = newUrl;
                res();
              });
          })
          .catch(() => {
            rej();
          });
      });
    } else if (light[propertyName] != "") {
      const extension = extractExtension(light[propertyName]);
      const fileName = imageName + "-" + light.id + extension;
      light[propertyName] = "";
      return firebaseSingleton.deleteMedia(imageLocation, fileName);
    }
  };

  const handleEditThumbnailClicked = () => {
    if (editingThumbnail && !updatingProject) {
      setEditingThumbnail(false);
      setUpdatingProject(true);
      if (thumbnail && thumbnail.hasNewFile) {
        handleMediaUpdate(
          thumbnail,
          "FurnitureImage",
          "thumbnail",
          "ThumbnailURL",
        ).then(() => {
          firebaseSingleton.updateLight(light).then(() => {
            setEditingThumbnail(false);
            setUpdatingProject(false);
          });
        });
      }
    } else if (!updatingProject) {
      setEditingThumbnail(true);
    }
  };

  const handleEditFbxClicked = () => {
    if (editingFbx && !updatingProject) {
      setEditingFbx(false);
      setUpdatingProject(true);
      if (modelFbx && modelFbx.hasNewFile) {
        handleMediaUpdate(
          modelFbx,
          "FurnitureModels",
          "model",
          "ModelURL",
        ).then(() => {
          firebaseSingleton.updateLight(light).then(() => {
            setEditingFbx(false);
            setUpdatingProject(false);
          });
        });
      }
    } else if (!updatingProject) {
      setEditingFbx(true);
    }
  };

  const ThumbnailImage = light?.ThumbnailURL ? (
    <VuiBox
      component="img"
      src={light?.ThumbnailURL}
      alt="Product Image"
      borderRadius="lg"
      shadow="lg"
      width="100%"
      my={3}
    />
  ) : (
    <VuiTypography my={3} variant="body2" color="white">
      No image provided
    </VuiTypography>
  );

  const hasModel =
    modelFbx?.info != undefined ? modelFbx.info.url : light.ModelURL;

  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <VuiBox px={3}>
          <VuiTypography mb={1.5} variant="h5" color="white">
            {t("modules.lights.section_titles.light_thumbnail")}
          </VuiTypography>
          {!editingThumbnail ? (
            ThumbnailImage
          ) : (
            <VuiBox mt={3} mb={5}>
              <ProjectMedia
                title={""}
                label={""}
                dropzoneContentState={thumbnailState}
              />
            </VuiBox>
          )}
          <VuiBox display="flex">
            <VuiButton
              disabled={!!!light.id}
              variant="contained"
              color={!!light.id ? "info" : "dark"}
              size="small"
              sx={{ mr: "8px" }}
              onClick={handleEditThumbnailClicked}
            >
              {editingThumbnail
                ? t("common.buttons.save")
                : t("common.buttons.edit")}
            </VuiButton>
            {editingThumbnail && (
              <VuiButton
                variant="contained"
                color="error"
                size="small"
                sx={{ mr: "8px" }}
                onClick={() => setEditingThumbnail(false)}
              >
                {t("common.buttons.cancel")}
              </VuiButton>
            )}
          </VuiBox>
        </VuiBox>
      </Grid>

      <Grid item xs={6}>
        <VuiBox px={3}>
          <VuiTypography mb={1.5} variant="h5" color="white">
            {t("modules.lights.section_titles.model_fbx")}
          </VuiTypography>
          {!editingFbx ? (
            <VuiTypography my={3} variant="body2" color="white">
              {hasModel ? "Model FBX Provided" : "No Model FBX Provided"}
            </VuiTypography>
          ) : (
            <VuiBox mt={3} mb={5}>
              <ProjectMedia
                title={""}
                label={""}
                dropzoneContentState={modelFbxState}
              />
            </VuiBox>
          )}
          <VuiBox display="flex">
            <VuiButton
              disabled={!!!light.id}
              variant="contained"
              color={!!light.id ? "info" : "dark"}
              size="small"
              sx={{ mr: "8px" }}
              onClick={handleEditFbxClicked}
            >
              {editingFbx ? t("common.buttons.save") : t("common.buttons.edit")}
            </VuiButton>
            {editingFbx && (
              <VuiButton
                variant="contained"
                color="error"
                size="small"
                sx={{ mr: "8px" }}
                onClick={() => setEditingFbx(false)}
              >
                {t("common.buttons.cancel")}
              </VuiButton>
            )}
          </VuiBox>
        </VuiBox>
      </Grid>
    </Grid>
  );
};

export default LightImage;

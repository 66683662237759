/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import { styled } from "@mui/material/styles";

export interface VUIInputIconBoxRootProps {
  ownerState: VUIInputIconBoxRootState;
}

export interface VUIInputIconBoxRootState {
  size: "small" | "medium" | "large";
}

export default styled("div")<VUIInputIconBoxRootProps>(({
  theme,
  ownerState,
}) => {
  const { palette, functions } = theme;
  const { size } = ownerState;

  const { dark } = palette;
  const { pxToRem } = functions;

  return {
    lineHeight: 0,
    padding:
      size === "small"
        ? `${pxToRem(4)} ${pxToRem(10)}`
        : `${pxToRem(8)} ${pxToRem(10)}`,
    width: pxToRem(39),
    height: "100%",
    color: dark.main,
  };
});

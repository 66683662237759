import React, { useState } from "react";

import PropTypes from "prop-types";

import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import borders from "@/assets/theme/base/borders";
import colors from "@/assets/theme/base/colors";

import { useTranslation } from "react-i18next";

function CompanyCard({
  color,
  title,
  email,
  company,
  address,
  noDivider,
  onClick,
  deleteCompany,
}) {
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);

  const { t } = useTranslation();

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
    event.stopPropagation();
  };
  const handleCloseMenu = () => setOpenMenu(null);
  const handleDeleteCompany = () => {
    setOpenMenu(null);
    deleteCompany(company);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onClick={(e) => e.stopPropagation()}
      keepMounted
    >
      <MenuItem onClick={handleDeleteCompany}>
        {t("common.buttons.cancel")}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <VuiBox
        component="li"
        width="100%"
        pr={2}
        py={1}
        borderLeft={`${borderWidth[4]} solid ${colors[color].main}`}
        onClick={onClick}
        sx={{
          listStyle: "none",
          cursor: "pointer",
          "&:hover": {
            marginLeft: `-${borderWidth[4]}`,
            transition: "all 100ms ease-in-out",
          },
        }}
      >
        <VuiBox width="100%" pl={1} ml={2}>
          <VuiBox display="flex" alignItems="center">
            <Icon fontSize="default" sx={{ color: "#fff" }}>
              folder
            </Icon>
            <VuiBox sx={{ display: "flex" }} ml={0.3} lineHeight={1}>
              <VuiTypography variant="button" color="white" fontWeight="medium">
                {title}
              </VuiTypography>
            </VuiBox>
            <VuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
              <Icon
                fontSize="default"
                sx={{ cursor: "pointer", color: "#fff" }}
                onClick={handleOpenMenu}
              >
                more_horiz
              </Icon>
            </VuiBox>
            {renderMenu()}
          </VuiBox>
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mt={2}
            ml={{ xs: 0, md: 3 }}
            pl={0.5}
          >
            <VuiBox width="16rem" lineHeight={1} mb={{ xs: 1, sm: 0 }}>
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.companies.data.Address")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {address || "-"}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              width="10rem"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: { display: "none" },
              })}
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.companies.data.id")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {company || "-"}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              width="12rem"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: { display: "none" },
              })}
              mx={{ xs: 0, sm: "auto" }}
              lineHeight={1}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.companies.data.Email")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {email || "-"}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      {noDivider ? null : <Divider light sx={{ marginBottom: 0 }} />}
    </>
  );
}

CompanyCard.defaultProps = {
  color: "info",
  noDivider: false,
};

CompanyCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
};

export default CompanyCard;

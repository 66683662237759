import React from "react";

import { Card, Grid } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import VuiFormField from "@/Components/VuiFormField";
import { JoinedUserRole } from "@/DatabaseControls/DataTypes";

import _ from "lodash";

import { UserRoleInputStateType } from "./UserRoleWindow";
import { USER_ROLE_LABEL_MAPPER } from "./UserRoleManagementWindow";

import { useAtomValue } from "jotai";
import { currentUserState } from "@/states/auth";

import { useTranslation } from "react-i18next";

const UserRoleInfo = ({
  userRoleState,
  setUserRoleState,
  inputState,
  setInputState,
  isEdit,
  isLoading,
  roleOptions,
  companyOptions,
  userOptions,
}: {
  userRoleState: JoinedUserRole;
  setUserRoleState: (userRole: JoinedUserRole) => void;
  inputState: UserRoleInputStateType;
  setInputState: (inputState: UserRoleInputStateType) => void;
  isEdit: boolean;
  isLoading: boolean;
  roleOptions: { value: string; label: string }[];
  companyOptions: { value: string; label: string }[];
  userOptions: { value: string; label: string }[];
}) => {
  const currentUser = useAtomValue(currentUserState);
  const { t } = useTranslation();

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox>
        <VuiBox mb={1.5} lineHeight={0} display="flex" flexDirection="column">
          <VuiTypography variant="h5" color="white" fontWeight="bold">
            {t("modules.user_roles.section_titles.information")}
          </VuiTypography>
        </VuiBox>

        <VuiBox mt={1}>
          <Grid container flexDirection="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                {isEdit ? (
                  <VuiFormField
                    type="text"
                    name="User Email"
                    label={t("modules.user_roles.data.email")}
                    value={userRoleState.AssociatedUser.Email}
                    disabled
                  />
                ) : (
                  <VuiFormField
                    select
                    name="User Email"
                    label={t("modules.user_roles.data.email")}
                    defaultValue={{
                      value: currentUser.id,
                      label: currentUser.Email,
                    }}
                    onChange={(data) => {
                      setInputState({
                        ...inputState,
                        AssociatedUser: data.value,
                      });
                    }}
                    options={userOptions}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <VuiFormField
                  select
                  name="AccessLevel"
                  label={t("modules.user_roles.data.role")}
                  value={{
                    value: inputState.AccessLevel,
                    label: USER_ROLE_LABEL_MAPPER[inputState.AccessLevel],
                  }}
                  onChange={(data) => {
                    setInputState({ ...inputState, AccessLevel: data.value });
                  }}
                  options={roleOptions}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12}>
                <VuiFormField
                  select
                  name="Company"
                  label={t("modules.user_roles.data.company")}
                  value={companyOptions.find(
                    (c) => c.value === inputState.AssociatedCompany,
                  )}
                  onChange={(data) => {
                    setInputState({
                      ...inputState,
                      AssociatedCompany: data.value,
                    });
                  }}
                  options={companyOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default UserRoleInfo;

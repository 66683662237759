import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import BaseModal from "@/Components/BaseModal";
import VuiBox from "@/Components/VuiBox";

import { Catalog, Light, ModelBrand } from "@/DatabaseControls/DataTypes";

import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useAtom } from "jotai";
import { lightsModalState } from "@/states/catalogs/modals";

import LightWindow from "@/Pages/LightManagement/LightWindow";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { getDefaultLight } from "@/DatabaseControls/DataDefaultValues";

interface LightsModalPropsType {
  catalogState: Catalog;
  setCatalogState: (catalog: Catalog) => void;
  refreshData: () => void;
  modelBrandList: ModelBrand[];
}

const LightsModal = (props: LightsModalPropsType) => {
  const { catalogState, setCatalogState, refreshData, modelBrandList } = props;

  const [lightsModal, setLightsModal] = useAtom(lightsModalState);
  const [lightState, setLightState] = useState<Light | null>(null);
  const [selectedLight, setSelectedLight] = useState<Light | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();

  const { t } = useTranslation();

  const isEdit = !!selectedLight;

  useEffect(() => {
    if (lightsModal.isShow) {
      if (lightsModal.props.selectedLight) {
        setSelectedLight(lightsModal.props.selectedLight);
        setLightState(lightsModal.props.selectedLight);
      } else {
        setLightState(getDefaultLight());
      }

      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightsModal.isShow, catalogState]);

  const saveLight = async () => {
    resetSnackbarProps();

    if (isEdit) {
      await firebaseSingleton.updateLight(lightState).then(() => {
        handleClose();
        refreshData();
      });

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Light record updated successfully.",
      });
    } else {
      const newLightId = firebaseSingleton.getNewDocumentId("light");

      await firebaseSingleton
        .addLight(lightState, newLightId, catalogState.id)
        .then(() => {
          handleClose();
          refreshData();

          setCatalogState({
            ...catalogState,
            Lights: [...catalogState.Lights, newLightId],
          });
        });

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Light record created successfully.",
      });
    }
  };

  const deleteLight = async () => {
    if (selectedLight) {
      await firebaseSingleton
        .deleteLight(selectedLight.id, catalogState.id)
        .then(() => {
          handleClose();
          refreshData();

          setCatalogState({
            ...catalogState,
            Lights: [...catalogState.Lights],
          });

          setSnackbarProps({
            open: true,
            icon: "check_circle",
            content: "Light record deleted successfully.",
          });
        });
    }
  };

  const handleClose = (): void => {
    setSelectedLight(null);

    setIsLoaded(false);

    setLightsModal({
      ...lightsModal,
      isShow: false,
      props: {},
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    saveLight();
  };

  return (
    <BaseModal
      title={
        selectedLight
          ? t("modules.lights.section_titles.edit")
          : t("modules.lights.section_titles.create")
      }
      open={lightsModal.isShow}
      onClose={handleClose}
      onDone={handleSubmit}
    >
      <VuiBox sx={{ minHeight: 600 }}>
        {isLoaded && (
          <LightWindow
            lightState={lightState}
            setLightState={setLightState}
            modelBrandList={modelBrandList}
            onSave={saveLight}
            onDelete={deleteLight}
          />
        )}
      </VuiBox>
    </BaseModal>
  );
};

export default LightsModal;

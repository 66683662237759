import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";

import { Company } from "@/DatabaseControls/DataTypes";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import CompanyInfo from "./CompanyInfo";
import { getDefaultCompany } from "@/DatabaseControls/DataDefaultValues";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

import { useTranslation } from "react-i18next";
import _ from "lodash";

function CompanyWindow({ company }: { company?: Company }) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { setSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const [companyState, setCompanyState] = useState<Company | null>(null);

  const getCompany = async () => {
    return await firebaseSingleton.getCompany(params.id);
  };

  const saveCompany = async () => {
    if (params.id) {
      await firebaseSingleton.updateCompany(companyState).then(() => {
        navigate("/companies");

        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: "Company record updated successfully.",
        });
      });
    } else {
      await firebaseSingleton.addCompany(companyState).then(() => {
        navigate("/companies");

        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: "Company record created successfully.",
        });
      });
    }
  };

  useEffect(() => {
    if (params.id) {
      if (location.state?.company) {
        setCompanyState(location.state.company);
      } else {
        getCompany().then((result) => {
          if (result.status) {
            setCompanyState(result.data);
          } else {
            navigate("/companies");
          }
        });
      }
    } else {
      setCompanyState(getDefaultCompany());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.state]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {companyState && (
          <VuiBox my={3} mb="30px">
            <VuiBox mx={1} mt={1} mb={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={10}>
                  <VuiTypography variant="h4" fontWeight="medium" color="white">
                    {companyState?.Name || "New Company"}
                  </VuiTypography>
                  <VuiBox mt={1} mb={2}>
                    <VuiTypography variant="body2" color="text">
                      {t("common.pages.edit_description", {
                        title: _.toLower(t("modules.companies.title_singular")),
                      })}
                    </VuiTypography>
                  </VuiBox>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <VuiBox display="flex" justifyContent="flex-end">
                    <VuiButton
                      variant="contained"
                      color="info"
                      onClick={saveCompany}
                    >
                      {t("common.buttons.save")}
                    </VuiButton>
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox>
            <CompanyInfo
              company={companyState}
              setCompanyState={setCompanyState}
            />
          </VuiBox>
        )}
      </Grid>
    </Grid>
  );
}

export default CompanyWindow;

import React from "react";

import VuiBox from "@/Components/VuiBox";
import Card from "@mui/material/Card";
import CatalogList from "./CatalogList";

import { Catalog } from "@/DatabaseControls/DataTypes";

import { getDefaultCatalog } from "@/DatabaseControls/DataDefaultValues";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

async function getDatabaseList(
  setCatalogList: (catalogList: Catalog[]) => void,
) {
  const catalogList: Map<string, Catalog> =
    await firebaseSingleton.getDatabaseList("catalog");
  setCatalogList(
    Array.from(catalogList, ([_, catalog]) => {
      return { ...getDefaultCatalog(), ...catalog };
    }),
  );
}

const CatalogIndexWindow = () => {
  const [hasRetrievedData, setHasRetrievedData] = React.useState(false);
  const [catalogList, setCatalogList] = React.useState([] as Catalog[]);

  const refreshData = () => getDatabaseList(setCatalogList);

  if (!hasRetrievedData) {
    refreshData();
    setHasRetrievedData(true);
  }

  return (
    <VuiBox mt={6}>
      <VuiBox mb={3}>
        <Card>
          <CatalogList listData={catalogList} refreshData={refreshData} />
        </Card>
      </VuiBox>
    </VuiBox>
  );
};

export default CatalogIndexWindow;

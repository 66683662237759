import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";

import ProjectMedia, {
  DropzoneContent,
  convertUrlToBlobUrl,
  extractExtension,
} from "../ProjectManagement/ProjectMedia";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import { Material } from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

const MaterialImage = ({
  material,
}: {
  material?: Material;
  setMaterialState: (x: Material) => void;
}) => {
  const { t } = useTranslation();

  const [editingTexture, setEditingTexture] = useState(false);
  const [editingNormal, setEditingNormal] = useState(false);

  const [updatingProject, setUpdatingProject] = useState(false);

  const textureThumbnailState = useState<DropzoneContent>();
  const normalThumbnailState = useState<DropzoneContent>();

  const [texture, setTexture] = textureThumbnailState;
  const [normal, setNormal] = normalThumbnailState;

  useEffect(() => {
    if (material && material.TextureURL) {
      convertUrlToBlobUrl(material.TextureURL, setTexture);
    }

    if (material && material.NormalURL) {
      convertUrlToBlobUrl(material.NormalURL, setNormal);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMediaUpdate = async (
    dropzoneContent: DropzoneContent,
    imageName: string,
    propertyName: string,
  ): Promise<void> => {
    const extension = extractExtension(dropzoneContent?.file?.name);
    const fileName = imageName + "-" + material.id + extension;

    if (dropzoneContent.file != null) {
      return new Promise<void>((res, rej) => {
        firebaseSingleton
          .uploadMedia("MaterialTextures", fileName, dropzoneContent.file)
          .then(() => {
            firebaseSingleton
              .getMediaUrl("MaterialTextures", fileName)
              .then((newUrl: string) => {
                material[propertyName] = newUrl;
                res();
              });
          })
          .catch(() => {
            rej();
          });
      });
    } else if (material[propertyName] !== "") {
      const extension = extractExtension(material[propertyName]);
      const fileName = imageName + "-" + material.id + extension;
      material[propertyName] = "";
      return firebaseSingleton.deleteMedia("MaterialTextures", fileName);
    }
  };

  const handleEditTextureClicked = () => {
    if (editingTexture && !updatingProject) {
      setEditingTexture(false);
      setUpdatingProject(true);

      if (texture && texture.hasNewFile) {
        handleMediaUpdate(texture, "texture", "TextureURL").then(() => {
          firebaseSingleton.updateMaterial(material).then(() => {
            setEditingTexture(false);
            setUpdatingProject(false);
          });
        });
      }
    } else if (!updatingProject) {
      setEditingTexture(true);
    }
  };

  const handleEditNormalClicked = () => {
    if (editingNormal && !updatingProject) {
      setEditingNormal(false);
      setUpdatingProject(true);

      if (normal && normal.hasNewFile) {
        handleMediaUpdate(normal, "normal", "NormalURL").then(() => {
          firebaseSingleton.updateMaterial(material).then(() => {
            setEditingTexture(false);
            setUpdatingProject(false);
          });
        });
      }
    } else if (!updatingProject) {
      setEditingNormal(true);
    }
  };

  const TextureImage = material?.TextureURL ? (
    <Grid container justifyContent="center" alignItems="center">
      <VuiBox
        component="img"
        src={material?.TextureURL}
        alt="Product Image"
        borderRadius="lg"
        shadow="lg"
        width="30%"
        my={3}
      />
    </Grid>
  ) : (
    <VuiTypography my={3} variant="body2" color="white">
      No image provided
    </VuiTypography>
  );

  const NormalImage = material?.NormalURL ? (
    <Grid container justifyContent="center" alignItems="center">
      <VuiBox
        component="img"
        src={material?.NormalURL}
        alt="Product Image"
        borderRadius="lg"
        shadow="lg"
        width="30%"
        my={3}
      />
    </Grid>
  ) : (
    <VuiTypography my={3} variant="body2" color="white">
      No image provided
    </VuiTypography>
  );

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <VuiBox p={3}>
          <VuiTypography variant="h5" fontWeight="bold" color="white">
            {t("modules.materials.section_titles.material_texture")}
          </VuiTypography>

          <Grid container justifyContent={"space-between"} spacing={4}>
            <Grid item xs={6}>
              <VuiTypography fontSize={16} fontWeight={"bold"} color="white">
                Texture
              </VuiTypography>

              {!editingTexture ? (
                TextureImage
              ) : (
                <VuiBox mb={2}>
                  <ProjectMedia
                    noMargin
                    title={""}
                    label={""}
                    dropzoneContentState={textureThumbnailState}
                  />
                </VuiBox>
              )}
              <VuiBox display="flex">
                <VuiButton
                  disabled={!!!material.id}
                  variant="contained"
                  color={!!material.id ? "info" : "dark"}
                  size="small"
                  sx={{ mr: "8px" }}
                  onClick={handleEditTextureClicked}
                >
                  {editingTexture
                    ? t("common.buttons.save")
                    : t("common.buttons.edit")}
                </VuiButton>
                {editingTexture && (
                  <VuiButton
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ mr: "8px" }}
                    onClick={() => setEditingTexture(false)}
                  >
                    {t("common.buttons.cancel")}
                  </VuiButton>
                )}
              </VuiBox>
            </Grid>

            <Grid item xs={6}>
              <VuiTypography fontSize={16} fontWeight={"bold"} color="white">
                Normal
              </VuiTypography>

              {!editingNormal ? (
                NormalImage
              ) : (
                <VuiBox mb={2}>
                  <ProjectMedia
                    noMargin
                    title={""}
                    label={""}
                    dropzoneContentState={normalThumbnailState}
                  />
                </VuiBox>
              )}
              <VuiBox display="flex">
                <VuiButton
                  disabled={!!!material.id}
                  variant="contained"
                  color={!!material.id ? "info" : "dark"}
                  size="small"
                  sx={{ mr: "8px" }}
                  onClick={handleEditNormalClicked}
                >
                  {editingNormal
                    ? t("common.buttons.save")
                    : t("common.buttons.edit")}
                </VuiButton>

                {editingNormal && (
                  <VuiButton
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ mr: "8px" }}
                    onClick={() => setEditingNormal(false)}
                  >
                    {t("common.buttons.cancel")}
                  </VuiButton>
                )}
              </VuiBox>
            </Grid>
          </Grid>
        </VuiBox>
      </Grid>
    </Grid>
  );
};

export default MaterialImage;

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useTranslation } from "react-i18next";

import { Card, Divider, Icon } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";
import VuiInput from "@/Components/VuiInput";

import CatalogCard from "./CatalogCard";

import { Catalog } from "@/DatabaseControls/DataTypes";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

function applySearch(searchTerm: string, listData: Catalog[]) {
  return listData.filter((catalog: Catalog) => {
    console.log("search term: " + searchTerm + ", name: " + catalog.Name);
    return (
      catalog.Name.includes(searchTerm) ||
      catalog.Theme.includes(searchTerm) ||
      catalog.id.toString().includes(searchTerm)
    );
  });
}

const CatalogList = ({
  listData,
  refreshData,
}: {
  listData: Catalog[];
  refreshData: () => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPlaceholder, setSearchPlaceholder] = useState("");
  const handleSetSearchTerm = (e) => setSearchTerm(e.target.value);
  const navigateToCatalogWindow = () => navigate(`/catalogs/new`, {});

  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deleteCatalog = async (id: string) => {
    resetSnackbarProps();

    await firebaseSingleton.deleteCatalog(id).then(() => {
      refreshData();

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Catalog record deleted successfully.",
      });
    });
  };

  useEffect(() => {
    setSearchPlaceholder(t("common.messages.search"));
  }, [t]);

  return (
    <Card>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="lg" fontWeight="bold" color="white">
          {t("modules.catalogs.section_titles.list")}
        </VuiTypography>
        <VuiBox display="flex" justifyContent="space-between">
          <VuiBox pr={2} maxWidth={{ sm: "175px", md: "100%" }}>
            <VuiInput
              placeholder={searchPlaceholder}
              onChange={handleSetSearchTerm}
              icon={{ component: "search", direction: "left" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox>
          <VuiButton
            onClick={navigateToCatalogWindow}
            variant="contained"
            color="info"
            sx={{ minWidth: "48px", minHeight: "36px", padding: 0 }}
          >
            <Icon>add</Icon>
          </VuiButton>
        </VuiBox>
      </VuiBox>
      <Divider />
      <VuiBox>
        <VuiBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {applySearch(searchTerm, listData).map(
            (catalog: Catalog, i: number) => {
              return (
                <CatalogCard
                  color="success"
                  onClick={() =>
                    navigate(`/catalogs/${catalog.id}`, {
                      state: {
                        catalog,
                      },
                    })
                  }
                  deleteCatalog={deleteCatalog}
                  key={catalog.id}
                  catalogId={catalog.id.toString()}
                  name={catalog.Name}
                  image={catalog.PreviewImageURL}
                  tags={catalog.Tags.join(", ")}
                  theme={catalog.Theme}
                  noDivider={i === listData.length - 1}
                />
              );
            },
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default CatalogList;

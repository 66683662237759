import React, { useState } from "react";

import SignInWindow from "./SignInWindow";
import SignUpWindow from "./SignUpWindow";
import ResetPasswordWindow from "./ResetPasswordWindow";
import { currentUserRoleState, currentUserState } from "@/states/auth";
import { useAtomValue } from "jotai";
import UserInitWindow from "./UserInitWindow";

const AuthWindow = (props) => {
  const [currentPage, setCurrentPage] = useState(0);

  const currentUser = useAtomValue(currentUserState);
  const currentUserRole = useAtomValue(currentUserRoleState);

  return (
    <>
      {currentUser && !currentUserRole ? (
        <UserInitWindow />
      ) : (
        {
          0: <SignInWindow setCurrentPage={setCurrentPage} />,
          1: <SignUpWindow setCurrentPage={setCurrentPage} />,
          2: <ResetPasswordWindow setCurrentPage={setCurrentPage} />,
        }[currentPage]
      )}
    </>
  );
};

export default AuthWindow;

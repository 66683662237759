/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "../VuiBox";
import VuiTypography from "../VuiTypography";

// Vision UI Dashboard PRO React example components
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Scrollbar component
import { Scrollbars } from "react-custom-scrollbars-2";

// Scrollbar configs
import {
  renderThumb,
  renderTrack,
  renderTrackRTL,
  renderView,
  renderViewRTL,
} from "../VuiScrollbar";

// Vision UI Dashboard PRO React context
import {
  useVisionUIController,
  setMiniSidenav,
  setTransparentSidenav,
} from "../../context/Vui";
import { useAtom, useAtomValue } from "jotai";
import { currentUserRoleState } from "@/states/auth";
import _ from "lodash";

interface SideNavItemType {
  type: string;
  route: string;
  name: string;
  icon: string;
  title?: string;
  collapse?: boolean;
  noCollapse?: boolean;
  key?: string;
  href?: string;
  onClick?: () => void;
}

const SIDE_NAV_ITEMS: SideNavItemType[] = [
  {
    type: "collapse",
    route: "/",
    name: "Home",
    key: "home",
    icon: "home",
  },
  {
    type: "collapse",
    route: "/catalogs",
    name: "Catalogs",
    key: "catalogs",
    icon: "import_contacts",
  },
  {
    type: "collapse",
    route: "/companies",
    name: "Companies",
    key: "companies",
    icon: "business",
  },
  // {
  //   type: "collapse",
  //   route: "/languages",
  //   name: "Language Info",
  //   key: "languages",
  //   icon: "language",
  // },
  {
    type: "collapse",
    route: "/model-brands",
    name: "Model Brands",
    key: "model_brands",
    icon: "sell",
  },
  {
    type: "collapse",
    route: "/projects",
    name: "Projects",
    key: "projects",
    icon: "account_tree",
  },
  // {
  //   type: "collapse",
  //   route: "/themes",
  //   name: "Theme",
  //   key: "themes",
  //   icon: "web",
  // },
  {
    type: "collapse",
    route: "/users",
    name: "Users",
    key: "users",
    icon: "person",
  },
  {
    type: "collapse",
    route: "/user-roles",
    name: "User Role",
    key: "user_roles",
    icon: "admin_panel_settings",
  },
];

function Sidenav(props) {
  const [openCollapse, setOpenCollapse] = useState<string | boolean>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0] || "home";
  const itemName = pathname.split("/").slice(1)[1];

  const [currentUserRole, setCurrentUserRole] = useAtom(currentUserRoleState);

  const { t } = useTranslation();

  const handleMiniSidenavOpen = () => setMiniSidenav(dispatch, !miniSidenav);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    if (window.innerWidth < 1440) {
      setTransparentSidenav(dispatch, false);
    }
  }, []);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem
            color="info"
            name={name}
            children={false}
            open={false}
            active={false}
            nested
          />
        </Link>
      ) : (
        <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
          <SidenavItem
            color="info"
            name={name}
            active={route === pathname}
            children={false}
            open={false}
            nested
          />
        </NavLink>
      ),
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            color="info"
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            nested={false}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SidenavItem
              color="info"
              name={name}
              active={key === itemName}
              nested={false}
              open={false}
              children={false}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem
              color="info"
              name={name}
              active={key === itemName}
              nested={false}
              open={false}
              children={false}
            />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = SIDE_NAV_ITEMS.map(
    ({
      type,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      onClick,
      route,
    }) => {
      let returnValue;
      const name = t(`modules.${key}.title`) || "hey";

      if (type === "collapse") {
        returnValue =
          href || onClick ? (
            <Link
              href={href}
              onClick={onClick}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                color="info"
                name={name}
                icon={icon}
                children={false}
                open={false}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          ) : collapse ? (
            <SidenavCollapse
              color="info"
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === name}
              noCollapse={false}
              onClick={() =>
                openCollapse === name
                  ? setOpenCollapse(false)
                  : setOpenCollapse(name)
              }
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          ) : (
            <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
              <SidenavCollapse
                color="info"
                name={name}
                icon={icon}
                noCollapse={true}
                active={key === collapseName}
              />
            </NavLink>
          );
      } else if (type === "title") {
        returnValue = (
          <VuiTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            color="white"
            textTransform="uppercase"
            pl={3}
            my={3}
            ml={1}
          >
            {title}
          </VuiTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    },
  );

  return (
    <SidenavRoot
      {...props}
      variant="permanent"
      ownerState={{ miniSidenav, transparentSidenav }}
    >
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        renderThumbVertical={renderThumb}
        renderTrackVertical={
          window.location.href.includes("rtl") ? renderTrackRTL : renderTrack
        }
        renderView={
          window.location.href.includes("rtl") ? renderViewRTL : renderView
        }
      >
        <VuiBox
          display="flex"
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <VuiBox pt={3} pb={1} px={4} textAlign="center">
            <VuiBox display="flex">
              <VuiBox
                sx={
                  // @ts-ignore
                  ((theme) => sidenavLogoLabel(theme, { miniSidenav }),
                  {
                    display: "flex",
                    alignItems: "center",
                    margin: "0 0.325rem",
                  })
                }
              >
                <VuiBox
                  display="flex"
                  sx={
                    // @ts-ignore
                    ((theme) =>
                      sidenavLogoLabel(theme, {
                        miniSidenav,
                        transparentSidenav,
                      }),
                    {
                      mr:
                        miniSidenav || (miniSidenav && transparentSidenav)
                          ? 0
                          : 1,
                      cursor: "pointer",
                    })
                  }
                >
                  <Icon
                    fontSize="medium"
                    onClick={handleMiniSidenavOpen}
                    sx={
                      // @ts-ignore
                      ((theme) => sidenavLogoLabel(theme, { miniSidenav }),
                      {
                        color: "white !important",
                      })
                    }
                  >
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </VuiBox>
                <VuiTypography
                  variant="button"
                  color="white"
                  fontSize={14}
                  letterSpacing={2}
                  sx={
                    // @ts-ignore
                    ((theme) =>
                      sidenavLogoLabel(theme, {
                        miniSidenav,
                        transparentSidenav,
                      }),
                    {
                      opacity:
                        miniSidenav || (miniSidenav && transparentSidenav)
                          ? 0
                          : 1,
                      maxWidth:
                        miniSidenav || (miniSidenav && transparentSidenav)
                          ? 0
                          : "100%",
                      margin: "0 0.325rem",
                    })
                  }
                >
                  BEER Labs
                </VuiTypography>
              </VuiBox>
            </VuiBox>
            <Divider light />

            <VuiBox
              onClick={() => setCurrentUserRole(null)}
              sx={({ palette }) => ({
                display: miniSidenav ? "none" : "flex",
                my: 2,
                width: "100%",
                textWrap: "wrap",
                textAlign: "start",
                color: palette.text.main,
                cursor: "pointer",
              })}
            >
              <Icon>apartment</Icon>

              <VuiTypography
                variant={"caption"}
                sx={{ fontSize: 12, ml: 1, lineHeight: 1, color: "inherit" }}
              >
                {_.upperFirst(currentUserRole.AssociatedCompany.Name)}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
          <List sx={{ mb: "auto" }}>{renderRoutes}</List>
        </VuiBox>
      </Scrollbars>
    </SidenavRoot>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  // routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;

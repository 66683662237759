import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import ProjectList from "./ProjectList";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { Project } from "@/DatabaseControls/DataTypes";

import VuiBox from "@/Components/VuiBox";

async function getDatabaseList(
  setProjectList: (projectList: Project[]) => void,
) {
  const projectList: Map<string, Project> =
    await firebaseSingleton.getDatabaseList("project");
  setProjectList(Array.from(projectList, ([_, project]) => project));
}

const ProjectIndexWindow = () => {
  const [hasRetrievedData, setHasRetrievedData] = React.useState(false);
  const [projectList, setProjectList] = React.useState([] as Project[]);

  const refreshData = () => getDatabaseList(setProjectList);

  if (!hasRetrievedData) {
    refreshData();
    setHasRetrievedData(true);
  }
  return (
    <VuiBox pt={6}>
      <VuiBox mb={3}>
        <Card>
          <ProjectList listData={projectList} refreshData={refreshData} />
        </Card>
      </VuiBox>
    </VuiBox>
  );
};

export default ProjectIndexWindow;

import React from "react";

import CompanyList from "./CompanyList";

import VuiBox from "@/Components/VuiBox";

import Card from "@mui/material/Card";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { Company } from "@/DatabaseControls/DataTypes";

async function getDatabaseList(
  setCompanyList: (companyList: Company[]) => void,
) {
  const companyList: Map<string, Company> =
    await firebaseSingleton.getDatabaseList("company");
  setCompanyList(Array.from(companyList, ([_, company]) => company));
}

const CompanyIndexWindow = () => {
  const [hasRetrievedData, setHasRetrievedData] = React.useState(false);
  const [companyList, setCompanyList] = React.useState([] as Company[]);

  const refreshData = () => getDatabaseList(setCompanyList);

  if (!hasRetrievedData) {
    refreshData();
    setHasRetrievedData(true);
  }

  return (
    <VuiBox pt={6}>
      <VuiBox mb={3}>
        <Card>
          <CompanyList listData={companyList} refreshData={refreshData} />
        </Card>
      </VuiBox>
    </VuiBox>
  );
};

export default CompanyIndexWindow;

import { useState } from "react";

const useLocalStorage = (key, initialValue = null) => {
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState(initial);

  const updateValue = (newValue = null) => {
    setValue(newValue);

    if (newValue) {
      localStorage.setItem(key, JSON.stringify(newValue));
    } else {
      localStorage.removeItem(key);
    }
  };

  return [value, updateValue];
};

export default useLocalStorage;

import React from "react";

import { Outlet } from "react-router-dom";

import { useLayout } from "@/hooks/useLayout";

const UserManagementWindow = (props) => {
  useLayout(props.routeInfo);

  return <Outlet />;
};

export default UserManagementWindow;

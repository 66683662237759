import {
  Catalog,
  Company,
  ElectricalSwitch,
  Furniture,
  Light,
  LightData,
  Material,
  MaterialData,
  ModelBrand,
  Project,
  User,
  UserRole,
} from "./DataTypes";
import _ from "lodash";

const DEFAULT_CATALOG: Catalog = {
  id: "",
  order: "",
  parentId: "",
  Name: "",
  PreviewImageURL: "",
  Tags: [],
  Theme: "",
  Furnitures: [],
  ElectricalSwitches: [],
  WallMaterials: [],
  FloorMaterials: [],
  ConstructionMaterials: [],
  OtherMaterials: [],
  Lights: [],
};

const DEFAULT_COMPANY: Company = {
  id: "",
  Name: "",
  Phone: "",
  Email: "",
  Address: "",
  Projects: [],
  AccessibleCatalogues: [],
  CompanyTheme: "",
};

const DEFAULT_FURNITURE: Furniture = {
  Brand: "",
  Cost: 0,
  FurnitureType: "0",
  ModelURL: "",
  Name: "",
  PurchaseLink: "",
  ThumbnailURL: "",
  Variant: "0",
  id: "",
  order: "",
  parentId: "",
};

const DEFAULT_ELECTRICALSWITCH: ElectricalSwitch = {
  Brand: "",
  Cost: 0,
  ModelURL: "",
  Name: "",
  PurchaseURL: "",
  ThumbnailURL: "",
  Variant: "0",
  id: "",
  order: "",
  parentId: "",
};

const DEFAULT_LIGHT_DATA: LightData = {
  ColorHexadecimal: "#FFFFFF",
  Shape: 0,
  Type: 0,
  Mount: 0,
  PowerType: 0,
  ColorType: 0,
  Lumens: 0,
  ColorTemperature: 0,
  WhiteBalance: 0,
  Wattage: 0,
  Efficiency: 0,
  Range: 0,
  ConeAngle: 0,
  Radius: 0,
  BoxX: 0,
  BoxY: 0,
  isCustom: false,
  SourceDistance: 0,
};
const DEFAULT_LIGHT: Light = {
  Brand: "",
  Cost: 0,
  ModelURL: "",
  Name: "",
  PurchaseURL: "",
  ThumbnailURL: "",
  Variant: "0",
  id: "",
  order: "",
  parentId: "",
  LightProperties: DEFAULT_LIGHT_DATA,
};

const DEFAULT_MATERIAL_DATA: MaterialData = {
  color: "#FFFFFF",
  shininess: 1,
  smoothness: 1,
  is_emissive: true,
  emissive_color: "#FFFFFF",
  is_override: true,
};

const DEFAULT_MATERIAL: Material = {
  Brand: "",
  CatalogueID: "",
  CatalogueName: "",
  Cost: 0,
  Name: "",
  PurchaseURL: "",
  TextureURL: "",
  NormalURL: "",
  id: "",
  order: "",
  parentId: "",
  MaterialProperties: DEFAULT_MATERIAL_DATA,
};

const DEFAULT_MODEL_BRAND: ModelBrand = {
  id: "",
  Name: "",
  PhysicalAddress: "",
  WebsiteURL: "",
  LogoURL: "",
  PhoneNumber: "",
};

const DEFAULT_PROJECT: Project = {
  "3DModelPreviewImageUrl": "",
  Current3DModelLocation: "",
  Data: "",
  EndDate: "",
  LastModified: "",
  Name: "",
  PhysicalAddress: "",
  RealWorldImageUrl: "",
  StartDate: "",
  id: "",
  order: "",
  parentId: "",
  isHQFBX: false,
};

const DEFAULT_USER: User = {
  id: "",
  UserId: "",
  FirstName: "",
  LastName: "",
  Username: "",
  ProfilePictureUrl:
    "https://firebasestorage.googleapis.com/v0/b/jbuild-staging.appspot.com/o/root%2FIcons%2FNoProfile.png?alt=media&token=03f56fcf-2521-4e16-bb2c-3475c845c49e",
  Email: "",
  PhoneNo: "",
  IsSuperAdmin: false,
  accessibleProjects: [],
};

const DEFAULT_USER_ROLE: UserRole = {
  id: "",
  AccessLevel: "",
  AssociatedCompany: "",
  AssociatedUser: "",
};

const getDefaultData = <T>(data: T) => {
  return _.cloneDeep(data);
};

export const getDefaultCatalog = (): Catalog =>
  getDefaultData<Catalog>(DEFAULT_CATALOG);
export const getDefaultCompany = (): Company =>
  getDefaultData<Company>(DEFAULT_COMPANY);
export const getDefaultFurniture = (): Furniture =>
  getDefaultData<Furniture>(DEFAULT_FURNITURE);
export const getDefaultElectricalSwitch = (): ElectricalSwitch =>
  getDefaultData<ElectricalSwitch>(DEFAULT_ELECTRICALSWITCH);
export const getDefaultLight = (): Light =>
  getDefaultData<Light>(DEFAULT_LIGHT);
export const getDefaultMaterial = (): Material =>
  getDefaultData<Material>(DEFAULT_MATERIAL);
export const getDefaultModelBrand = (): ModelBrand =>
  getDefaultData<ModelBrand>(DEFAULT_MODEL_BRAND);
export const getDefaultProject = (): Project =>
  getDefaultData<Project>(DEFAULT_PROJECT);
export const getDefaultUser = (): User => getDefaultData<User>(DEFAULT_USER);
export const getDefaultUserRole = (): UserRole =>
  getDefaultData<UserRole>(DEFAULT_USER_ROLE);

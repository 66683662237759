import React, { useState } from "react";

import { Icon, Divider, Menu, MenuItem } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import borders from "@/assets/theme/base/borders";
import colors from "@/assets/theme/base/colors";

import { USER_ROLE_LABEL_MAPPER } from "./UserRoleManagementWindow";

import { useTranslation } from "react-i18next";

const UserRoleCard = (props) => {
  const {
    color,
    id,
    userEmail,
    companyName,
    accessLevel,
    onClick,
    deleteUserRole,
  } = props;
  const { borderWidth } = borders;

  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
    event.stopPropagation();
  };
  const handleCloseMenu = () => setOpenMenu(null);

  const { t } = useTranslation();

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onClick={(e) => e.stopPropagation()}
      keepMounted
    >
      <MenuItem onClick={() => deleteUserRole(id)}>
        {t("common.buttons.delete")}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <VuiBox
        component="li"
        width="100%"
        pr={2}
        mt={0.5}
        py={1}
        borderLeft={`${borderWidth[4]} solid ${colors[color].main}`}
        onClick={onClick}
        sx={{
          listStyle: "none",
          cursor: "pointer",
          "&:hover": {
            marginLeft: `-${borderWidth[4]}`,
            transition: "all 100ms ease-in-out",
          },
        }}
      >
        <VuiBox width="100%">
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            pl={0.5}
          >
            <VuiBox
              display="flex"
              alignItems="center"
              width="16rem"
              pl={2}
              gap={1}
            >
              <Icon fontSize="default" sx={{ color: "#fff" }}>
                admin_panel_settings
              </Icon>

              <VuiTypography variant="button" fontWeight="medium" color="white">
                {userEmail}
              </VuiTypography>
            </VuiBox>

            <VuiBox
              width="12rem"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: { display: "none" },
              })}
              ml={{ xs: 0, sm: "auto" }}
              lineHeight={1}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.user_roles.data.company")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {companyName}
              </VuiTypography>
            </VuiBox>

            <VuiBox
              width="12rem"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: { display: "none" },
              })}
              ml={{ xs: 0, sm: "auto" }}
              lineHeight={1}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.user_roles.data.role")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {USER_ROLE_LABEL_MAPPER[accessLevel]}
              </VuiTypography>
            </VuiBox>

            <VuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
              <Icon
                fontSize="default"
                sx={{ cursor: "pointer", color: "#fff" }}
                onClick={handleOpenMenu}
              >
                more_horiz
              </Icon>
            </VuiBox>
            {renderMenu()}
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <Divider light sx={{ marginBottom: 0 }} />
    </>
  );
};

UserRoleCard.defaultProps = {
  color: "info",
  noDivider: false,
  removeFromCatalog: undefined,
};

// UserCard.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "dark",
//   ]),
//   name: PropTypes.string.isRequired,
//   image: PropTypes.string.isRequired,
// };

export default UserRoleCard;

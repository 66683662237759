/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { forwardRef } from "react";

// Custom styles for VuiInput
import VuiInputRoot from "./VuiInputRoot";
import VuiInputWithIconRoot from "./VuiInputWithIconRoot";
import VuiInputIconBoxRoot from "./VuiInputIconBoxRoot";
import VuiInputIconRoot from "./VuiInputIconRoot";

// Vision UI Dashboard PRO React contexts
import { useVisionUIController } from "../../context/Vui";

const VuiInput = forwardRef<any, any>(
  ({ size, icon, error, success, disabled, ...rest }, ref) => {
    let template;
    const [controller] = useVisionUIController();
    const { direction } = controller;
    const iconDirection = icon.direction;

    const inputProps = {
      inputProps: {
        disabled: disabled,
        sx: { cursor: disabled ? "not-allowed " : "auto" },
      },
    };

    if (icon.component && icon.direction === "left") {
      template = (
        <VuiInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <VuiInputIconBoxRoot sx={{ width: "1rem" }} ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
          <VuiInputRoot
            sx={{ cursor: "auto " }}
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
            {...inputProps}
            {...rest}
          />
        </VuiInputWithIconRoot>
      );
    } else if (icon.component && icon.direction === "right") {
      template = (
        <VuiInputWithIconRoot
          ref={ref}
          ownerState={{ error, success, disabled }}
        >
          <VuiInputRoot
            sx={{ cursor: "auto " }}
            ownerState={{
              size,
              error,
              success,
              iconDirection,
              direction,
              disabled,
            }}
            {...inputProps}
            {...rest}
          />
          <VuiInputIconBoxRoot ownerState={{ size }}>
            <VuiInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </VuiInputIconRoot>
          </VuiInputIconBoxRoot>
        </VuiInputWithIconRoot>
      );
    } else {
      template = (
        <VuiInputRoot
          sx={{ cursor: "auto " }}
          ref={ref}
          ownerState={{ size, error, success, disabled }}
          {...inputProps}
          {...rest}
        />
      );
    }

    return template;
  },
);

// Setting default values for the props of VuiInput
VuiInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,
};

export default VuiInput;

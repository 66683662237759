import React from "react";

// Vision UI Dashboard PRO React components
import VuiBox from "../../Components/VuiBox";
import VuiTypography from "../../Components/VuiTypography";
import VuiDropzone from "../../Components/VuiDropzone";

export type DropzoneContent = {
  info: { url: string; size: number };
  file: File;
  hasNewFile: boolean;
};

export function extractExtension(fullImageName?: string) {
  const rawExtension = fullImageName?.split(".").pop().split("?")[0];
  return fullImageName != null && rawExtension == fullImageName
    ? ""
    : `.${rawExtension}`;
}

export function convertUrlToBlobUrl(
  url: string,
  setImageUrl?: (x: DropzoneContent) => void,
) {
  if (url) {
    return fetch(url).then(async (res) => {
      const arrayBuffer = await res.arrayBuffer();
      const blob = new Blob([arrayBuffer]);
      const blobbedUrl = URL.createObjectURL(blob);
      const info = { url: blobbedUrl, size: blob.size };
      const file = new File([blob], blob.name);

      setImageUrl && setImageUrl({ info, file, hasNewFile: false });
      return { info, file, hasNewFile: false };
    });
  } else {
    setImageUrl && setImageUrl({ info: null, file: null, hasNewFile: false });
  }
}

function ProjectMedia({
  title,
  label,
  dropzoneContentState,
  noMargin,
}: {
  title: string;
  label: string;
  dropzoneContentState: [DropzoneContent, (x: DropzoneContent) => void];
  noMargin?: boolean;
}) {
  const [dropZoneContent, setDropZoneContent] = dropzoneContentState;

  let mounted = false;

  const onFileUploaded = (file: File) => {
    const blobUrl = URL.createObjectURL(file);
    setDropZoneContent({
      info: { url: blobUrl, size: file.size },
      file,
      hasNewFile: true,
    });
  };

  const onFileRemoved = (file) => {
    // Setting a onFileRemoved handler stops the default behaviour. We have to remove the preview
    // element manually afterwards. This is not explained in the dropzone documentation.
    file.previewElement?.remove();
    if (dropZoneContent?.file && mounted) {
      setDropZoneContent({ info: null, file: null, hasNewFile: true });
    }
  };

  return (
    <VuiBox>
      {title && (
        <VuiTypography variant="h5" color="white">
          {title}
        </VuiTypography>
      )}

      <VuiBox mt={noMargin ? 0 : 3}>
        {label && (
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              color="white"
            >
              {label}
            </VuiTypography>
          </VuiBox>
        )}

        <VuiDropzone
          options={{
            maxFiles: 1,
            addRemoveLinks: true,
            accept: onFileUploaded,
            removedfile: onFileRemoved,
            mount: () => {
              mounted = true;
            },
            unmount: () => {
              mounted = false;
            },
          }}
          file={dropZoneContent?.info}
        />
      </VuiBox>
    </VuiBox>
  );
}

export default ProjectMedia;

import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
  CreateAuthAndUserReqType,
  CreateAuthAndUserResType,
  DeleteAuthAndUserReqType,
  DeleteAuthAndUserResType,
} from "./ApiTypes";

const firebaseConfig = {
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
  appId: process.env.REACT_APP_APPID,
  projectId: process.env.REACT_APP_PROJECTID,
  apiKey: process.env.REACT_APP_APIKEY,
};

const app = initializeApp(firebaseConfig);

const functions = getFunctions(app);

const createCloudFunction = <ReqType, ResType>(
  name: string,
): ((data: ReqType) => Promise<ResType>) => {
  const callable = httpsCallable(functions, name);

  return async (data: ReqType) => {
    const result = await callable(data);

    return result.data as Promise<ResType>;
  };
};

export const createAuthAndUser = createCloudFunction<
  CreateAuthAndUserReqType,
  CreateAuthAndUserResType
>("createAuthAndUser");

export const deleteAuthAndUser = createCloudFunction<
  DeleteAuthAndUserReqType,
  DeleteAuthAndUserResType
>("deleteAuthAndUser");

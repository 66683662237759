import React, { useEffect, useState } from "react";

import { Divider, Icon, Card } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiInput from "@/Components/VuiInput";
import VuiButton from "@/Components/VuiButton";

import UserRoleCard from "./UserRoleCard";

import { useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { currentUserState } from "@/states/auth";
import { useTranslation } from "react-i18next";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { JoinedUserRole } from "@/DatabaseControls/DataTypes";

async function getDatabaseList(
  setUserList: (userList: JoinedUserRole[]) => void,
) {
  const userRoleList = await firebaseSingleton.getJoinedUserRoleList();

  setUserList(userRoleList);
}

function applySearch(searchTerm: string, listData: JoinedUserRole[]) {
  return listData.filter((userRole: JoinedUserRole) => {
    return userRole.AssociatedUser?.Username?.includes(searchTerm);
  });
}

const UserRoleIndexWindow = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPlaceholder, setSearchPlaceholder] = useState("");
  const [hasRetrievedData, setHasRetrievedData] = useState(false);
  const [userRoleList, setUserRoleList] = useState<JoinedUserRole[]>([]);
  const currentUser = useAtomValue(currentUserState);

  const refreshData = () => getDatabaseList(setUserRoleList);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSetSearchTerm = (e) => setSearchTerm(e.target.value);

  const deleteUserRoleItem = (id: string) => {
    firebaseSingleton.deleteUserRole(id).then(() => {
      refreshData();
    });
  };

  if (!hasRetrievedData) {
    refreshData();
    setHasRetrievedData(true);
  }

  useEffect(() => {
    setSearchPlaceholder(t("common.messages.search"));
  }, [t]);

  return (
    <VuiBox mt={6}>
      <VuiBox>
        <Card>
          <VuiBox p={3}>
            <VuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiTypography variant="lg" fontWeight="bold" color="white">
                {t("modules.users.section_titles.list")}
              </VuiTypography>
              <VuiBox display="flex" justifyContent="space-between">
                <VuiBox pr={2} maxWidth={{ sm: "175px", md: "100%" }}>
                  <VuiInput
                    placeholder={searchPlaceholder}
                    onChange={handleSetSearchTerm}
                    icon={{ component: "search", direction: "left" }}
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("sm")]: {
                        maxWidth: "80px",
                      },
                      [breakpoints.only("sm")]: {
                        maxWidth: "80px",
                      },
                      backgroundColor: "info.main !important",
                    })}
                  />
                </VuiBox>
                <VuiButton
                  onClick={() => navigate(`/user-roles/new`, {})}
                  variant="contained"
                  color="info"
                  sx={{ minWidth: "48px", minHeight: "36px", padding: 0 }}
                >
                  <Icon>add</Icon>
                </VuiButton>
              </VuiBox>
            </VuiBox>
            <Divider />
            <VuiBox>
              <VuiBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
              >
                {applySearch(searchTerm, userRoleList).map(
                  (userRole: JoinedUserRole, userIndex: number) => {
                    return (
                      <UserRoleCard
                        key={userRole.id}
                        id={userRole.id}
                        color={
                          currentUser.id === userRole.AssociatedUser.id
                            ? "warning"
                            : "success"
                        }
                        onClick={() =>
                          navigate(`/user-roles/${userRole.id}`, {
                            state: {
                              userRole,
                            },
                          })
                        }
                        deleteUserRole={() => deleteUserRoleItem(userRole.id)}
                        userEmail={userRole.AssociatedUser.Email}
                        companyName={userRole.AssociatedCompany.Name}
                        accessLevel={userRole.AccessLevel}
                      />
                    );
                  },
                )}
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Card>
      </VuiBox>
    </VuiBox>
  );
};

export default UserRoleIndexWindow;

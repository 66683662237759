import React from "react";

import { Grid, Card } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import VuiFormField from "@/Components/VuiFormField";
import { ModelBrand } from "@/DatabaseControls/DataTypes";

import _ from "lodash";

import { useTranslation } from "react-i18next";

const ModelBrandInfo = ({
  modelBrandState,
  setModelBrandState,
  isEdit,
  isLoading,
}: {
  modelBrandState: ModelBrand;
  setModelBrandState: (modelBrand: ModelBrand) => void;
  isEdit: boolean;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { value, name } = e.target;

    const newModelBrandState = _.cloneDeep(modelBrandState);

    newModelBrandState[name] = value;

    setModelBrandState(newModelBrandState);
  };

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox>
        <VuiBox mb={1.5} lineHeight={0} display="flex" flexDirection="column">
          <VuiTypography variant="h5" color="white" fontWeight="bold">
            {t("modules.model_brands.section_titles.information")}
          </VuiTypography>
        </VuiBox>

        <VuiBox mt={1}>
          <Grid container flexDirection="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12}>
                <VuiFormField
                  required
                  type="text"
                  name="Name"
                  label={t("modules.model_brands.data.name")}
                  value={modelBrandState?.Name}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <VuiFormField
                  type="text"
                  name="PhysicalAddress"
                  label={t("modules.model_brands.data.physical_address")}
                  value={modelBrandState?.PhysicalAddress}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <VuiFormField
                  type="text"
                  name="PhoneNumber"
                  label={t("modules.model_brands.data.phone_number")}
                  value={modelBrandState?.PhoneNumber}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12}>
                <VuiFormField
                  type="text"
                  name="LogoURL"
                  label={t("modules.model_brands.data.logo_url")}
                  value={modelBrandState?.LogoURL}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12}>
                <VuiFormField
                  type="text"
                  name="WebsiteURL"
                  label={t("modules.model_brands.data.website_url")}
                  value={modelBrandState?.WebsiteURL}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default ModelBrandInfo;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";

import { ModelBrand } from "@/DatabaseControls/DataTypes";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { getDefaultModelBrand } from "@/DatabaseControls/DataDefaultValues";

import ModelBrandInfo from "./ModelBrandInfo";

import _ from "lodash";

const ModelBrandWindow = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [modelBrandState, setModelBrandState] = useState<ModelBrand | null>(
    null,
  );

  const isEdit = !!params.id;

  useEffect(() => {
    if (isEdit) {
      if (location.state?.modelBrand) {
        setModelBrandState(location.state.modelBrand);
      } else {
        getModelBrand().then((result) => {
          if (result.status) {
            setModelBrandState(result.data);
          } else {
            navigate("/model-brands");
          }
        });
      }
    } else {
      setModelBrandState(getDefaultModelBrand());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.state]);

  const getModelBrand = async () => {
    return await firebaseSingleton.getModelBrand(params.id);
  };

  const saveModelBrand = async () => {
    setIsLoading(true);
    resetSnackbarProps();

    let status = false;
    let message = "Model brand record update failed!";

    if (params.id) {
      if (modelBrandState.Name) {
        await firebaseSingleton.updateModelBrand(modelBrandState).then(() => {
          navigate("/model-brands");

          status = true;
          message = "Model brand record updated successfully.";
        });
      } else {
        message = "Please fill in all the requried (*) fields.";
      }
    } else {
      if (modelBrandState.Name) {
        await firebaseSingleton
          .addModelBrand(modelBrandState)
          .then(async () => {
            navigate("/model-brands");

            status = true;
            message = "Model brand record updated successfully.";
          });
      } else {
        message = "Please fill in all the requried (*) fields.";
      }
    }

    setSnackbarProps({
      open: true,
      icon: status ? "notifications" : "report",
      iconColor: status ? "info" : "error",
      content: message,
    });

    setIsLoading(false);
  };

  const deleteModelBrand = async () => {
    setIsLoading(true);
    resetSnackbarProps();

    await firebaseSingleton
      .deleteModelBrand(modelBrandState.id)
      .then(async () => {
        navigate("/model-brands");
        setIsLoading(false);

        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: "Model brand record deleted successfully.",
        });
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {modelBrandState && (
          <VuiBox my={3} mb="30px">
            <VuiBox mx={1} mt={1} mb={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <VuiTypography variant="h4" fontWeight="medium" color="white">
                    {modelBrandState?.Name || "New Model Brand"}
                  </VuiTypography>
                  <VuiBox mt={1} mb={2}>
                    <VuiTypography variant="body2" color="text">
                      {t("common.pages.edit_description", {
                        title: _.toLower(
                          t("modules.model_brands.title_singular"),
                        ),
                      })}
                    </VuiTypography>
                  </VuiBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VuiBox display="flex" justifyContent="flex-end" gap={1}>
                    {isEdit && (
                      <VuiButton
                        variant="contained"
                        color="error"
                        onClick={deleteModelBrand}
                      >
                        {t("common.buttons.delete")}
                      </VuiButton>
                    )}

                    <VuiButton
                      variant="contained"
                      color="info"
                      onClick={saveModelBrand}
                      disabled={isLoading}
                    >
                      {t("common.buttons.save")}
                    </VuiButton>
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12}>
                <ModelBrandInfo
                  modelBrandState={modelBrandState}
                  setModelBrandState={setModelBrandState}
                  isEdit={!!params.id}
                  isLoading={isLoading}
                />
              </Grid>
            </Grid>
          </VuiBox>
        )}
      </Grid>
    </Grid>
  );
};

export default ModelBrandWindow;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useAtomValue } from "jotai";
import { currentUserState } from "@/states/auth";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";

import {
  Company,
  JoinedUserRole,
  User,
  UserRole,
} from "@/DatabaseControls/DataTypes";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { getDefaultUserRole } from "@/DatabaseControls/DataDefaultValues";

import UserRoleInfo from "./UserRoleInfo";
import { USER_ROLE_LABEL_MAPPER } from "./UserRoleManagementWindow";

export interface UserRoleInputStateType {
  AccessLevel: string;
  AssociatedUser: string;
  AssociatedCompany: string;
}

const UserRoleWindow = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [userRoleState, setUserRoleState] = useState<JoinedUserRole | null>(
    null,
  );
  const [inputState, setInputState] = useState<UserRoleInputStateType>({
    AccessLevel: "0",
    AssociatedUser: "",
    AssociatedCompany: "",
  });

  const [companyList, setCompanyList] = useState<Company[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const currentUser = useAtomValue(currentUserState);

  useEffect(() => {
    if (params.id) {
      if (location.state?.user) {
        setUserRoleState(location.state.userRole);
      } else {
        getUserRole().then((result) => {
          if (result.status) {
            setUserRoleState(result.data);
            getCompanyList();
          } else {
            navigate("/user-roles");
          }
        });
      }
    } else {
      setUserRoleState(getDefaultUserRole() as unknown as JoinedUserRole);
      getCompanyList();
      getUserList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.state]);

  useEffect(() => {
    if (userRoleState) {
      setInputState({
        AccessLevel: userRoleState.AccessLevel,
        AssociatedCompany: userRoleState.AssociatedCompany.id,
        AssociatedUser: params.id
          ? userRoleState.AssociatedUser.id
          : currentUser.id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoleState]);

  const getUserRole = async () => {
    return await firebaseSingleton.getJoinedUserRole(params.id);
  };

  const getCompanyList = async () => {
    const companyList: Map<string, Company> =
      await firebaseSingleton.getDatabaseList("company");
    setCompanyList(Array.from(companyList, ([_, company]) => company));
  };

  const getUserList = async () => {
    const userList: Map<string, User> = await firebaseSingleton.getDatabaseList(
      "user",
    );
    setUserList(Array.from(userList, ([_, user]) => user));
  };

  const saveUserRole = async () => {
    setIsLoading(false);
    resetSnackbarProps();

    const { AssociatedCompany, AssociatedUser, ...restUserRoleState } =
      userRoleState;

    const newUserRole = {
      ...restUserRoleState,
      AssociatedUser: inputState.AssociatedUser,
      AssociatedCompany: inputState.AssociatedCompany,
      AccessLevel: inputState.AccessLevel,
    } as UserRole;

    if (params.id) {
      await firebaseSingleton.updateUserRole(newUserRole).then(() => {
        navigate("/user-roles");
        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: "User Role record updated successfully.",
        });
      });
    } else {
      if (
        newUserRole.AssociatedUser &&
        newUserRole.AssociatedCompany &&
        newUserRole.AccessLevel
      ) {
        await firebaseSingleton.addUserRole(newUserRole).then(() => {
          navigate("/user-roles");
          setSnackbarProps({
            open: true,
            icon: "check_circle",
            content: "User Role record created successfully.",
          });
        });
      }
    }

    setIsLoading(true);
  };

  const roleOptions = Object.keys(USER_ROLE_LABEL_MAPPER).map((level) => {
    return { value: level, label: USER_ROLE_LABEL_MAPPER[level] };
  });

  const companyOptions = companyList.map((company) => {
    return { value: company.id, label: company.Name };
  });

  const userOptions = userList.map((user) => {
    return { value: user.id, label: user.Email };
  });

  return (
    <Grid container justifyContent="center">
      <Grid item mt={6} xs={12}>
        {userRoleState && (
          <VuiBox my={3} mb="30px">
            <VuiBox mx={1} mt={1} mb={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <VuiTypography variant="h4" fontWeight="medium" color="white">
                    {userRoleState?.AssociatedUser.Email}
                  </VuiTypography>
                  <VuiBox mt={1} mb={2}>
                    <VuiTypography variant="body2" color="text">
                      {userRoleState?.AssociatedCompany.Name}
                    </VuiTypography>
                  </VuiBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VuiBox display="flex" justifyContent="flex-end">
                    <VuiButton
                      variant="contained"
                      color="info"
                      onClick={saveUserRole}
                      disabled={isLoading}
                    >
                      {t("common.buttons.save")}
                    </VuiButton>
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12}>
                <UserRoleInfo
                  userRoleState={userRoleState}
                  setUserRoleState={setUserRoleState}
                  inputState={inputState}
                  setInputState={setInputState}
                  isEdit={!!params.id}
                  isLoading={isLoading}
                  roleOptions={roleOptions}
                  companyOptions={companyOptions}
                  userOptions={userOptions}
                />
              </Grid>
            </Grid>
          </VuiBox>
        )}
      </Grid>
    </Grid>
  );
};

export default UserRoleWindow;

import "@/App.css";

import {
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import React, { useState } from "react";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { CookieHelper } from "@/CookieHelper";

// react-router-dom components
import { Link } from "react-router-dom";

// Vision UI Dashboard PRO React components
import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiInput from "@/Components/VuiInput";
import VuiButton from "@/Components/VuiButton";
import VuiSwitch from "@/Components/VuiSwitch";

// Vision UI Dashboard components
import GradientBorder from "@/Components/GradientBorder";

// Authentication layout components
import BasicLayout from "@/Components/BasicLayout";

// Vision UI Dashboard assets
import radialGradient from "@/assets/theme/functions/radialGradient";
import palette from "@/assets/theme/base/colors";
import borders from "@/assets/theme/base/borders";

// Images
import bgBasic from "@/assets/background-basic-auth.png";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { Divider } from "@mui/material";
import { useSetAtom } from "jotai";
import {
  allUserRolesState,
  currentUserRoleState,
  currentUserState,
} from "@/states/auth";

import { useTranslation } from "react-i18next";

type SignInInputType = {
  user: string;
  pass: string;
};

type SignInErrorType = {
  hasError: boolean;
  error: string;
};

export default function SignInWindow(props: any) {
  const [isLoading, setIsLoading] = useState(false);

  const [rememberMe, setRememberMe] = useState<boolean>(
    CookieHelper.getCookie("remember") === "true" || false,
  );

  const [signInState, setSignInState] = useState<SignInInputType>({
    user: "",
    pass: "",
  });
  const [signInError, setSignInError] = useState<SignInErrorType>({
    hasError: false,
    error: "",
  });

  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const handleSetRememberMe = () => {
    CookieHelper.setCookie("remember", "" + !rememberMe);
    setRememberMe(!rememberMe);
  };
  const handleSetUser = (e) => {
    setSignInState({ user: e.target.value, pass: signInState.pass });
    console.log("user: " + e.target.value);
  };

  const handleSetPass = (e) => {
    setSignInState({ pass: e.target.value, user: signInState.user });
    console.log("pass: " + e.target.value);
  };

  const handleSignIn = async () => {
    setIsLoading(true);

    const { user, pass } = signInState;

    setSignInError({
      hasError: false,
      error: "",
    });

    resetSnackbarProps();

    if (user && pass) {
      await firebaseSingleton.setCurrentPersistence(
        rememberMe ? browserLocalPersistence : browserSessionPersistence,
      );

      await firebaseSingleton
        .signIn(user, pass)
        .then(async (user) => {
          await firebaseSingleton.getUser({ uid: user.uid }).then((result) => {
            if (result.status) {
              const user = result.data;

              // setCurrentUser(user);

              setSnackbarProps({
                open: true,
                icon: "notifications",
                iconColor: "info",
                content: `Sign-in successful! Welcome, ${user.Username}.`,
              });

              //   });
            }
          });
        })
        .catch((err) => {
          setSignInError({
            hasError: true,
            error: "Sign in failed. Check your credentials and try again.",
          });

          setSnackbarProps({
            open: true,
            icon: "report",
            iconColor: "error",
            content: "Sign-in failed! Check your credentials and try again.",
          });
        });
    }

    setIsLoading(false);
  };

  const onSignUpclick = () => {
    props.setCurrentPage(1);
  };

  const onResetclick = () => {
    props.setCurrentPage(2);
  };

  return (
    <BasicLayout
      title={t("modules.auth.sign_in.title")}
      description={t("modules.auth.sign_in.subtitle")}
      image={bgBasic}
    >
      <GradientBorder
        borderRadius={borders.borderRadius.form}
        minWidth="100%"
        maxWidth="100%"
      >
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.main,
          })}
        >
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("modules.auth.fields.email")}
              </VuiTypography>
            </VuiBox>

            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <VuiInput
                type="email"
                placeholder={t("modules.auth.fields.email_placeholder")}
                onChange={handleSetUser}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("modules.auth.fields.password")}
              </VuiTypography>
            </VuiBox>

            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <VuiInput
                type="password"
                placeholder={t("modules.auth.fields.password_placeholder")}
                onChange={handleSetPass}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                error={signInError.hasError}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox display="flex" alignItems="center">
            <VuiSwitch
              color="info"
              checked={rememberMe}
              onChange={handleSetRememberMe}
            />
            <VuiTypography
              variant="caption"
              color="white"
              fontWeight="medium"
              onClick={handleSetRememberMe}
              sx={{ cursor: "pointer", userSelect: "none" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;{t("modules.auth.fields.remember")}
            </VuiTypography>
          </VuiBox>
          <VuiBox mt={4} mb={1}>
            <VuiButton
              fullWidth
              color="info"
              onClick={handleSignIn}
              disabled={isLoading}
            >
              {t("modules.auth.buttons.sign_in")}
            </VuiButton>
          </VuiBox>
          <VuiBox mt={3} textAlign="center">
            <VuiBox>
              <VuiTypography variant="button" color="text" fontWeight="regular">
                {t("modules.auth.messages.create_account")}{" "}
              </VuiTypography>

              <VuiTypography
                component={Link}
                onClick={onSignUpclick}
                variant="button"
                color="white"
                fontWeight="medium"
                sx={{
                  color: (theme) => theme.palette.light.main,

                  "&:hover": { color: (theme) => theme.palette.white.main },
                }}
              >
                {t("modules.auth.buttons.sign_up")}
              </VuiTypography>
            </VuiBox>

            <Divider sx={{ m: 1 }} light={true} />

            <VuiBox>
              <VuiTypography
                component={Link}
                onClick={onResetclick}
                variant="button"
                fontWeight="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,

                  "&:hover": { color: (theme) => theme.palette.white.main },
                }}
              >
                {t("modules.auth.messages.forgot_password")}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </BasicLayout>
  );
}

import React from "react";

import Card from "@mui/material/Card";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiSelect from "@/Components/VuiSelect";
import VuiFormField from "@/Components/VuiFormField";

import {
  Furniture,
  FurnitureTypeMapper,
  ModelBrand,
  ModelVariantMapper,
} from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

const FurnitureInfo = ({
  furniture,
  setFurnitureState,
  modelBrandList,
}: {
  furniture?: Furniture;
  setFurnitureState: (x: Furniture) => void;
  modelBrandList: ModelBrand[];
}) => {
  const { t } = useTranslation();

  const handleSetName = (e) => {
    const { Name, ...rest } = furniture;
    setFurnitureState({ Name: e.target.value, ...rest });
  };

  const handleSetCost = (e) => {
    const { Cost, ...rest } = furniture;
    setFurnitureState({ Cost: e.target.value, ...rest });
  };

  const handleSetType = (e) => {
    const { FurnitureType, ...rest } = furniture;
    setFurnitureState({ FurnitureType: e.value, ...rest });
  };

  const handleSetVariant = (e) => {
    const { Variant, ...rest } = furniture;
    setFurnitureState({ Variant: e.value, ...rest });
  };

  const handleSetBrand = (e) => {
    const { Brand, ...rest } = furniture;
    setFurnitureState({ Brand: e.value, ...rest });
  };

  const handleSetPurchaseLink = (e) => {
    const { PurchaseLink, ...rest } = furniture;
    setFurnitureState({ PurchaseLink: e.target.value, ...rest });
  };

  const brandOptions = modelBrandList.map((modelBrand) => ({
    value: modelBrand.id,
    label: modelBrand.Name,
  }));

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox>
        <VuiTypography mb={1.5} variant="h5" color="white">
          Furniture Information
        </VuiTypography>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.furnitures.data.name")}
            value={furniture?.Name}
            onChange={handleSetName}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.furnitures.data.cost")}
            value={furniture?.Cost}
            onChange={handleSetCost}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.furnitures.data.furniture_type")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={{
              value: furniture?.FurnitureType || "0",
              label: FurnitureTypeMapper[furniture?.FurnitureType || "0"],
            }}
            onChange={handleSetType}
            options={Object.keys(FurnitureTypeMapper).map((key) => ({
              value: key,
              label: FurnitureTypeMapper[key],
            }))}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.furnitures.data.variant")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={{
              value: furniture?.Variant || "0",
              label: ModelVariantMapper[furniture?.Variant || "0"],
            }}
            onChange={handleSetVariant}
            options={Object.keys(ModelVariantMapper).map((key) => ({
              value: key,
              label: ModelVariantMapper[key],
            }))}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.furnitures.data.brand")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              furniture?.Brand
                ? brandOptions.find(
                    (brand) => brand.value === furniture?.Brand,
                  ) || null
                : null
            }
            onChange={handleSetBrand}
            options={brandOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.furnitures.data.purchase_link")}
            value={furniture?.PurchaseLink}
            onChange={handleSetPurchaseLink}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default FurnitureInfo;

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Vision UI Context Provider
import { VisionUIControllerProvider } from "./context/Vui";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";

// Vision UI Dashboard PRO React themes
import theme from "./assets/theme";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <VisionUIControllerProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </VisionUIControllerProvider>
  </BrowserRouter>,
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);

import React, { ReactElement } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import CatalogManagementWindow from "@/Pages/CatalogManagement/CatalogManagementWindow";
import CatalogIndexWindow from "@/Pages/CatalogManagement/CatalogIndexWindow";
import CatalogWindow from "@/Pages/CatalogManagement/CatalogWindow";

import CompanyManagementWindow from "@/Pages/CompanyManagement/CompanyManagementWindow";
import CompanyIndexWindow from "@/Pages/CompanyManagement/CompanyIndexWindow";
import CompanyWindow from "@/Pages/CompanyManagement/CompanyWindow";

import ProjectManagementWindow from "@/Pages/ProjectManagement/ProjectManagementWindow";
import ProjectIndexWindow from "@/Pages/ProjectManagement/ProjectIndexWindow";
import ProjectWindow from "@/Pages/ProjectManagement/ProjectWindow";

import UserManagementWindow from "@/Pages/UserManagement/UserManagementWindow";
import UserIndexWindow from "@/Pages/UserManagement/UserIndexWindow";
import UserWindow from "@/Pages/UserManagement/UserWindow";

import UserRoleManagementWindow from "@/Pages/UserRoleManagement/UserRoleManagementWindow";
import UserRoleIndexWindow from "@/Pages/UserRoleManagement/UserRoleIndexWindow";
import UserRoleWindow from "@/Pages/UserRoleManagement/UserRoleWindow";

import ModelBrandManagementWindow from "@/Pages/ModelBrandManagement/ModelBrandManagementWindow";
import ModelBrandIndexWindow from "@/Pages/ModelBrandManagement/ModelBrandndexWindow";
import ModelBrandWindow from "@/Pages/ModelBrandManagement/ModelBrandWindow";

import { useLayout } from "@/hooks/useLayout";
import ProjectEdit from "@/Pages/ProjectManagement/ProjectEdit";

export interface RouteType {
  name: string;
  title?: string;
  icon?: string;
  key: string;
  localeKey: string;
  path: string;
  redirectPath?: string;
  component?: (props?: any) => JSX.Element;
  nestedRoutes?: RouteType[];
}

export interface RouteInfoType {
  title: string;
  name: string;
  key: string;
  localeKey: string;
  icon?: string;
  path?: string;
}

const TempComp = (props) => {
  useLayout(props.routeInfo);

  return <></>;
};

const ALL_ROUTES: RouteType[] = [
  {
    title: "Home",
    name: "Home",
    key: "home",
    localeKey: "home",
    icon: "home",
    path: "/",
    component: TempComp,
  },
  {
    title: "Catalog Management",
    name: "CatalogManagement",
    key: "catalog_management",
    localeKey: "catalogs",
    icon: "import_contacts",
    path: "/catalogs",
    component: CatalogManagementWindow,
    nestedRoutes: [
      {
        name: "CatalogManagement",
        key: "catalog_management",
        localeKey: "catalogs",
        path: "",
        component: CatalogIndexWindow,
      },
      {
        name: "CatalogManagement",
        key: "catalog_management",
        localeKey: "catalogs",
        path: ":id",
        component: CatalogWindow,
      },
      {
        name: "CatalogManagement",
        key: "catalog_management",
        localeKey: "catalogs",
        path: "new",
        component: CatalogWindow,
      },
    ],
  },
  {
    title: "Company Management",
    name: "CompanyManagement",
    key: "company_managment",
    localeKey: "companies",
    icon: "business",
    path: "/companies",
    component: CompanyManagementWindow,
    nestedRoutes: [
      {
        name: "CompanyManagement",
        key: "company_managment",
        localeKey: "companies",
        path: "",
        component: CompanyIndexWindow,
      },
      {
        name: "CompanyManagement",
        key: "company_managment",
        localeKey: "companies",
        path: ":id",
        component: CompanyWindow,
      },
      {
        name: "CompanyManagement",
        key: "company_managment",
        localeKey: "companies",
        path: "new",
        component: CompanyWindow,
      },
    ],
  },
  // {
  //   title: "Language",
  //   name: "LanguageManagement",
  //   key: "language_managment",
  //   localeKey: "languages",
  //   icon: "language",
  //   path: "/languages",
  //   component: TempComp,
  // },
  {
    title: "Model Brand Management",
    name: "ModelBrandManagement",
    key: "model_brand_managment",
    localeKey: "model_brands",
    icon: "sell",
    path: "/model-brands",
    component: ModelBrandManagementWindow,
    nestedRoutes: [
      {
        name: "CompanyManagement",
        key: "company_managment",
        localeKey: "companies",
        path: "",
        component: ModelBrandIndexWindow,
      },
      {
        name: "CompanyManagement",
        key: "company_managment",
        localeKey: "companies",
        path: ":id",
        component: ModelBrandWindow,
      },
      {
        name: "CompanyManagement",
        key: "company_managment",
        localeKey: "companies",
        path: "new",
        component: ModelBrandWindow,
      },
    ],
  },
  {
    title: "Project Management",
    name: "ProjectManagement",
    key: "project_managment",
    localeKey: "projects",
    icon: "account_tree",
    path: "/projects",
    component: ProjectManagementWindow,
    nestedRoutes: [
      {
        name: "ProjectManagement",
        key: "project_managment",
        localeKey: "projects",
        path: "",
        component: ProjectIndexWindow,
      },
      {
        name: "ProjectManagement",
        key: "project_managment",
        localeKey: "projects",
        path: ":id",
        component: ProjectEdit,
      },
      {
        name: "ProjectManagement",
        key: "project_managment",
        localeKey: "projects",
        path: "new",
        component: ProjectWindow,
      },
    ],
  },
  // {
  //   title: "Theme",
  //   name: "ThemeManagement",
  //   key: "theme_managment",
  //   localeKey: "themes",
  //   icon: "web",
  //   path: "/themes",
  //   component: TempComp,
  // },
  {
    title: "User Management",
    name: "UserManagement",
    key: "user_managment",
    localeKey: "users",
    icon: "person",
    path: "/users",
    component: UserManagementWindow,
    nestedRoutes: [
      {
        name: "UserManagement",
        key: "user_managment",
        localeKey: "users",
        path: "",
        component: UserIndexWindow,
      },
      {
        name: "UserManagement",
        key: "user_managment",
        localeKey: "users",
        path: ":id",
        component: UserWindow,
      },
      {
        name: "UserManagement",
        key: "user_managment",
        localeKey: "users",
        path: "new",
        component: UserWindow,
      },
    ],
  },
  {
    title: "User Role Management",
    name: "UserRoleManagement",
    key: "user_role_managment",
    localeKey: "user_roles",
    icon: "admin_panel_settings",
    path: "/user-roles",
    component: UserRoleManagementWindow,
    nestedRoutes: [
      {
        name: "UserRoleManagement",
        key: "user_role_managment",
        localeKey: "user_roles",
        path: "",
        component: UserRoleIndexWindow,
      },
      {
        name: "UserRoleManagement",
        key: "user_role_managment",
        localeKey: "user_roles",
        path: ":id",
        component: UserRoleWindow,
      },
      {
        name: "UserRoleManagement",
        key: "user_role_managment",
        localeKey: "user_roles",
        path: "new",
        component: UserRoleWindow,
      },
    ],
  },
  {
    title: "Not Found",
    name: "404",
    key: "404",
    localeKey: "404",
    path: "*",
    redirectPath: "/",
  },
];

const createRoute = (route, nRouteIndex, pRoute?: any): ReactElement => {
  const routeInfo: RouteInfoType = {
    key: route.key,
    localeKey: route.localeKey,
    title: route.title,
    name: route.name,
    path: route.path,
    icon: route.icon,
  };

  return (
    <Route
      key={nRouteIndex}
      path={route.path}
      element={
        route.redirectPath ? (
          <Navigate to={route.redirectPath} />
        ) : (
          <route.component routeInfo={routeInfo} />
        )
      }
    >
      {route.nestedRoutes?.map((nRoute, nRouteIndex) =>
        createRoute(nRoute, nRouteIndex, route),
      )}
    </Route>
  );
};

const RoutesContainer = () => {
  return (
    <>
      <Routes>
        {ALL_ROUTES.map((route, routeIndex) => {
          return createRoute(route, routeIndex);
        })}
      </Routes>
    </>
  );
};

export default RoutesContainer;

/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

// Vision UI Dashboard PRO React base styles
import colors from "../../assets/theme/base/colors";

// Custom styles for VuiSelect
import styles from "./styles";

const VuiSelect = forwardRef<any, any>(
  ({ size, error, success, freesolo, ...rest }, ref) => {
    const { light } = colors;

    const baseProps = {
      ...rest,
      ref: ref,
      styles: styles(size, error, success),
      theme: (theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      }),
    };

    return (
      <>
        {freesolo ? (
          <CreatableSelect
            {...baseProps}
            formatCreateLabel={(inputValue) => {
              return 'Add "'.concat(inputValue, '"');
            }}
          />
        ) : (
          <Select {...baseProps} />
        )}
      </>
    );
  },
);

// Setting default values for the props of VuiSelect
VuiSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
};

// Typechecking props for the VuiSelect
VuiSelect.propTypes = {
  size: PropTypes.oneOf(["extraSmall", "small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default VuiSelect;

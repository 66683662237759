import React, { useEffect, useState } from "react";

import { useAtom } from "jotai";
import { materialsModalState } from "@/states/catalogs/modals";

import MaterialWindow from "@/Pages/MaterialManagement/MaterialWindow";

import BaseModal from "@/Components/BaseModal";
import VuiBox from "@/Components/VuiBox";

import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useTranslation } from "react-i18next";

import { Catalog, Material, ModelBrand } from "@/DatabaseControls/DataTypes";
import { getDefaultMaterial } from "@/DatabaseControls/DataDefaultValues";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

interface MaterialsModalPropsType {
  catalogState: Catalog;
  setCatalogState: (catalog: Catalog) => void;
  refreshData: () => void;
  modelBrandList: ModelBrand[];
}

const MaterialsModal = (props: MaterialsModalPropsType) => {
  const { t } = useTranslation();

  const { catalogState, setCatalogState, refreshData, modelBrandList } = props;

  const [materialsModal, setMaterialsModal] = useAtom(materialsModalState);
  const [materialState, setMaterialState] = useState<Material | null>(null);
  const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(
    null,
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();

  const isEdit = !!selectedMaterial;
  const type = materialsModal?.props?.type;

  useEffect(() => {
    if (materialsModal.isShow) {
      if (materialsModal.props.selectedMaterial) {
        setSelectedMaterial(materialsModal.props.selectedMaterial);
        setMaterialState(materialsModal.props.selectedMaterial);
      } else {
        setMaterialState(getDefaultMaterial());
      }

      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialsModal.isShow, catalogState]);

  const saveMaterial = async () => {
    resetSnackbarProps();

    if (isEdit) {
      await firebaseSingleton.updateMaterial(materialState).then(() => {
        handleClose();
        refreshData();
      });

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Material record updated successfully.",
      });
    } else {
      const newMaterialId = firebaseSingleton.getNewDocumentId("material");

      await firebaseSingleton
        .addMaterial(materialState, newMaterialId, type, catalogState.id)
        .then(() => {
          handleClose();
          refreshData();

          setCatalogState({
            ...catalogState,
            [`${type}Materials`]: [
              ...catalogState[`${type}Materials`],
              newMaterialId,
            ],
          });
        });

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Material record created successfully.",
      });
    }
  };

  const deleteMaterial = async () => {
    if (selectedMaterial) {
      await firebaseSingleton
        .deleteMaterial(selectedMaterial.id, type, catalogState.id)
        .then(() => {
          handleClose();
          refreshData();

          if (type) {
            setCatalogState({
              ...catalogState,
              [`${type}Materials`]: catalogState[`${type}Materials`].filter(
                (mId) => {
                  return mId !== selectedMaterial.id;
                },
              ),
            });
          }

          setSnackbarProps({
            open: true,
            icon: "check_circle",
            content: "Material record deleted successfully.",
          });
        });
    }
  };

  const handleClose = (): void => {
    setSelectedMaterial(null);

    setIsLoaded(false);

    setMaterialsModal({
      ...materialsModal,
      isShow: false,
      props: {
        type: materialsModal.props.type,
      },
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    saveMaterial();
  };

  return (
    <BaseModal
      title={
        selectedMaterial
          ? t("modules.materials.section_titles.edit")
          : t("modules.materials.section_titles.create")
      }
      open={materialsModal.isShow}
      onClose={handleClose}
      onDone={handleSubmit}
    >
      <VuiBox sx={{ minHeight: 600 }}>
        {isLoaded && (
          <MaterialWindow
            materialState={materialState}
            setMaterialState={setMaterialState}
            modelBrandList={modelBrandList}
            onSave={saveMaterial}
            onDelete={deleteMaterial}
          />
        )}
      </VuiBox>
    </BaseModal>
  );
};

export default MaterialsModal;

import React from "react";

import { Outlet } from "react-router-dom";

import { useLayout } from "@/hooks/useLayout";

const ModelBrandManagementWindow = (props) => {
  useLayout(props.routeInfo);

  return <Outlet />;
};

export default ModelBrandManagementWindow;

import React, { useState } from "react";

import { Icon } from "@mui/material";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import borders from "@/assets/theme/base/borders";
import colors from "@/assets/theme/base/colors";

import { useTranslation } from "react-i18next";
import { ModelBrand } from "@/DatabaseControls/DataTypes";

const ModelBrandCard = ({
  modelBrand,
  color,
  onClick,
  deleteModelBrand,
  noDivider,
}: {
  modelBrand: ModelBrand;
  color: string;
  onClick: () => void;
  deleteModelBrand: (id: string) => void;
  noDivider: boolean;
}) => {
  const { borderWidth } = borders;

  const [openMenu, setOpenMenu] = useState(null);

  const { t } = useTranslation();

  const handleOpenMenu = (e) => {
    e.stopPropagation();

    setOpenMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleDeleteModelBrand = () => {
    handleCloseMenu();
    deleteModelBrand(modelBrand.id);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onClick={(e) => e.stopPropagation()}
      keepMounted
    >
      <MenuItem onClick={handleDeleteModelBrand}>
        {t("common.buttons.delete")}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <VuiBox
        component="li"
        width="100%"
        pr={2}
        mt={0.5}
        py={1}
        borderLeft={`${borderWidth[4]} solid ${colors[color].main}`}
        onClick={onClick}
        sx={{
          listStyle: "none",
          cursor: "pointer",
          "&:hover": {
            marginLeft: `-${borderWidth[4]}`,
            transition: "all 100ms ease-in-out",
          },
        }}
      >
        <VuiBox width="100%">
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            pl={0.5}
          >
            <VuiBox display="flex" alignItems="center" width="20rem">
              {modelBrand.LogoURL ? (
                <VuiBox
                  component="img"
                  onLoad={() => console.log("load")}
                  ml={2.5}
                  mr={2}
                  src={modelBrand.LogoURL}
                  alt={modelBrand.Name}
                  sx={({ borders: { borderRadius }, palette }) => ({
                    width: "48px",
                    height: "48px",
                    objectFit: "contain",
                    boxShadow: "0 0 2px rgba(255, 255, 255, 1);",
                    borderRadius: borderRadius.md,
                  })}
                />
              ) : (
                <VuiBox
                  ml={2.5}
                  mr={2}
                  sx={({ borders: { borderRadius }, palette }) => ({
                    width: "48px",
                    minHeight: "48px",
                    borderRadius: borderRadius.lg,
                    backgroundColor: palette.dark.main,
                  })}
                />
              )}

              <VuiTypography variant="button" fontWeight="medium" color="white">
                {modelBrand.Name}
              </VuiTypography>
            </VuiBox>

            <VuiBox width="10rem" lineHeight={1} mb={{ xs: 1, sm: 0 }}>
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.model_brands.data.physical_address")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {modelBrand.PhysicalAddress || "-"}
              </VuiTypography>
            </VuiBox>

            <VuiBox
              width="10rem"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: { display: "none" },
              })}
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.model_brands.data.id")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {modelBrand.id}
              </VuiTypography>
            </VuiBox>

            <VuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
              <Icon
                fontSize="default"
                sx={{ cursor: "pointer", color: "#fff" }}
                onClick={handleOpenMenu}
              >
                more_horiz
              </Icon>
            </VuiBox>
            {renderMenu()}
          </VuiBox>
        </VuiBox>
      </VuiBox>
      {noDivider ? null : <Divider light sx={{ marginBottom: 0 }} />}
    </>
  );
};

ModelBrandCard.defaultProps = {
  color: "info",
  noDivider: false,
  removeFromCatalog: undefined,
};

export default ModelBrandCard;

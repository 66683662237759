/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// @mui material components
import Icon from "@mui/material/Icon";
import { styled } from "@mui/material/styles";

export interface VUISnackbarIconRootProps {
  ownerState: VUISnackBarIconRootState;
}

export interface VUISnackBarIconRootState {
  color: string;
}

export default styled(Icon)<VUISnackbarIconRootProps>(({
  theme,
  ownerState,
}) => {
  const { palette, functions, typography } = theme;
  const { color } = ownerState;

  const { pxToRem } = functions;
  const { size } = typography;

  return {
    color: palette[color].main,
    marginRight: pxToRem(8),
    fontSize: size.lg,
    transform: `translateY(${pxToRem(-2)})`,
  };
});

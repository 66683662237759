import React, { useEffect, useState } from "react";
import { DateInputToDateTime, DateTimeToDateInput } from "./ProjectWindow";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import ProjectMedia, {
  DropzoneContent,
  convertUrlToBlobUrl,
  extractExtension,
} from "./ProjectMedia";
import {
  Project,
  ProjectFormInput,
  ProjectComments,
  Project360Photo,
} from "@/DatabaseControls/DataTypes";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import VuiBox from "@/Components/VuiBox";
import VuiFormField from "@/Components/VuiFormField";
import VuiTypography from "@/Components/VuiTypography";
import { Grid, Card, Box, Link, Typography, Paper } from "@mui/material";
import DateInput from "@/Components/DateInput/DateInput";
import VuiButton from "@/Components/VuiButton";
import _ from "lodash";
import CheckBox from "@mui/material/Checkbox";

const ProjectImage = ({
  dropZoneContentState,
  projectState,
  setProjectState,
  imageLocation,
  imageName,
  propertyName,
  isDisplayLink = false,
  emptyLabel = "No Image Provided",
}: {
  dropZoneContentState: [DropzoneContent, (x: DropzoneContent) => void];
  projectState: Project;
  setProjectState: (project: Project) => void;
  imageLocation: string;
  imageName: string;
  propertyName: string;
  isDisplayLink?: boolean;
  emptyLabel?: string;
}) => {
  const [dropZoneContent] = dropZoneContentState;
  const [isEditingMedia, setIsEditingMedia] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setSnackbarProps } = useSnackbar();
  const { t } = useTranslation();
  const src = projectState[propertyName];

  const handleMediaUpdate = async (): Promise<void> => {
    const newProject = _.cloneDeep(projectState);
    const promises: Promise<any>[] = [];

    if (dropZoneContent.file) {
      const extension = extractExtension(dropZoneContent?.file?.name);
      const fileName = imageName + "-" + projectState.id + extension;

      promises.push(
        firebaseSingleton
          .uploadMedia(imageLocation, fileName, dropZoneContent.file)
          .then(() => {
            return firebaseSingleton
              .getMediaUrl(imageLocation, fileName)
              .then((newUrl: string) => {
                newProject[propertyName] = newUrl;
              });
          })
          .catch((error) => {
            console.error(error);
          }),
      );
    } else if (projectState[propertyName]) {
      const extension = extractExtension(projectState[propertyName]);
      const fileName = imageName + "-" + projectState.id + extension;

      newProject[propertyName] = "";

      promises.push(firebaseSingleton.deleteMedia(imageLocation, fileName));
    }

    return Promise.all(promises).then(async () => {
      return await firebaseSingleton.updateProject(newProject).then(() => {
        setProjectState(newProject);

        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: "Project record updated successfully.",
        });
      });
    });
  };

  const handleEdit = async () => {
    setIsLoading(true);

    if (isEditingMedia) {
      if (dropZoneContent && dropZoneContent.hasNewFile) {
        await handleMediaUpdate();
      }

      setIsEditingMedia(false);
    } else {
      setIsEditingMedia(true);
    }

    setIsLoading(false);
  };
  return (
    <>
      {!isEditingMedia ? (
        <>
          {src ? (
            <>
              {isDisplayLink ? (
                <Link href={src} sx={{ color: "white !important" }}>
                  {src}
                </Link>
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <VuiBox
                    component="img"
                    src={src}
                    alt="Product Image"
                    borderRadius="lg"
                    shadow="lg"
                    width="100%"
                  />
                </Grid>
              )}
            </>
          ) : (
            <VuiTypography mb={3} variant="body2" color="white">
              {emptyLabel}
            </VuiTypography>
          )}
        </>
      ) : (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              zIndex: 50,
              top: 0,
              right: 0,
              width: "100%",
              height: "100%",
              background: "black",
              opacity: 0.5,
              userSelect: "none",
              display: isLoading ? "block" : "none",
            }}
          />

          <ProjectMedia
            noMargin
            title={""}
            label={""}
            dropzoneContentState={dropZoneContentState}
          />
        </Box>
      )}

      <VuiBox display="flex" mt={2}>
        <VuiButton
          disabled={isLoading}
          variant="contained"
          color={isLoading ? "dark" : "info"}
          size="small"
          sx={{ mr: "8px" }}
          onClick={handleEdit}
        >
          {isEditingMedia
            ? t("common.buttons.save")
            : src
            ? t("common.buttons.edit")
            : t("common.buttons.upload")}
        </VuiButton>

        {isEditingMedia && (
          <VuiButton
            disabled={isLoading}
            variant="contained"
            color={isLoading ? "dark" : "error"}
            size="small"
            sx={{ mr: "8px" }}
            onClick={() => setIsEditingMedia(false)}
          >
            {t("common.buttons.cancel")}
          </VuiButton>
        )}
      </VuiBox>
    </>
  );
};

const ProjectComment = ({ comment }: { comment: ProjectComments }) => {
  const originalPoster = comment?.OriginalPoster;
  const commentImage = comment?.ImageLocation;
  const datePosted = comment?.DatePosted;
  const initialComment = comment?.InitialComment;
  const commentStatus = comment?.Status;
  const commentID = comment?.id;

  return (
    <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={4}>
        <img src={commentImage} alt="Comment Image" style={{ width: "100%" }} />
      </Grid>
      <Grid item xs={8}>
        <VuiBox display="flex-start" flexDirection="row">
          <VuiTypography variant="h6" color="white">
            Initial Comment: {initialComment}
          </VuiTypography>
        </VuiBox>
        <Grid item alignItems="flex-start" display="flex">
          <VuiBox lineHeight={0} display="flex" flexDirection="row">
            <VuiTypography variant="h6" color="white">
              ID: {commentID}
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item alignItems="flex-start" display="flex">
          <VuiBox lineHeight={0} display="flex" flexDirection="row">
            <VuiTypography variant="h6" color="white">
              Date Posted: {datePosted}
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item alignItems="flex-start" display="flex">
          <VuiBox lineHeight={0} display="flex" flexDirection="row">
            <VuiTypography variant="h6" color="white">
              Original Poster: {originalPoster}
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item alignItems="flex-start" display="flex">
          <VuiBox lineHeight={0} display="flex" flexDirection="row">
            <VuiTypography variant="h6" color="white">
              Comment Status: {commentStatus}
            </VuiTypography>
          </VuiBox>
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

const Project360Image = ({ photos }: { photos: Project360Photo }) => {
  const originalPoster = photos?.OriginalPoster;
  const image = photos?.ImageLocation;
  const datePosted = photos?.DatePosted;
  const id = photos?.id;

  return (
    <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={4}>
        <img src={image} alt="Comment Image" style={{ width: "100%" }} />
      </Grid>
      <Grid item xs={8}>
        <Grid item alignItems="flex-start" display="flex">
          <VuiBox lineHeight={0} display="flex" flexDirection="row">
            <VuiTypography variant="h6" color="white">
              ID: {id}
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item alignItems="flex-start" display="flex">
          <VuiBox lineHeight={0} display="flex" flexDirection="row">
            <VuiTypography variant="h6" color="white">
              Date Posted: {datePosted}
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item alignItems="flex-start" display="flex">
          <VuiBox lineHeight={0} display="flex" flexDirection="row">
            <VuiTypography variant="h6" color="white">
              Original Poster: {originalPoster}
            </VuiTypography>
          </VuiBox>
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

async function getCommentDatabaseList(
  project: Project,
  setProjectCommentList: (projectCommentList: ProjectComments[] | null) => void,
) {
  const commentList: Map<string, ProjectComments> =
    project.Data.Comments ?? null;
  setProjectCommentList(commentList ? Object.values(commentList) : null);
}

async function get360PhotoDatabaseList(
  project: Project,
  setProject360PhotoList: (
    project360PhotoList: Project360Photo[] | null,
  ) => void,
) {
  const threesixtyPhotoMap: Map<string, Project360Photo> =
    project.Data.Photos ?? null;
  setProject360PhotoList(
    threesixtyPhotoMap ? Object.values(threesixtyPhotoMap) : null,
  );
}

const ProjectEdit = () => {
  const [projectState, setProjectState] = useState<Project | null>(null);
  const [projectDetails, setProjectDetails] = useState<ProjectFormInput>({
    Name: "New Project",
    Address: "",
    StartDate: { day: 1, month: 1, year: new Date().getFullYear() },
    EndDate: { day: 1, month: 1, year: new Date().getFullYear() },
    isHQFBX: false,
  });
  const [realWorldPreview, setRealWorldPreview] = useState<DropzoneContent>();
  const [modelPreview, setModelPreview] = useState<DropzoneContent>();
  const [modelFbx, setModelFbx] = useState<DropzoneContent>();
  const [idToken, setIdToken] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [updatingProject, setUpdatingProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isHQFBX, setIsHQFBX] = useState(false);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { setSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const [comments, setComments] = useState([] as ProjectComments[] | null);
  const [threeSixtyPhotos, setThreeSixtyPhotos] = useState(
    [] as Project360Photo[] | null,
  );

  useEffect(() => {
    getProject().then((result) => {
      if (result.status) {
        initStates(result.data);
      } else {
        navigate("/projects");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.state]);
  const getProject = async () => {
    return await firebaseSingleton.getProject(params.id);
  };

  const initStates = async (project: Project) => {
    setProjectState({
      ...project,
      isHQFBX: project.isHQFBX || false,
    });

    getCommentDatabaseList(project, setComments);
    get360PhotoDatabaseList(project, setThreeSixtyPhotos);
    handleToggleSwitch(project.isHQFBX, setIsHQFBX);

    await convertUrlToBlobUrl(project.RealWorldImageUrl, setRealWorldPreview);
    await convertUrlToBlobUrl(
      project["3DModelPreviewImageUrl"],
      setModelPreview,
    );
    await convertUrlToBlobUrl(project.Current3DModelLocation, setModelFbx);

    firebaseSingleton.getCurrentUserToken().then((token) => {
      setIdToken(token);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const newProjectState = _.cloneDeep(projectState);

    newProjectState[name] = value;

    setProjectState(newProjectState);
  };

  const handleDateChange = (dateInput, type: "start" | "end") => {
    const newProjectState = _.cloneDeep(projectState);

    const value = DateInputToDateTime(dateInput);

    if (type === "start") {
      newProjectState.StartDate = value;
    } else {
      newProjectState.EndDate = value;
    }

    setProjectState(newProjectState);
  };

  const handleIsHQFBXChange = (e) => {
    const { name, checked } = e.target;

    handleToggleSwitch(checked, setIsHQFBX);

    const newProjectState = _.cloneDeep(projectState);

    newProjectState[name] = checked;

    setProjectState(newProjectState);
  };

  const handleToggleSwitch = (state, setState) => {
    setState(state);
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (projectState.Name) {
      await firebaseSingleton.updateProject(projectState).then(() => {
        setActiveStep(activeStep + 1);

        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: "Project record updated successfully.",
        });
      });
    }

    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsLoading(true);

    await firebaseSingleton.deleteProject(projectState.id).then(() => {
      navigate("/projects");
    });

    setIsLoading(false);
  };

  const handleWebRTCLink = async (id) => {
    const link = `https://jbuildzoom.web.app/?RoomName=${id}`;
    try {
      await navigator.clipboard.writeText(link);
    } catch (err) {
      console.error("Failed to copy link", err);
    }
    return setSnackbarProps({
      open: true,
      icon: "check_circle",
      content: "Link copied successfully.",
    });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        {projectState && (
          <VuiBox my={3} mb="30px">
            <VuiBox mx={1} mt={1} mb={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <VuiTypography variant="h4" fontWeight="medium" color="white">
                    {projectState.Name}
                  </VuiTypography>
                  <VuiBox mt={1} mb={2}>
                    <VuiTypography variant="body2" color="text">
                      {t("common.pages.edit_description", {
                        title: _.toLower(t("modules.projects.title_singular")),
                      })}
                    </VuiTypography>
                  </VuiBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <VuiBox display="flex" justifyContent="flex-end" gap={1}>
                    <VuiButton
                      variant="contained"
                      color="error"
                      disabled={isLoading}
                      onClick={handleDelete}
                    >
                      {t("common.buttons.delete")}
                    </VuiButton>

                    <VuiButton
                      variant="contained"
                      color="info"
                      onClick={handleSave}
                      disabled={isLoading}
                    >
                      {t("common.buttons.save")}
                    </VuiButton>
                  </VuiBox>
                </Grid>
              </Grid>
            </VuiBox>

            <Grid container direction="column" gap={4} wrap="nowrap">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={4}></Grid>

                  <Grid item xs={12}>
                    <Card sx={{ overflow: "visible" }}>
                      <VuiBox
                        mb={2.5}
                        lineHeight={0}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <VuiTypography
                          variant="h5"
                          color="white"
                          fontWeight="bold"
                        >
                          {t("modules.projects.section_titles.information")}
                        </VuiTypography>
                      </VuiBox>

                      <VuiBox>
                        <Grid container flexDirection="column" spacing={2}>
                          <Grid item container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <VuiFormField
                                type="text"
                                label={t("modules.projects.data.name")}
                                name="Name"
                                value={projectState.Name}
                                onChange={handleInputChange}
                                disabled={isLoading}
                              />
                            </Grid>
                          </Grid>

                          <Grid item container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <VuiFormField
                                type="text"
                                label={t("modules.projects.data.address")}
                                name="PhysicalAddress"
                                value={projectState.PhysicalAddress}
                                onChange={handleInputChange}
                                disabled={isLoading}
                              />
                            </Grid>
                          </Grid>

                          <Grid item container spacing={2}>
                            <Grid item sm={12} md={6}>
                              <DateInput
                                label={t("modules.projects.data.start_date")}
                                value={projectDetails.StartDate}
                                onChangeDate={(dateInput) =>
                                  handleDateChange(dateInput, "start")
                                }
                              />
                            </Grid>
                            <Grid item sm={12} md={6}>
                              <DateInput
                                label={t("modules.projects.data.end_date")}
                                value={projectDetails.EndDate}
                                onChangeDate={(dateInput) =>
                                  handleDateChange(dateInput, "end")
                                }
                              />
                            </Grid>
                            <Grid item sm={12} md={6}>
                              <VuiBox mt={1}>
                                <VuiBox
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="flex-end"
                                  height="100%"
                                >
                                  <VuiTypography
                                    color="white"
                                    component="label"
                                    variant="h7"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                  >
                                    {t("modules.projects.data.is_hqfbx")}
                                  </VuiTypography>
                                </VuiBox>
                                <VuiBox
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="flex-end"
                                  height="100%"
                                >
                                  <CheckBox
                                    name="isHQFBX"
                                    checked={isHQFBX || false}
                                    onChange={handleIsHQFBXChange}
                                  />
                                </VuiBox>
                              </VuiBox>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                              <VuiTypography
                                color="white"
                                component="label"
                                variant="h5"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t("modules.projects.data.webrtc_link")}
                              </VuiTypography>
                              <VuiBox
                                display="flex"
                                justifyContent="flex-start"
                                gap={1}
                              >
                                <VuiButton
                                  variant="contained"
                                  color="inherit"
                                  disabled={isLoading}
                                  onClick={() =>
                                    handleWebRTCLink(projectState.id)
                                  }
                                >
                                  {t("common.buttons.link")}
                                </VuiButton>
                              </VuiBox>
                            </Grid>
                          </Grid>
                        </Grid>
                      </VuiBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Card sx={{ overflow: "visible" }}>
                      <VuiBox
                        mb={2.5}
                        lineHeight={0}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <VuiTypography
                          variant="h5"
                          color="white"
                          fontWeight="bold"
                        >
                          {t("modules.projects.section_titles.model_fbx")}
                        </VuiTypography>
                      </VuiBox>

                      <VuiBox>
                        <Grid container flexDirection="column" spacing={2}>
                          <Grid item container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <ProjectImage
                                dropZoneContentState={[modelFbx, setModelFbx]}
                                imageLocation="ProjectModels"
                                imageName="model"
                                propertyName="Current3DModelLocation"
                                projectState={projectState}
                                setProjectState={setProjectState}
                                isDisplayLink={true}
                                emptyLabel="No FBX Provided"
                              />
                            </Grid>

                            {projectState.Current3DModelLocation &&
                              modelFbx?.info?.url?.startsWith("blob") && (
                                <Grid
                                  item
                                  xs={12}
                                  mt={3}
                                  key={projectState.Current3DModelLocation}
                                >
                                  <VuiBox>
                                    <VuiTypography
                                      variant="h5"
                                      color="white"
                                      fontSize={15}
                                      mb={3}
                                    >
                                      {t(
                                        "modules.projects.section_titles.configure_starting_position",
                                      )}
                                    </VuiTypography>

                                    <VuiBox>
                                      <iframe
                                        title={projectState.id}
                                        allow={"fullscreen"}
                                        width={"100%"}
                                        height={"400px"}
                                        src={`https://jbuild-model-starting-placer.web.app/?ProjectID=${projectState.id}&FirebaseToken=${idToken}`}
                                      />
                                    </VuiBox>
                                  </VuiBox>
                                </Grid>
                              )}
                          </Grid>
                        </Grid>
                      </VuiBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Card sx={{ overflow: "visible" }}>
                      <VuiBox
                        mb={2.5}
                        lineHeight={0}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <VuiTypography
                          variant="h5"
                          color="white"
                          fontWeight="bold"
                        >
                          {t(
                            "modules.projects.section_titles.real_world_preview",
                          )}
                        </VuiTypography>
                      </VuiBox>

                      <VuiBox>
                        <Grid container flexDirection="column" spacing={2}>
                          <Grid item container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <ProjectImage
                                dropZoneContentState={[
                                  realWorldPreview,
                                  setRealWorldPreview,
                                ]}
                                imageLocation="ProjectImages"
                                imageName="thumbnail"
                                propertyName="RealWorldImageUrl"
                                projectState={projectState}
                                setProjectState={setProjectState}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </VuiBox>
                    </Card>
                  </Grid>

                  <Grid item xs={4}>
                    <Card sx={{ overflow: "visible" }}>
                      <VuiBox
                        mb={2.5}
                        lineHeight={0}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <VuiTypography
                          variant="h5"
                          color="white"
                          fontWeight="bold"
                        >
                          {t(
                            "modules.projects.section_titles.3d_model_preview",
                          )}
                        </VuiTypography>
                      </VuiBox>

                      <VuiBox>
                        <Grid container flexDirection="column" spacing={2}>
                          <Grid item container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <ProjectImage
                                dropZoneContentState={[
                                  modelPreview,
                                  setModelPreview,
                                ]}
                                imageLocation="ProjectImages"
                                imageName="preview"
                                propertyName="3DModelPreviewImageUrl"
                                projectState={projectState}
                                setProjectState={setProjectState}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </VuiBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Card sx={{ overflow: "visible" }}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <VuiBox
                          mb={2.5}
                          lineHeight={0}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <VuiTypography
                            variant="h5"
                            color="white"
                            fontWeight="bold"
                          >
                            {t("modules.projects.section_titles.comments")}
                          </VuiTypography>
                        </VuiBox>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <VuiBox>
                          {comments ? (
                            <>
                              {comments.map(
                                (comments: ProjectComments, i: number) => {
                                  return (
                                    <Grid
                                      container
                                      flexDirection="column"
                                      spacing={2}
                                    >
                                      <ProjectComment comment={comments} />
                                    </Grid>
                                  );
                                },
                              )}
                            </>
                          ) : (
                            <Grid
                              container
                              justifyContent="column"
                              alignItems="center"
                            >
                              <Grid item xs={4}>
                                <VuiTypography
                                  variant="h7"
                                  justfiyContent="center"
                                  alignItems="center"
                                >
                                  No comments to show
                                </VuiTypography>
                              </Grid>
                            </Grid>
                          )}
                        </VuiBox>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Card sx={{ overflow: "visible" }}>
                    <Grid item container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <VuiBox
                          mb={2.5}
                          lineHeight={0}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <VuiTypography
                            variant="h5"
                            color="white"
                            fontWeight="bold"
                          >
                            {t("modules.projects.section_titles.360_photo")}
                          </VuiTypography>
                        </VuiBox>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <VuiBox>
                          {threeSixtyPhotos ? (
                            <>
                              {threeSixtyPhotos.map(
                                (
                                  threeSixtyPhoto: Project360Photo,
                                  i: number,
                                ) => {
                                  return (
                                    <Grid
                                      container
                                      flexDirection="column"
                                      spacing={2}
                                    >
                                      <Project360Image
                                        photos={threeSixtyPhoto}
                                      />
                                    </Grid>
                                  );
                                },
                              )}
                            </>
                          ) : (
                            <Grid
                              container
                              justifyContent="column"
                              alignItems="center"
                            >
                              <Grid item xs={4}>
                                <VuiTypography
                                  variant="h7"
                                  justfiyContent="center"
                                  alignItems="center"
                                >
                                  No 360 photos to display
                                </VuiTypography>
                              </Grid>
                            </Grid>
                          )}
                        </VuiBox>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </VuiBox>
        )}
      </Grid>
    </Grid>
  );
};

export default ProjectEdit;

import React from "react";

import Card from "@mui/material/Card";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiFormField from "@/Components/VuiFormField";
import VuiSelect from "@/Components/VuiSelect";

import { Material, ModelBrand } from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

const MaterialInfo = ({
  material,
  setMaterialState,
  modelBrandList,
}: {
  material?: Material;
  setMaterialState: (x: Material) => void;
  modelBrandList: ModelBrand[];
}) => {
  const { t } = useTranslation();

  const handleSetName = (e) => {
    const { Name, ...rest } = material;
    setMaterialState({ Name: e.target.value, ...rest });
  };

  const handleSetCost = (e) => {
    const { Cost, ...rest } = material;
    setMaterialState({ Cost: e.target.value, ...rest });
  };

  const handleSetPurchaseURL = (e) => {
    const { PurchaseURL, ...rest } = material;
    setMaterialState({ PurchaseURL: e.target.value, ...rest });
  };

  const handleSetBrand = (e) => {
    const { Brand, ...rest } = material;
    setMaterialState({ Brand: e.value, ...rest });
  };

  const brandOptions = modelBrandList.map((modelBrand) => ({
    value: modelBrand.id,
    label: modelBrand.Name,
  }));

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox>
        <VuiTypography mb={1.5} variant="h5" color="white">
          Material Information
        </VuiTypography>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.materials.data.name")}
            value={material?.Name}
            onChange={handleSetName}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.materials.data.cost")}
            value={material?.Cost}
            onChange={handleSetCost}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.materials.data.brand")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              material?.Brand
                ? brandOptions.find(
                    (brand) => brand.value === material?.Brand,
                  ) || null
                : null
            }
            onChange={handleSetBrand}
            options={brandOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.materials.data.purchase_link")}
            value={material?.PurchaseURL}
            onChange={handleSetPurchaseURL}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default MaterialInfo;

import { initializeApp } from "firebase/app";
import { mainApp } from "../App";
import {
  User,
  UserCredential,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  connectAuthEmulator,
} from "firebase/auth";
import {
  getDatabase,
  ref,
  child,
  get,
  equalTo,
  onChildChanged,
  orderByChild,
  query,
  update,
  remove,
  limitToFirst,
  onValue,
  DatabaseReference,
  orderByKey,
  connectDatabaseEmulator,
  push,
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  Furniture,
  Light,
  Material,
  Project,
  Company,
  Catalog,
  User as DbUser,
  UserRole,
  ProjectComments,
  JoinedUserRole,
  MaterialTypesType,
  ModelBrand,
  ElectricalSwitch,
} from "./DataTypes";
import { StorageError } from "firebase/storage";
import { StorageErrorCode } from "firebase/storage";

import moment from "moment";
import { getDefaultCatalog, getDefaultUser } from "./DataDefaultValues";
import _ from "lodash";
import { createAuthAndUser, deleteAuthAndUser } from "./FirebaseCloudFunctions";

import {
  CreateAuthAndUserReqType,
  CreateAuthAndUserResType,
  DeleteAuthAndUserReqType,
  DeleteAuthAndUserResType,
  GetDataResType,
} from "./ApiTypes";

const firebaseConfig = {
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
  appId: process.env.REACT_APP_APPID,
  projectId: process.env.REACT_APP_PROJECTID,
  apiKey: process.env.REACT_APP_APIKEY,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getDatabase(app);
const functions = getFunctions(app);

if (process.env.REACT_APP_ENV === "test") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectDatabaseEmulator(db, "127.0.0.1", 9000);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const dbRef = ref(getDatabase(app));
var signingIn = false;
var signingOut = false;
var signingUp = false;
export var firebaseSingleton: firebaseClass;

export class firebaseClass {
  public deviceList: Map<string, object>;
  public loginTime: number;
  public credentials: UserCredential;

  constructor() {
    if (firebaseSingleton == null) {
      this.deviceList = new Map();
      firebaseSingleton = this;
    } else {
      return firebaseSingleton;
    }
  }

  setCurrentPersistence(newPersistence) {
    console.log("Persistence is now " + newPersistence);
    return setPersistence(auth, newPersistence);
  }

  async signInFromRemembered(persistence): Promise<void> {
    return new Promise((res, err) => {
      setPersistence(auth, persistence).then(() => {
        console.log("Finding session " + persistence.name);
        console.log(auth);
        if (auth.currentUser != null) {
          console.log("Remembered");
          console.log(auth.currentUser);
          if (mainApp != null) {
            // mainApp.setWindow("ProjectManagement");
          }
        }
        res();
      });
    });
  }

  async signIn(userName: string, password: string) {
    if (!signingIn) {
      signingIn = true;

      return await this.signInAsync(userName, password)
        .then((credentials) => {
          console.log("Signed In");

          return credentials.user;
        })
        .catch(() => {
          console.log("Sign In Error");
        });
    } else {
      console.log("ALREADY SIGNING IN");

      return this.credentials.user;
    }
  }

  signInAsync(userName: string, password: string) {
    //In the future, this should be changed to an input user name and password.
    var returnPromise = signInWithEmailAndPassword(auth, userName, password)
      .then((credentials) => {
        credentials.user.getIdToken(true);
        signingIn = false;
        this.authTimeoutCheck(credentials);

        return credentials;
      })
      .catch((err) => {
        signingIn = false;

        return err;
      });
    return returnPromise;
  }

  async signOut() {
    if (!signingOut) {
      signingOut = true;

      await Promise.resolve(this.signOutAsync())
        .then(() => {
          console.log("Signed out");
          signingOut = false;
          if (mainApp != null) {
            // mainApp.setWindow("SignIn");
          }
        })
        .catch(() => {
          console.log("Error while signing out: ");
          signingOut = false;
        });
    } else {
      console.log("ALREADY SIGNING OUT");
    }
  }

  signOutAsync() {
    var returnPromise = signOut(auth)
      .then(() => {
        console.log("Signed out");
        signingOut = false;
      })
      .catch((err) => {
        console.log("Could not sign out " + err);
        signingOut = false;
      });
    return returnPromise;
  }

  async resetPassword(email) {
    return await fetchSignInMethodsForEmail(auth, email)
      .then((methods) => {
        if (methods.length > 0) {
          return sendPasswordResetEmail(auth, email, {
            url: `https://${process.env.REACT_APP_AUTHDOMAIN}`,
          })
            .then((res) => {
              return { status: true };
            })
            .catch((err) => {
              return { status: false };
            });
        } else {
          return { status: false };
        }
      })
      .catch(() => {
        return { status: false };
      });
  }

  async signUp({
    email,
    pass,
    displayName,
  }: {
    email: string;
    pass: string;
    displayName: string;
  }) {
    let userCredential: UserCredential;

    return await createUserWithEmailAndPassword(auth, email, pass)
      .then(async (newCredentials) => {
        console.log("Signed up");
        userCredential = newCredentials;

        const newUser: DbUser = {
          ...getDefaultUser(),
          id: this.getNewDocumentId("user"),
          UserId: newCredentials.user.uid,
          Username: displayName,
          Email: newCredentials.user.email,
        };

        await this.addUser(newUser);

        return userCredential;
      })
      .catch((err) => {
        console.log("Error while signing up: ");

        return userCredential;
      });
  }

  async authTimeoutCheck(credentials: UserCredential) {
    this.loginTime = Date.now();
    this.credentials = credentials;
    auth.onIdTokenChanged((user: User) => {
      console.log("Time taken: " + (Date.now() - this.loginTime));
      console.log(
        //@ts-expect-error
        "Time End: " + (credentials._tokenResponse.expiresIn - 7 * 60) * 1000,
      );
      console.log(
        "Time left: " +
          //@ts-expect-error
          ((credentials._tokenResponse.expiresIn - 7 * 60) * 1000 -
            (Date.now() - this.loginTime)),
      );
      if (
        Date.now() - this.loginTime >=
        //@ts-expect-error
        (credentials._tokenResponse.expiresIn - 7 * 60) * 1000
      ) {
        console.log("refreshing login");
        this.loginTime = Date.now();

        //@ts-expect-error
        user.accessToken = user.getIdToken(true);
      }
    });
  }
  // refreshAuth(token){
  //     window.setTimeout(()=>{Sign}, token.expiresIn);
  // }

  async getDevices() {
    //let devicesRef = ref(getDatabase(app), `flamelink/environments/production/content/rtlcDevice/en-US/`)

    let returnPromise = new Promise((res, rej) => {
      get(
        child(
          dbRef,
          `flamelink/environments/production/content/rtlcDevice/en-US/`,
        ),
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            //if something was returned, set values.
            //Firstly have to convert whatever it gives to an array.
            this.deviceList = new Map();
            snapshot.forEach((eachValue) => {
              this.deviceList.set(eachValue.val().id, eachValue.val());
            });
            //then set the result to the value
            res(this.deviceList);
          } else {
            //no data retrieved, reject.
            console.log("No data available");
            rej([]);
          }
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error(error);
          rej([]);
        });
    });
    return returnPromise;
  }

  getCurrentUserToken(): Promise<string> {
    return auth.currentUser.getIdToken();
  }

  // Technically you get UIDs when you're pushing data, but if we're also making reference to
  // UIDs in the data itself, we should generate them beforehand. Firebase UIDs are typically based
  // on current DateTime + 72 random characters to avoid collisions. If you want to use that method
  // exactly, there's a post here describing how they do it.
  // https://firebase.blog/posts/2015/02/the-2120-ways-to-ensure-unique_68
  generateNewId() {
    const timeSinceEpoch = new Date().getTime();
    const randomValue = Math.random() * 100000;
    return (timeSinceEpoch + "" + Math.floor(randomValue)).toString();
  }

  getNewDocumentId(collectionName: string) {
    const dbRef = this.getObjectRef(collectionName);

    const newChildRef = push(dbRef);

    return newChildRef.key;
  }

  getUser({
    uid,
    id,
  }: {
    uid?: DbUser["UserId"];
    id?: DbUser["id"];
  }): Promise<GetDataResType<DbUser>> {
    if (!uid && !id)
      return Promise.resolve({ status: false, message: "User Not Found!" });

    if (uid) {
      return this.getDatabaseObjectByField("user", "UserId", uid)
        .then((data: DbUser): GetDataResType<DbUser> => {
          if (data) {
            return { status: true, data };
          } else {
            return { status: false, message: "User Not Found!" };
          }
        })
        .catch((): GetDataResType<DbUser> => {
          return { status: false, message: "User Not Found!" };
        });
    } else {
      return this.getDatabaseObject("user", id)
        .then((data: DbUser): GetDataResType<DbUser> => {
          return { status: true, data };
        })
        .catch((): GetDataResType<DbUser> => {
          return { status: false, message: "User Not Found!" };
        });
    }
  }

  async addUser(user: DbUser): Promise<void> {
    const id = user.id || this.getNewDocumentId("user");

    const newUser: DbUser = {
      ...user,
      id,
    };

    return await this.updateData(newUser, "user", id);
  }

  updateUser(user: DbUser): Promise<void> {
    return this.updateData(user, "user", user.id);
  }

  async createAuthAndUser({
    user,
    password,
  }: CreateAuthAndUserReqType): Promise<CreateAuthAndUserResType> {
    const newUser: DbUser = {
      ...user,
      id: user.id || this.getNewDocumentId("user"),
    };

    return await createAuthAndUser({ user: newUser, password });

    // return await httpsCallable(
    //   functions,
    //   "createUser",
    // )({
    //   userState: newUser,
    //   password: password,
    // });
  }

  async deleteAuthAndUser({
    id,
  }: DeleteAuthAndUserReqType): Promise<DeleteAuthAndUserResType> {
    if (id == null) {
      return;
    }

    return await deleteAuthAndUser({ id });
  }

  async getJoinedUserRolesByUserId(
    id: DbUser["id"],
  ): Promise<JoinedUserRole[]> {
    const allUserRoles = await this.getUserRolesByUserId(id);
    const allJoinedUserRoles = [];
    const promises: Promise<any>[] = [];
    let user;

    allUserRoles.forEach((userRole, userRoleIndex) => {
      const userRef = this.getObjectRef("user", userRole.AssociatedUser);
      const companyRef = this.getObjectRef(
        "company",
        userRole.AssociatedCompany,
      );

      allJoinedUserRoles[userRoleIndex] = userRole;

      if (user) {
        allJoinedUserRoles[userRoleIndex].AssociatedUser = user;
      } else {
        promises.push(
          get(userRef).then((userSnap) => {
            allJoinedUserRoles[userRoleIndex].AssociatedUser = userSnap.val();
            user = userSnap.val();
          }),
        );
      }

      promises.push(
        get(companyRef).then((companySnap) => {
          allJoinedUserRoles[userRoleIndex].AssociatedCompany =
            companySnap.val();
        }),
      );
    });

    return Promise.all(promises).then(() => {
      return allJoinedUserRoles;
    });
  }

  async getUserRolesByUserId(id: DbUser["id"]): Promise<UserRole[]> {
    const userRoleRef = query(
      this.getObjectRef("userRole"),
      orderByChild("AssociatedUser"),
      equalTo(id),
    );

    return await get(userRoleRef)
      .then((userRoleSnap) => {
        const userRoles = [];

        userRoleSnap.forEach((snap) => {
          userRoles.push(snap.val());
        });

        return userRoles;
      })
      .catch((err) => {
        console.error(err);
        throw new Error(err);
      });
  }

  async getJoinedUserRole(id: JoinedUserRole["id"]) {
    return await this.getJoinedUserRoleList(id)
      .then((data: JoinedUserRole[]): GetDataResType<JoinedUserRole> => {
        return { status: true, data: data[0] };
      })
      .catch((): GetDataResType<JoinedUserRole> => {
        return { status: false, message: "User Role Not Found!" };
      });
  }

  async getJoinedUserRoleList(id?: UserRole["id"]): Promise<JoinedUserRole[]> {
    const userRoleRef = id
      ? query(
          this.getObjectRef("userRole"),
          orderByKey(),
          equalTo(id),
          limitToFirst(1),
        )
      : this.getObjectRef("userRole");

    const joinedUserRoleList: JoinedUserRole[] = [];
    const promises = [];

    await get(userRoleRef).then((userRoleSnap) => {
      const userRoleList: UserRole[] = Object.values(userRoleSnap.val());

      for (const [userRoleIndex, userRole] of userRoleList.entries()) {
        const joinedUserRole: JoinedUserRole = _.cloneDeep(
          userRole,
        ) as unknown as JoinedUserRole;

        joinedUserRoleList.push(joinedUserRole as JoinedUserRole);

        const userRef = this.getObjectRef("user", userRole.AssociatedUser);
        const companyRef = this.getObjectRef(
          "company",
          userRole.AssociatedCompany,
        );

        promises.push(
          get(userRef).then((userSnap) => {
            joinedUserRoleList[userRoleIndex].AssociatedUser = userSnap.val();
          }),
        );
        promises.push(
          get(companyRef).then((companySnap) => {
            joinedUserRoleList[userRoleIndex].AssociatedCompany =
              companySnap.val();
          }),
        );
      }
    });

    return Promise.all(promises).then(() => {
      return joinedUserRoleList;
    });
  }

  addUserRole(userRole: UserRole, id?: string): Promise<void> {
    const newId = id ?? this.getNewDocumentId("userRole");

    const newUserRole: UserRole = {
      ...userRole,
      id: newId,
    };

    return this.updateData(newUserRole, "userRole", newId);
  }

  updateUserRole(userRole: UserRole): Promise<void> {
    return this.updateData(userRole, "userRole", userRole.id);
  }

  deleteUserRole(id: UserRole["id"]): Promise<unknown[]> {
    if (id == null) {
      return;
    }
    const promiseList = [this.removeData("userRole", id)];

    return Promise.all(promiseList);
  }

  getElectricalSwitch(id: string): Promise<GetDataResType<ElectricalSwitch>> {
    if (!id) return;

    return this.getDatabaseObject("electricalSwitch", id)
      .then((data: ElectricalSwitch): GetDataResType<ElectricalSwitch> => {
        return { status: true, data };
      })
      .catch((): GetDataResType<ElectricalSwitch> => {
        return { status: false, message: "" };
      });
  }

  addElectricalSwitch(
    electricalSwitch: ElectricalSwitch,
    id?: ElectricalSwitch["id"],
    catalogId?: Catalog["id"],
  ): Promise<void> {
    const newId = id ?? this.getNewDocumentId("electricalSwitch");

    const newElectricalSwitch: ElectricalSwitch = {
      ...electricalSwitch,
      id: newId,
    };

    return this.updateData(newElectricalSwitch, "electricalSwitch", newId).then(
      async () => {
        if (catalogId) {
          await this.getCatalog(catalogId).then(async (res) => {
            if (res.status) {
              const catalog = res.data;

              const dataToUpate = {
                id: catalog.id,
                ElectricalSwitches: [...catalog.ElectricalSwitches, newId],
              } as Catalog;

              await this.updateCatalog(dataToUpate);
            }
          });
        }
      },
    );
  }

  updateElectricalSwitch(electricalSwitch: ElectricalSwitch): Promise<void> {
    return this.updateData(
      electricalSwitch,
      "electricalSwitch",
      electricalSwitch.id,
    );
  }

  deleteElectricalSwitch(
    id: ElectricalSwitch["id"],
    catalogId?: Catalog["id"],
  ): Promise<unknown[]> {
    if (id == null) {
      return;
    }
    const promiseList = [
      this.getDatabaseObject("electricalSwitch", id).then(
        (obj: ElectricalSwitch) => {
          if (obj["ModelURL"]) {
            const fileName = this.extractObjectName(
              "FurnitureModels",
              obj["ModelURL"],
            );
            this.deleteMedia("FurnitureModels", fileName);
          }
          if (obj["ThumbnailURL"]) {
            const fileName = this.extractObjectName(
              "FurnitureImage",
              obj["ThumbnailURL"],
            );
            this.deleteMedia("FurnitureImage", fileName);
          }
        },
      ),
      this.removeData("electricalSwitch", id).then(async () => {
        if (catalogId) {
          await this.getCatalog(catalogId).then(async (res) => {
            if (res.status) {
              const catalog = res.data;

              const dataToUpate = {
                id: catalog.id,
                ElectricalSwitches: catalog.ElectricalSwitches.filter(
                  (i) => i !== id,
                ),
              } as Catalog;

              await this.updateCatalog(dataToUpate);
            }
          });
        }
      }),
    ];

    return Promise.all(promiseList);
  }

  getFurniture(id: string): Promise<GetDataResType<Furniture>> {
    if (!id) return;

    return this.getDatabaseObject("furniture", id)
      .then((data: Furniture): GetDataResType<Furniture> => {
        return { status: true, data };
      })
      .catch((): GetDataResType<Furniture> => {
        return { status: false, message: "" };
      });
  }

  addFurniture(
    furniture: Furniture,
    id?: Furniture["id"],
    catalogId?: Catalog["id"],
  ): Promise<void> {
    const newId = id ?? this.getNewDocumentId("furniture");

    const newFurniture: Furniture = {
      ...furniture,
      id: newId,
    };

    return this.updateData(newFurniture, "furniture", newId).then(async () => {
      if (catalogId) {
        await this.getCatalog(catalogId).then(async (res) => {
          if (res.status) {
            const catalog = res.data;

            const dataToUpate = {
              id: catalog.id,
              Furnitures: [...catalog.Furnitures, newId],
            } as Catalog;

            await this.updateCatalog(dataToUpate);
          }
        });
      }
    });
  }

  updateFurniture(furniture: Furniture): Promise<void> {
    return this.updateData(furniture, "furniture", furniture.id);
  }

  deleteFurniture(
    id: Furniture["id"],
    catalogId?: Catalog["id"],
  ): Promise<unknown[]> {
    if (id == null) {
      return;
    }
    const promiseList = [
      this.getDatabaseObject("furniture", id).then((obj: Furniture) => {
        if (obj["ModelURL"]) {
          const fileName = this.extractObjectName(
            "FurnitureModels",
            obj["ModelURL"],
          );
          this.deleteMedia("FurnitureModels", fileName);
        }
        if (obj["ThumbnailURL"]) {
          const fileName = this.extractObjectName(
            "FurnitureImage",
            obj["ThumbnailURL"],
          );
          this.deleteMedia("FurnitureImage", fileName);
        }
      }),
      this.removeData("furniture", id).then(async () => {
        if (catalogId) {
          await this.getCatalog(catalogId).then(async (res) => {
            if (res.status) {
              const catalog = res.data;

              const dataToUpate = {
                id: catalog.id,
                Furnitures: catalog.Furnitures.filter((i) => i !== id),
              } as Catalog;

              await this.updateCatalog(dataToUpate);
            }
          });
        }
      }),
    ];

    return Promise.all(promiseList);
  }

  extractObjectName(location: string, fullUrl: string) {
    const locationIndex = fullUrl.indexOf(location);
    return fullUrl.substring(locationIndex).split("%2F").pop().split("?")[0];
  }

  getLight(id: string): Promise<GetDataResType<Light>> {
    if (!id) return;

    return this.getDatabaseObject("light", id)
      .then((data: Light): GetDataResType<Light> => {
        return { status: true, data };
      })
      .catch((): GetDataResType<Light> => {
        return { status: false, data: {}, message: "" };
      });
  }

  addLight(
    light: Light,
    id?: Light["id"],
    catalogId?: Catalog["id"],
  ): Promise<void> {
    const newId = id ?? this.getNewDocumentId("light");

    const newLight: Light = {
      ...light,
      id: newId,
    };

    return this.updateData(newLight, "light", newId).then(async () => {
      if (catalogId) {
        await this.getCatalog(catalogId).then(async (res) => {
          if (res.status) {
            const catalog = res.data;

            const dataToUpate = {
              id: catalog.id,
              Lights: [...catalog.Lights, newId],
            } as Catalog;

            await this.updateCatalog(dataToUpate);
          }
        });
      }
    });
  }

  updateLight(light: Light): Promise<void> {
    return this.updateData(light, "light", light.id);
  }

  deleteLight(id: string, catalogId?: Catalog["id"]): Promise<unknown[]> {
    if (id == null) {
      return;
    }
    const promiseList = [
      this.getDatabaseObject("light", id).then((obj: Furniture) => {
        if (obj["ModelURL"]) {
          const fileName = this.extractObjectName(
            "FurnitureModels",
            obj["ModelURL"],
          );
          this.deleteMedia("FurnitureModels", fileName);
        }
        if (obj["ThumbnailURL"]) {
          const fileName = this.extractObjectName(
            "FurnitureImage",
            obj["ThumbnailURL"],
          );
          this.deleteMedia("FurnitureImage", fileName);
        }
      }),
      this.removeData("light", id).then(async () => {
        if (catalogId) {
          await this.getCatalog(catalogId).then(async (res) => {
            if (res.status) {
              const catalog = res.data;

              const dataToUpate = {
                id: catalog.id,
                Lights: catalog.Lights.filter((i) => i !== id),
              } as Catalog;

              await this.updateCatalog(dataToUpate);
            }
          });
        }
      }),
    ];

    return Promise.all(promiseList);
  }

  addCatalog(catalog: Catalog, id?: Catalog["id"]): Promise<void> {
    const newId = id ?? this.getNewDocumentId("catalog");

    const newCatalog: Catalog = {
      ...catalog,
      id: newId,
    };

    return this.updateData(newCatalog, "catalog", newId);
  }

  getCatalog(id: string): Promise<GetDataResType<Catalog>> {
    if (!id) return;

    return this.getDatabaseObject("catalog", id)
      .then((catalog: Catalog): GetDataResType<Catalog> => {
        return { status: true, data: { ...getDefaultCatalog(), ...catalog } };
      })
      .catch((): GetDataResType<Catalog> => {
        return { status: false, data: {}, message: "" };
      });
  }

  updateCatalog(catalog: Catalog): Promise<void> {
    return this.updateData(catalog, "catalog", catalog.id);
  }

  async deleteCatalog(id: Catalog["id"]): Promise<void> {
    if (id == null) {
      return;
    }

    const promises = [];

    await this.getCatalog(id).then((res) => {
      if (res.status) {
        const {
          Furnitures: furnitureIds,
          Lights: lightIds,
          WallMaterials,
          FloorMaterials,
          ConstructionMaterials,
          OtherMaterials,
        }: Catalog = res.data;

        const materialIds = [
          ...WallMaterials,
          ...FloorMaterials,
          ...ConstructionMaterials,
          ...OtherMaterials,
        ];

        furnitureIds.forEach((id) => {
          promises.push(this.deleteFurniture(id));
        });

        lightIds.forEach((id) => {
          promises.push(this.deleteLight(id));
        });

        materialIds.forEach((id) => {
          promises.push(this.deleteMaterial(id));
        });
      }
    });

    promises.push(
      this.removeData("catalog", id).then(async () => {
        await firebaseSingleton
          .getCompaniesByCatalogId(id)
          .then(async (result) => {
            if (result.status) {
              const companies = result.data;

              companies.forEach(async (company) => {
                const newCompany = _.cloneDeep(company);

                newCompany.AccessibleCatalogues =
                  company.AccessibleCatalogues.filter(
                    (catalogId) => catalogId !== id,
                  );

                return await firebaseSingleton.updateCompany(newCompany);
              });
            }
          })
          .catch((err) => console.error(err));
      }),
    );

    return Promise.all(promises)
      .then(() => {
        return;
      })
      .catch((err) => {
        console.error(err);

        return;
      });
  }

  addMaterial(
    material: Material,
    id?: Material["id"],
    type?: MaterialTypesType,
    catalogId?: Catalog["id"],
  ): Promise<void> {
    const newId = id ?? this.getNewDocumentId("material");

    const newMaterial: Material = {
      ...material,
      id: newId,
    };

    return this.updateData(newMaterial, "material", newId).then(async () => {
      if (catalogId && type) {
        await this.getCatalog(catalogId).then(async (res) => {
          if (res.status) {
            const catalog = res.data;

            const dataToUpate = {
              id: catalog.id,
              [`${type}Materials`]: [...catalog[`${type}Materials`], newId],
            } as Catalog;

            await this.updateCatalog(dataToUpate);
          }
        });
      }
    });
  }

  updateMaterial(material: Material): Promise<void> {
    return this.updateData(material, "material", material.id);
  }

  getMaterial(id: string): Promise<GetDataResType<Material>> {
    if (!id) return;

    return this.getDatabaseObject("material", id)
      .then((material: Material): GetDataResType<Material> => {
        return { status: true, data: material };
      })
      .catch((): GetDataResType<Material> => {
        return { status: false, data: {}, message: "" };
      });
  }

  deleteMaterial(
    id: Material["id"],
    type?: MaterialTypesType,
    catalogId?: Catalog["id"],
  ): Promise<unknown[]> {
    if (id == null) {
      return;
    }
    const promiseList = [
      this.getDatabaseObject("material", id).then((obj: Furniture) => {
        if (obj["TextureURL"]) {
          const fileName = this.extractObjectName(
            "MaterialTextures",
            obj["TextureURL"],
          );
          this.deleteMedia("MaterialTextures", fileName);
        }

        if (obj["NormalURL"]) {
          const fileName = this.extractObjectName(
            "MaterialTextures",
            obj["NormalURL"],
          );
          this.deleteMedia("MaterialTextures", fileName);
        }
      }),
      this.removeData("material", id).then(async () => {
        if (catalogId && type) {
          await this.getCatalog(catalogId).then(async (res) => {
            if (res.status) {
              const catalog = res.data;

              const dataToUpate = {
                id: catalog.id,
                [`${type}Materials`]: catalog[`${type}Materials`].filter(
                  (i) => i !== id,
                ),
              } as Catalog;

              await this.updateCatalog(dataToUpate);
            }
          });
        }
      }),
    ];

    return Promise.all(promiseList);
  }

  getModelBrand(id: string): Promise<GetDataResType<ModelBrand>> {
    if (!id) return;

    return this.getDatabaseObject("modelBrand", id)
      .then((modelBrand: ModelBrand): GetDataResType<ModelBrand> => {
        return { status: true, data: modelBrand };
      })
      .catch((): GetDataResType<ModelBrand> => {
        return { status: false, data: {}, message: "" };
      });
  }

  addModelBrand(modelBrand: ModelBrand, id?: ModelBrand["id"]): Promise<void> {
    const newId = id ?? this.getNewDocumentId("modelBrand");

    const newModelBrand: ModelBrand = {
      ...modelBrand,
      id: newId,
    };

    return this.updateData(newModelBrand, "modelBrand", newId);
  }

  updateModelBrand(modelBrand: ModelBrand): Promise<void> {
    return this.updateData(modelBrand, "modelBrand", modelBrand.id);
  }

  async deleteModelBrand(id: ModelBrand["id"]): Promise<void> {
    if (id == null) {
      return;
    }

    return this.removeData("modelBrand", id);
  }

  // getProjectComments(id: Project["id"]): Promise<GetDataResType<Project[]>> {
  //   if (!id) return;

  //   const ref = this.getDatabaseObject("project", id)
  //   console.log(ref)
  //   const comments = []

  //   // return get(ref)
  //   //   .then((snapshot): GetDataResType<Project[]> => {
  //   //     if (snapshot.exists()) {
  //   //       snapshot.forEach((childSnap) => {
  //   //         const comment = childSnap.val();

  //   //         if ((comment.Comments || []).includes(id)) {
  //   //           comments.push(comment);
  //   //         }
  //   //       });
  //   //     }
  //   //     return { status: true, data: comments };
  //   //   })
  //   //   .catch((err): GetDataResType<Project[]> => {
  //   //     return { status: false, message: err };
  //   //   });
  // }

  getCompaniesByCatalogId(
    id: Catalog["id"],
  ): Promise<GetDataResType<Company[]>> {
    if (!id) return;

    const ref = query(this.getObjectRef("company"));

    // return { status: true, data: data[0] };

    const companies = [];

    return get(ref)
      .then((snapshot): GetDataResType<Company[]> => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnap) => {
            const company = childSnap.val();

            if ((company.AccessibleCatalogues || []).includes(id)) {
              companies.push(company);
            }
          });
        }

        return { status: true, data: companies };
      })
      .catch((err): GetDataResType<Company[]> => {
        return { status: false, message: err };
      });
  }

  getCompaniesByProjectId(
    id: Project["id"],
  ): Promise<GetDataResType<Company[]>> {
    if (!id) return;

    const ref = query(this.getObjectRef("company"));

    const companies = [];

    return get(ref)
      .then((snapshot): GetDataResType<Company[]> => {
        if (snapshot.exists()) {
          snapshot.forEach((childSnap) => {
            const company = childSnap.val();

            if ((company.Projects || []).includes(id)) {
              companies.push(company);
            }
          });
        }

        return { status: true, data: companies };
      })
      .catch((err): GetDataResType<Company[]> => {
        return { status: false, message: err };
      });
  }

  getCompany(id: string): Promise<GetDataResType<Company>> {
    if (!id) return;

    return this.getDatabaseObject("company", id)
      .then((company: Company): GetDataResType<Company> => {
        return { status: true, data: company };
      })
      .catch((): GetDataResType<Company> => {
        return { status: false, data: {}, message: "" };
      });
  }

  addCompany(company: Company, id?: string): Promise<void> {
    const newId = id ?? this.getNewDocumentId("company");

    const newCompany: Company = {
      ...company,
      id: newId,
    };

    return this.updateData(newCompany, "company", newId);
  }

  updateCompany(company: Company): Promise<void> {
    return this.updateData(company, "company", company.id);
  }

  async deleteCompany(id: string): Promise<void> {
    if (id == null) {
      return;
    }
    return this.removeData("company", id);
  }

  getProject(id: string): Promise<GetDataResType<Project>> {
    if (!id) return;

    return this.getDatabaseObject("project", id)
      .then((data: Project): GetDataResType<Project> => {
        return { status: true, data };
      })
      .catch((): GetDataResType<Project> => {
        return { status: false, data: {}, message: "" };
      });
  }

  addProject(project: Project, id?: string): Promise<void> {
    const newId = id ?? this.getNewDocumentId("project");

    const newProject: Project = {
      ...project,
      LastModified: moment().utc().format(),
      id: newId,
    };

    return this.updateData(newProject, "project", newId);
  }

  updateProject(project: Project): Promise<void> {
    const newProject: Project = {
      ...project,
      LastModified: moment().utc().format(),
    };

    return this.updateData(newProject, "project", newProject.id);
  }

  async deleteProject(id: string): Promise<unknown[]> {
    if (id == null) {
      return;
    }
    const promiseList = [
      this.getDatabaseObject("project", id).then((obj: Project) => {
        if (obj.RealWorldImageUrl) {
          const fileName = this.extractObjectName(
            "ProjectImages",
            obj.RealWorldImageUrl,
          );

          this.deleteMedia("ProjectImages", fileName);
        }

        if (obj["3DModelPreviewImageUrl"]) {
          const fileName = this.extractObjectName(
            "ProjectImages",
            obj["3DModelPreviewImageUrl"],
          );

          this.deleteMedia("ProjectImages", fileName);
        }

        if (obj.Current3DModelLocation) {
          const fileName = this.extractObjectName(
            "ProjectModels",
            obj.Current3DModelLocation,
          );

          this.deleteMedia("ProjectImages", fileName);
        }
      }),
      this.removeData("project", id).then(async () => {
        await firebaseSingleton
          .getCompaniesByProjectId(id)
          .then(async (result) => {
            if (result.status) {
              const companies = result.data;

              companies.forEach(async (company) => {
                const newCompany = _.cloneDeep(company);

                newCompany.Projects = company.Projects.filter(
                  (projectId) => projectId !== id,
                );
                return await firebaseSingleton.updateCompany(newCompany);
              });
            }
          })
          .catch((err) => console.error(err));
      }),
    ];

    return Promise.all(promiseList);
  }

  async updateProjectName(id: string, Name: string): Promise<void> {
    const newData: { Name: string } = { Name: Name };
    return this.updateData(newData, "project", id);
  }

  async uploadMedia(location: string, name: string, file: File): Promise<void> {
    let objectRef = storageRef(
      getStorage(app),
      `root/` + location + `/` + name + `/`,
    );

    return new Promise((res, rej) => {
      uploadBytes(objectRef, file)
        .then(() => {
          res();
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error("Error in promise");
          console.error(error);
          rej();
        });
    });
  }

  async deleteMedia(location: string, name: string): Promise<void> {
    const objectRef = storageRef(
      getStorage(app),
      `root/` + location + `/` + name + `/`,
    );
    return new Promise<void>((res, rej) => {
      deleteObject(objectRef)
        .then(() => {
          res();
        })
        .catch((error: StorageError) => {
          if (error.code.includes(StorageErrorCode.OBJECT_NOT_FOUND)) {
            // The resource doesn't exist
            console.log("Object was already deleted, continuing: " + name);
            res();
          } else {
            rej();
          }
        });
    });
  }

  async getMediaUrl(location: string, name: string): Promise<string> {
    let objectRef = storageRef(
      getStorage(app),
      `root/` + location + `/` + name + `/`,
    );
    return getDownloadURL(objectRef);
  }

  private removeData(location: string, id: string): Promise<void> {
    let objectRef = ref(
      getDatabase(app),
      `flamelink/environments/production/content/` +
        location +
        `/en-US/` +
        id +
        `/`,
    );

    return new Promise((res, rej) => {
      remove(objectRef)
        .then(() => {
          res();
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error("Error in promise");
          console.error(error);
          rej();
        });
    });
  }

  updateData(jsonData: any, location: string, id: string): Promise<void> {
    let objectRef = ref(
      getDatabase(app),
      `flamelink/environments/production/content/` +
        location +
        `/en-US/` +
        id +
        `/`,
    );

    return new Promise((res, rej) => {
      update(objectRef, jsonData)
        .then(() => {
          res();
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error("Error in promise");
          console.error(error);
          rej();
        });
    });
  }

  getObjectRef(collectionName: string, key?: string): DatabaseReference {
    return ref(
      getDatabase(app),
      `flamelink/environments/production/content/${collectionName}/en-US/${
        key || ""
      }`,
    );
  }

  async getDatabaseList(location: string): Promise<Map<string, any>> {
    let objectRef = ref(
      getDatabase(app),
      `flamelink/environments/production/content/` + location + `/en-US/`,
    );

    let returnPromise = new Promise((res, rej) => {
      get(objectRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            //if something was returned, set values.
            //Firstly have to convert whatever it gives to an array.
            let returnVal = new Map();
            snapshot.forEach((eachValue) => {
              returnVal.set(eachValue.val().id, eachValue.val());
            });
            //then set the result to the value
            res(returnVal);
          } else {
            //no data retrieved, reject.
            console.log("No data available");
            rej([]);
          }
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error("Error in promise");
          console.error(error);
          rej([]);
        });
    });

    return returnPromise as Promise<Map<string, any>>;
  }

  async getDatabaseObjectByField(location: string, field: string, value: any) {
    const objectRef = query(
      ref(
        getDatabase(app),
        `flamelink/environments/production/content/` + location + `/en-US/`,
      ),
      orderByChild(field),
      equalTo(value),
      limitToFirst(1),
    );

    return await get(objectRef).then((snapshots) => {
      if (snapshots.exists()) {
        return Object.values(snapshots.val())[0];
      } else {
        return null;
      }
    });
  }

  async getDatabaseObject(location, id) {
    let objectRef = ref(
      getDatabase(app),
      `flamelink/environments/production/content/` +
        location +
        `/en-US/` +
        id +
        `/`,
    );

    let returnPromise = new Promise((res, rej) => {
      get(objectRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            res(snapshot.val());
          } else {
            //no data retrieved, reject.
            console.log("No data available");
            console.log(
              `flamelink/environments/production/content/` +
                location +
                `/en-US/` +
                id +
                `/`,
            );
            rej([]);
          }
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error("Error in promise");
          console.error(error);
          rej([]);
        });
    });
    return returnPromise;
  }

  listenDevices(funcToRun) {
    let devicesRef = ref(
      getDatabase(app),
      `flamelink/environments/production/content/rtlcDevice/en-US/`,
    );

    return onChildChanged(devicesRef, (snapshot) => {
      if (snapshot.exists()) {
        {
          this.deviceList.set(snapshot.val().id, snapshot.val());
        }

        //then set the result to the value
        if (funcToRun != null) funcToRun(this.deviceList);
      } else {
        //no data retrieved, reject.
        console.log("No data available");
        if (funcToRun != null) funcToRun(this.deviceList);
      }
    });
  }

  listenUser(
    uid: string,
    callback: (user: DbUser | null) => void,
    createIfNotExist: boolean = false,
  ): void {
    const location = "user";

    const objectRef = query(
      ref(
        getDatabase(app),
        `flamelink/environments/production/content/${location}/en-US/`,
      ),
      orderByChild("UserId"),
      equalTo(uid),
      limitToFirst(1),
    );

    onValue(
      objectRef,
      (snapshot) => {
        let user: DbUser | null = null;

        if (snapshot.exists()) {
          user = Object.values(snapshot.val())[0] as DbUser;
        } else if (createIfNotExist) {
          const authUser = auth.currentUser;

          user = {
            ...getDefaultUser(),
            id: this.getNewDocumentId("user"),
            UserId: authUser.uid,
            Username: authUser.displayName || "",
            Email: authUser.email,
          };

          this.addUser(user);
        }

        callback(user);
      },
      (err) => {},
    );
  }

  async getInterval(id) {
    let returnPromise = new Promise((res, rej) => {
      //get object
      get(
        child(
          dbRef,
          `flamelink/environments/production/content/interval/en-US/` +
            id +
            "/",
        ),
      )
        .then((snapshot) => {
          if (snapshot.exists()) {
            //if something was returned, set values.
            res(snapshot.val());
          } else {
            //no data retrieved, reject.
            console.log("No data available");
            rej([]);
          }
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error(error);
          rej([]);
        });
    });
    return returnPromise;
  }

  async getIntervals(device) {
    let returnObject = [];
    for (const interval of device.intervalSettings) {
      const getValue = await this.getInterval(interval);
      returnObject.push(getValue);
    }

    return returnObject;
  }
  async getImages(device) {
    var que = query(
      ref(db, "flamelink/environments/production/content/images/en-US/"),
      orderByChild("deviceId"),
      equalTo(device),
    );
    let returnObject = {};
    let returnPromise = new Promise((res, rej) => {
      get(que)
        .then((snapshot) => {
          if (snapshot.exists()) {
            //if something was returned, set values.
            returnObject = snapshot.val();
            res(returnObject);
          } else {
            //no data retrieved, reject.
            console.log("No data available for " + device);
            rej({});
          }
        })
        .catch((error) => {
          console.error(error);
          rej({});
        });
    });
    return returnPromise;
  }

  async getLastImage(device) {
    var que = query(
      ref(
        db,
        "flamelink/environments/production/content/images/en-US/" +
          device.latestImage,
      ),
    );
    let startTime = Date.now();
    let returnObject = null;
    let returnPromise = new Promise((res, rej) => {
      get(que)
        .then((snapshot) => {
          if (snapshot.exists()) {
            res(snapshot.val());
          } else {
            //no data retrieved, reject.
            console.log("No data available for " + device);
            rej({});
          }
        })
        .catch((error) => {
          console.error(error);
          rej({});
        });
    });
    return returnPromise;
    // var que = query(ref(db, "flamelink/environments/production/content/images/en-US/"), orderByChild("deviceId"), equalTo(device))
    // let returnObject = null
    // let returnTime = null
    // console.log("Getting images")
    // let returnPromise = new Promise((res, rej) => {
    //     get(que).then((snapshot) => {
    //         if (snapshot.exists()) {
    //             //if something was returned, set values.
    //             console.log(snapshot.val())

    //             //for each value, check if it is the latest. If it is, set the return value to be the new value.
    //             snapshot.forEach((value)=>{
    //                 let time = value.val().timestamp.split('.')[0].replaceAll("-", " ") //convert to how they want it
    //                 let convertedTime = Date.parse(time) //convert to datetime
    //                 if(returnObject == null|| returnTime < convertedTime){
    //                     returnObject = value.val()
    //                     returnTime = Date.parse(time)
    //                 }
    //             })
    //             res(returnObject)
    //         } else {
    //             //no data retrieved, reject.
    //             console.log("No data available for " + device);
    //             rej({})
    //         }
    //     }).catch((error) => {
    //         console.error(error);
    //         rej({})
    //     });
    // })
    // return returnPromise
  }

  async getReferenced(location, orderBy, equalToValue) {
    console.log(
      "Getting " + location + " where " + orderBy + " = " + equalToValue,
    );
    var que = query(
      ref(
        db,
        "flamelink/environments/production/content/" + location + "/en-US/",
      ),
      orderByChild(orderBy),
      equalTo(equalToValue),
    );
    let returnPromise = new Promise((res, rej) => {
      get(que)
        .then((snapshot) => {
          if (snapshot.exists()) {
            //if something was returned, set values.
            let returnVal = new Map();
            snapshot.forEach((eachValue) => {
              returnVal.set(eachValue.val().id, eachValue.val());
            });
            res(returnVal);
          } else {
            //no data retrieved, reject.
            console.log("No data available");
            rej({});
          }
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error(
            "Error getting " + location + " for " + equalTo + " : " + error,
          );
          rej({});
        });
    });
    return returnPromise;
  }

  async getReferencedArray(location, orderBy, equalToValue) {
    console.log(
      "Getting " + location + " where " + orderBy + " = " + equalToValue,
    );
    var que = query(
      ref(
        db,
        "flamelink/environments/production/content/" + location + "/en-US/",
      ),
      orderByChild(orderBy),
      equalTo(equalToValue),
    );
    let returnPromise = new Promise((res, rej) => {
      get(que)
        .then((snapshot) => {
          if (snapshot.exists()) {
            //if something was returned, set values.
            let returnVal = new Map();
            snapshot.forEach((eachValue) => {
              returnVal.set(eachValue.val().id, eachValue.val());
            });
            res(returnVal);
          } else {
            //no data retrieved, reject.
            console.log("No data available");
            rej({});
          }
        })
        .catch((error) => {
          //if there was an error, reject and print the error.
          console.error(
            "Error getting " + location + " for " + equalTo + " : " + error,
          );
          rej({});
        });
    });
    return returnPromise;
  }
}

/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { forwardRef } from "react";

// Custom styles for VuiButton
import VuiButtonRoot, { VUIButtonRootState } from "./VuiButtonRoot";

const VuiButton = forwardRef<any, VUIButtonRootState>(
  ({ color, variant, size, circular, iconOnly, children, ...rest }, ref) => (
    <VuiButtonRoot
      {...rest}
      ref={ref}
      color="white"
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly }}
    >
      {children}
    </VuiButtonRoot>
  ),
);

// Setting default values for the props of VuiButton
VuiButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

export default VuiButton;

/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { useEffect, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Vision UI Dashboard PRO React components
import VuiBox from "../VuiBox";

// Custom styles for the VuiDropzone
import VuiDropzoneRoot from "./VuiDropzoneRoot";

function VuiDropzone({
  options,
  file,
  multiple = false,
}: {
  options: any;
  file?: { url: string; size: number };
  multiple?: boolean;
}) {
  const dropzoneRef = useRef();

  let mockFile = {
    name: "Existing File",
    size: 0,
    accepted: true,
    status: Dropzone.SUCCESS,
  };

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      const { mount, unmount, ...rest } = options;
      mount && mount();
      return new Dropzone(dropzoneRef.current, {
        ...rest,

        init: function () {
          if (file) {
            mockFile.size = file.size;

            this.displayExistingFile(mockFile, file.url);

            this.files.push(mockFile);
          }

          this.on("addedfile", function (event) {
            while (this.files.length > this.options.maxFiles) {
              this.removeFile(this.files[0]);
            }
          });
        },
      });
    }

    function removeDropzone() {
      const { unmount } = options;
      unmount && unmount();
      dropZone.removeFile(mockFile);

      if (Dropzone.instances.length > 0)
        Dropzone.instances.forEach((dz) => dz.destroy());
    }

    let dropZone = createDropzone();

    return () => removeDropzone();
  }, []);

  return (
    <VuiDropzoneRoot
      sx={{
        justifyContent: "center",
        ".dz-progress": {
          display: "none !important",
        },
      }}
      component="form"
      action="/file-upload"
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <VuiBox className="fallback">
        <VuiBox component="input" name="file" type="file" multiple={multiple} />
      </VuiBox>
    </VuiDropzoneRoot>
  );
}

// Typechecking props for the VuiDropzone
VuiDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  existingFile: PropTypes.string,
};

export default VuiDropzone;

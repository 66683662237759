import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CheckBox from "@mui/material/Checkbox";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiFormField from "@/Components/VuiFormField";

import { Material, MaterialData } from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

import { SketchPicker } from "react-color";

const MaterialProperties = ({
  material,
  setMaterialState,
}: {
  material: Material;
  setMaterialState: (x: Material) => void;
}) => {
  const { t } = useTranslation();

  const [showBaseColor, setShowBaseColor] = useState(
    material.MaterialProperties?.color,
  );
  const [showEmissiveColor, setShowEmissiveColor] = useState(
    material.MaterialProperties?.color,
  );

  // States for color picker (on or off)
  const [showBaseColorPicker, setShowBaseColorPicker] = useState(false);
  const [showEmissiveColorPicker, setShowEmissiveColorPicker] = useState(false);

  const [emissiveCheck, setEmissiveCheck] = useState(
    material.MaterialProperties?.is_emissive,
  );
  const [overrideCheck, setOverrideCheck] = useState(
    material.MaterialProperties?.is_override,
  );

  useEffect(() => {
    setShowBaseColor(material.MaterialProperties?.color);
    setShowEmissiveColor(material.MaterialProperties?.emissive_color);
  }, [
    material.MaterialProperties?.color,
    material.MaterialProperties?.emissive_color,
  ]);

  const handleSetColor = (e) => {
    const { ...rest } = material;
    setMaterialState({
      ...rest,
      MaterialProperties: { ...rest.MaterialProperties, color: e.hex },
    });
  };

  const handleSetShininess = (e) => {
    const { ...rest } = material;
    setMaterialState({
      ...rest,
      MaterialProperties: {
        ...rest.MaterialProperties,
        shininess: e.target.value,
      },
    });
  };

  const handleSetSmoothness = (e) => {
    const { ...rest } = material;
    setMaterialState({
      ...rest,
      MaterialProperties: {
        ...rest.MaterialProperties,
        smoothness: e.target.value,
      },
    });
  };

  const handleSetEmissive = (e) => {
    const check = e.target.checked;
    handleToggleSwitch(check, setEmissiveCheck);
    const { ...rest } = material;
    setMaterialState({
      ...rest,
      MaterialProperties: {
        ...rest.MaterialProperties,
        is_emissive: check,
      },
    });
  };

  const handleSetEmissiveColor = (e) => {
    const { ...rest } = material;
    setMaterialState({
      ...rest,
      MaterialProperties: { ...rest.MaterialProperties, emissive_color: e.hex },
    });
  };

  const handleSetOverride = (e) => {
    const check = e.target.checked;
    handleToggleSwitch(check, setOverrideCheck);
    const { ...rest } = material;
    setMaterialState({
      ...rest,
      MaterialProperties: {
        ...rest.MaterialProperties,
        is_override: check,
      },
    });
  };

  const handleToggleClose = (setCallback) => {
    setCallback(false);
  };

  const handleToggleColorPicker = (setCallback, isShowColorPicker) => {
    setCallback(!isShowColorPicker);
  };

  const handleToggleColorChange = (setCallback, newColor) => {
    setCallback(newColor);
  };
  const handleToggleSwitch = (event, setCallback) => {
    setCallback(event);
  };

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox>
        <VuiTypography mb={1.5} variant="h5" color="white">
          Material Properties
        </VuiTypography>

        <VuiBox mt={1}>
          <VuiTypography
            color="white"
            component="label"
            variant="h7"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {t("modules.materials.properties.color")}
          </VuiTypography>
          <div
            style={{
              backgroundColor: material.MaterialProperties?.color,
              width: "36px",
              height: "36px",
            }}
            onClick={() =>
              handleToggleColorPicker(
                setShowBaseColorPicker,
                showBaseColorPicker,
              )
            }
          />
          {showBaseColorPicker ? (
            <div style={{ position: "absolute", zIndex: "2" }}>
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={() => handleToggleClose(setShowBaseColorPicker)}
              />
              <SketchPicker
                color={material.MaterialProperties?.color}
                onChangeComplete={handleSetColor}
              />
            </div>
          ) : null}
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.materials.properties.shininess")}
            value={material.MaterialProperties?.shininess}
            onChange={handleSetShininess}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.materials.properties.smoothness")}
            value={material.MaterialProperties?.smoothness}
            onChange={handleSetSmoothness}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <VuiTypography
              color="white"
              component="label"
              variant="h7"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {t("modules.materials.properties.is_emissive")}
            </VuiTypography>
          </VuiBox>
          <VuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <CheckBox checked={emissiveCheck} onChange={handleSetEmissive} />
          </VuiBox>
        </VuiBox>
        <VuiTypography
          color="white"
          component="label"
          variant="h7"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {t("modules.materials.properties.emissive_color")}
        </VuiTypography>
        <div
          style={{
            backgroundColor: material.MaterialProperties?.emissive_color,
            width: "36px",
            height: "36px",
          }}
          onClick={() =>
            handleToggleColorPicker(
              setShowEmissiveColorPicker,
              showEmissiveColorPicker,
            )
          }
        />
        {showEmissiveColorPicker ? (
          <div style={{ position: "absolute", zIndex: "2" }}>
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={() => handleToggleClose(setShowEmissiveColorPicker)}
            />
            <SketchPicker
              color={material.MaterialProperties?.emissive_color}
              onChangeComplete={handleSetEmissiveColor}
            />
          </div>
        ) : null}
        <VuiBox mt={1}>
          <VuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <VuiTypography
              color="white"
              component="label"
              variant="h7"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {t("modules.materials.properties.is_override")}
            </VuiTypography>
          </VuiBox>
          <VuiBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <CheckBox checked={overrideCheck} onChange={handleSetOverride} />
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default MaterialProperties;

import React from "react";

import Card from "@mui/material/Card";

import VuiBox from "@//Components/VuiBox";
import VuiTypography from "@//Components/VuiTypography";

import VuiFormField from "@//Components/VuiFormField";
import { Company } from "@//DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

function CompanyInfo({
  company,
  setCompanyState,
}: {
  company?: Company;
  setCompanyState: (x: Company) => void;
}) {
  const handleSetName = (e) => {
    const { Name, ...rest } = company;
    setCompanyState({ Name: e.target.value, ...rest });
  };

  const handleSetPhone = (e) => {
    const { Phone, ...rest } = company;
    setCompanyState({ Phone: e.target.value, ...rest });
  };

  const handleSetEmail = (e) => {
    const { Email, ...rest } = company;
    setCompanyState({ Email: e.target.value, ...rest });
  };

  const handleSetAddress = (e) => {
    const { Address, ...rest } = company;
    setCompanyState({ Address: e.target.value, ...rest });
  };

  const { t } = useTranslation();

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox mx={2} my={1}>
        <VuiTypography mb={1.5} variant="h5" color="white">
          {t("modules.companies.section_titles.information")}
        </VuiTypography>
        <VuiBox mt={2}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.companies.data.Name")}
            value={company?.Name}
            onChange={handleSetName}
          />
        </VuiBox>
        <VuiBox mt={2}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.companies.data.Phone")}
            value={company?.Phone}
            onChange={handleSetPhone}
          />
        </VuiBox>
        <VuiBox mt={2}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.companies.data.Email")}
            value={company?.Email}
            onChange={handleSetEmail}
          />
        </VuiBox>
        <VuiBox mt={2}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.companies.data.Address")}
            value={company?.Address}
            onChange={handleSetAddress}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default CompanyInfo;

import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";

import { useLayout } from "@/hooks/useLayout";

const CompanyManagementWindow = (props) => {
  useLayout(props.routeInfo);

  return <Outlet />;
};

export default CompanyManagementWindow;

import React, { useEffect, useState } from "react";

import { Divider, Icon, Card } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiInput from "@/Components/VuiInput";
import VuiButton from "@/Components/VuiButton";
import VuiTypography from "@/Components/VuiTypography";

import UserCard from "./UserCard";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { User } from "@/DatabaseControls/DataTypes";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useAtomValue } from "jotai";
import { currentUserState } from "@/states/auth";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

async function getDatabaseList(setUserList: (userList: User[]) => void) {
  const userList: Map<string, User> = await firebaseSingleton.getDatabaseList(
    "user",
  );
  setUserList(Array.from(userList, ([_, user]) => user));
}

function applySearch(searchTerm: string, listData: User[]) {
  return listData.filter((user: User) => {
    return user.Username.includes(searchTerm);
  });
}

const UserIndexWindow = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPlaceholder, setSearchPlaceholder] = useState("");
  const handleSetSearchTerm = (e) => setSearchTerm(e.target.value);
  const [hasRetrievedData, setHasRetrievedData] = useState(false);
  const [userList, setUserList] = useState<User[]>([]);
  const currentUser = useAtomValue(currentUserState);

  const navigate = useNavigate();
  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const refreshData = () => getDatabaseList(setUserList);

  const navigateToUserWindow = () => {
    navigate(`/users/new`, {});
  };

  const deleteUserItem = async (id: string) => {
    resetSnackbarProps();

    await firebaseSingleton.deleteAuthAndUser({ id }).then((res) => {
      if (res.status) {
        setSnackbarProps({
          open: true,
          icon: "notifications",
          iconColor: "info",
          content: "Account deleted successfully!",
        });
      } else {
        setSnackbarProps({
          open: true,
          icon: "report",
          iconColor: "error",
          content: "Account delete failed!",
        });
      }

      refreshData();
    });
  };

  if (!hasRetrievedData) {
    refreshData();
    setHasRetrievedData(true);
  }

  useEffect(() => {
    setSearchPlaceholder(t("common.messages.search"));
  }, [t]);

  return (
    <VuiBox mt={6}>
      <VuiBox>
        <Card>
          <VuiBox p={3}>
            <VuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiTypography variant="lg" fontWeight="bold" color="white">
                {t("modules.users.section_titles.list")}
              </VuiTypography>
              <VuiBox display="flex" justifyContent="space-between">
                <VuiBox pr={2} maxWidth={{ sm: "175px", md: "100%" }}>
                  <VuiInput
                    placeholder={searchPlaceholder}
                    onChange={handleSetSearchTerm}
                    icon={{ component: "search", direction: "left" }}
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("sm")]: {
                        maxWidth: "80px",
                      },
                      [breakpoints.only("sm")]: {
                        maxWidth: "80px",
                      },
                      backgroundColor: "info.main !important",
                    })}
                  />
                </VuiBox>
                <VuiButton
                  onClick={navigateToUserWindow}
                  variant="contained"
                  color="info"
                  sx={{ minWidth: "48px", minHeight: "36px", padding: 0 }}
                >
                  <Icon>add</Icon>
                </VuiButton>
              </VuiBox>
            </VuiBox>
            <Divider />
            <VuiBox>
              <VuiBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
              >
                {applySearch(searchTerm, userList).map(
                  (user: User, userIndex: number) => {
                    return (
                      <UserCard
                        key={user.id}
                        color={
                          currentUser.id === user.id ? "warning" : "success"
                        }
                        onClick={() =>
                          navigate(`/users/${user.id}`, {
                            state: {
                              user,
                            },
                          })
                        }
                        deleteUser={() => deleteUserItem(user.id as string)}
                        email={user.Email}
                        userId={user.id}
                        name={user.Username}
                        noDivider={userIndex === userList.length - 1}
                      />
                    );
                  },
                )}
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Card>
      </VuiBox>
    </VuiBox>
  );
};

export default UserIndexWindow;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import BasicLayout from "@/Components/BasicLayout";
import GradientBorder from "@/Components/GradientBorder";
import VuiBox from "@/Components/VuiBox";

import VuiTypography from "@/Components/VuiTypography";
import borders from "@/assets/theme/base/borders";

import bgBasic from "@/assets/background-basic-auth.png";

import { Divider, Grid, Icon } from "@mui/material";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import _ from "lodash";

import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

import { useAtomValue, useSetAtom } from "jotai";
import { allUserRolesState, currentUserRoleState } from "@/states/auth";

import { USER_ROLE_LABEL_MAPPER } from "../UserRoleManagement/UserRoleManagementWindow";
import useLocalStorage from "@/hooks/useLocalStorage";

const UserInitWindow = (props): JSX.Element => {
  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();

  const allUserRoles = useAtomValue(allUserRolesState);
  const setCurrentUserRole = useSetAtom(currentUserRoleState);

  const [, setUserRoleIdCahce] = useLocalStorage("user_role_id");

  useEffect(() => {
    if (allUserRoles.length < 1) {
      handleSignOut();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOut = async () => {
    resetSnackbarProps();

    firebaseSingleton.signOut();

    setSnackbarProps({
      open: true,
      icon: "report",
      iconColor: "error",
      content: "Signing out! User role not found!",
    });
  };

  return (
    <BasicLayout
      title={"Select Company"}
      // description={t("modules.auth.reset_password.subtitle")}
      image={bgBasic}
    >
      <GradientBorder
        borderRadius={borders.borderRadius.form}
        minWidth="100%"
        maxWidth="100%"
      >
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.main,
          })}
        >
          <VuiBox mb={2}>
            <Grid container direction={"column"}>
              {allUserRoles.map((userRole) => {
                const company = userRole.AssociatedCompany;

                return (
                  <Grid item xs={12}>
                    <VuiBox
                      onClick={() => {
                        setCurrentUserRole(userRole);
                        setUserRoleIdCahce(userRole.id);
                      }}
                      sx={({ palette, borders }) => ({
                        borderLeft: `${borders.borderWidth[4]} solid  ${palette.success.main}`,
                        cursor: "pointer",
                        padding: 1,
                        transition: "all .2s ease",

                        ":hover": {
                          background: palette.dark.main,
                        },
                      })}
                    >
                      <VuiBox width="100%" pl={1}>
                        <VuiBox display="flex" alignItems="center">
                          <Icon fontSize="medium" sx={{ color: "#fff" }}>
                            apartment
                          </Icon>
                          <VuiBox ml={1}>
                            <VuiTypography
                              variant="button"
                              color="white"
                              fontWeight="bold"
                            >
                              {_.upperFirst(company.Name)}
                            </VuiTypography>

                            <VuiBox mt={2}>
                              <VuiBox display="flex" alignItems="center">
                                <VuiTypography
                                  variant="caption"
                                  fontWeight="medium"
                                  color="text"
                                >
                                  {USER_ROLE_LABEL_MAPPER[userRole.AccessLevel]}
                                </VuiTypography>
                              </VuiBox>
                            </VuiBox>
                          </VuiBox>
                        </VuiBox>
                      </VuiBox>
                    </VuiBox>

                    <Divider light />
                  </Grid>
                );
              })}
            </Grid>
            {/* {allUserRoles.map((userRole) => {
              const company = userRole.AssociatedCompany;

              return (
                <VuiBox
                  width="100%"
                  pr={2}
                  py={1}
                  borderLeft={`${borders.borderWidth[4]} solid ${colors["success"].main}`}
                  onClick={() => {}}
                  sx={{
                    listStyle: "none",
                    cursor: "pointer",
                    "&:hover": {
                      marginLeft: `-${borders.borderWidth[4]}`,
                      transition: "all 100ms ease-in-out",
                    },
                  }}
                >
                  <VuiBox width="100%" pl={1} ml={2}>
                    <VuiBox display="flex" alignItems="center">
                      <Icon fontSize="default" sx={{ color: "#fff" }}>
                        folder
                      </Icon>
                      <VuiBox sx={{ display: "flex" }} ml={0.3} lineHeight={1}>
                        <VuiTypography
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          {company.Name}
                        </VuiTypography>
                      </VuiBox>
                    </VuiBox>
                  </VuiBox>
                </VuiBox>
              );
            })} */}
          </VuiBox>

          <VuiBox mt={3} textAlign="center">
            {/* <Divider sx={{ m: 1 }} light={true} /> */}

            <VuiBox>
              <VuiTypography
                component={Link}
                onClick={async () => {
                  await firebaseSingleton.signOut();
                  setSnackbarProps({
                    open: true,
                    icon: "notifications",
                    iconColor: "info",
                    content: `Signed out!`,
                  });
                }}
                variant="button"
                fontWeight="medium"
                sx={{
                  color: (theme) => theme.palette.text.main,

                  "&:hover": { color: (theme) => theme.palette.white.main },
                }}
              >
                Sign out
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </BasicLayout>
  );
};

export default UserInitWindow;

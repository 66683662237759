import React from "react";

import { Outlet } from "react-router-dom";

import { useLayout } from "@/hooks/useLayout";

function ProjectManagementWindow(props) {
  useLayout(props.routeInfo);

  return <Outlet />;
}

export default ProjectManagementWindow;

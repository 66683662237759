import React, { useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import VuiBox from "../VuiBox";
import { Divider, Grid, Icon, IconButton, useMediaQuery } from "@mui/material";
import theme from "@/assets/theme";
import VuiButton from "../VuiButton";
import VuiTypography from "../VuiTypography";

import { useTranslation } from "react-i18next";

interface BaseModalPropsType {
  children: React.ReactNode;
  title: string;
  open: boolean;
  disabledDoneButton?: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDone: (e: React.MouseEvent<HTMLButtonElement>) => void;
  submitLabel?: string;
  cancelLabel?: string;
  height?: number | string;
  footerButtons?: boolean;
  id?: number | string;
}

const BaseModal = (props: BaseModalPropsType) => {
  const { t } = useTranslation();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const footerButtons = props.footerButtons ?? true;

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 4,
    width: md ? "60%" : sm ? "70%" : "95%",
    // ...(props.height ? { height: props.height } : { maxHeight: "80vh" }),
    overflowY: "hidden",
    outline: "none",
    background: ({ palette }) => palette.secondary.main,
    border: 2,
    borderColor: ({ palette }) => palette.light.light,
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
    props.onClose(e);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    props.onDone(e);
  };

  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (ref.current && props.id) {
      ref.current.scrollTo(0, 0);
    }
  }, [ref, props.id]);

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <VuiBox sx={style} borderRadius="lg" boxShadow="lg">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <VuiTypography variant="h4" fontWeight="medium" color="white">
              {props.title}
            </VuiTypography>
          </Grid>

          <Grid item>
            <IconButton
              onClick={handleClose}
              sx={{
                color: ({ palette }) => palette.text.main,
                "& :hover": { color: ({ palette }) => palette.light.main },
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Grid>
        </Grid>

        <Divider light sx={{ mb: 0 }} />

        <Grid
          container
          ref={ref}
          sx={{
            overflow: "auto",
            p: 1,
            ...(props.height
              ? { height: props.height }
              : { maxHeight: "60vh" }),
            background: ({ palette }) => palette.secondary.dark,
            borderRadius: ({ borders }) => borders.borderRadius.md,
          }}
        >
          <Grid item xs={12}>
            {props.children}
          </Grid>
        </Grid>

        <Divider light sx={{ mt: 0 }} />

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
          sx={{ opacity: footerButtons ? 1 : 0 }}
        >
          <Grid item>
            <VuiButton onClick={handleClose} color="dark">
              {props.cancelLabel || t("common.buttons.cancel")}
            </VuiButton>
          </Grid>

          <Grid item>
            <VuiButton
              {...(props.disabledDoneButton && {
                disabled: props.disabledDoneButton,
              })}
              onClick={handleSubmit}
              color="info"
            >
              {props.submitLabel || t("common.buttons.done")}
            </VuiButton>
          </Grid>
        </Grid>
      </VuiBox>
    </Modal>
  );
};

export default BaseModal;

import React from "react";

import Grid from "@mui/material/Grid";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";

import MaterialImage from "./MaterialImage";
import MaterialInfo from "./MaterialInfo";
import MaterialProperties from "./MaterialProperties";

import { Material, ModelBrand } from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

import _ from "lodash";

const MaterialWindow = ({
  materialState,
  setMaterialState,
  modelBrandList,
  onSave,
  onDelete,
}: {
  materialState: Material;
  setMaterialState: (material: Material) => void;
  modelBrandList: ModelBrand[];
  onSave: () => void;
  onDelete: () => void;
}) => {
  const { t } = useTranslation();

  const isEdit = !!materialState.id;

  const handleSave = async () => {
    onSave();
  };

  const handleDelete = async (i) => {
    onDelete();
  };

  return (
    <>
      {materialState && (
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <VuiBox my={3}>
              <VuiBox mx={1} mt={1} mb={3}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <VuiTypography
                      variant="h4"
                      fontWeight="medium"
                      color="white"
                    >
                      {materialState?.Name || "New Material"}
                    </VuiTypography>
                    <VuiBox mt={1} mb={2}>
                      <VuiTypography variant="body2" color="text">
                        {t("common.pages.edit_description", {
                          title: _.toLower(
                            t("modules.materials.title_singular"),
                          ),
                        })}
                      </VuiTypography>
                    </VuiBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <VuiBox display="flex" justifyContent="flex-end" gap={1}>
                      {isEdit && (
                        <VuiButton
                          variant="contained"
                          color="error"
                          onClick={handleDelete}
                        >
                          {t("common.buttons.delete")}
                        </VuiButton>
                      )}

                      <VuiButton
                        variant="contained"
                        color="info"
                        onClick={handleSave}
                      >
                        {t("common.buttons.save")}
                      </VuiButton>
                    </VuiBox>
                  </Grid>
                </Grid>
              </VuiBox>
              <Grid container spacing={3} justifyContent="space-between">
                <Grid item xs={12}>
                  <MaterialInfo
                    material={materialState}
                    setMaterialState={setMaterialState}
                    modelBrandList={modelBrandList}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MaterialProperties
                    material={materialState}
                    setMaterialState={setMaterialState}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MaterialImage
                    material={materialState}
                    setMaterialState={setMaterialState}
                  />
                </Grid>
              </Grid>
            </VuiBox>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MaterialWindow;

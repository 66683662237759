/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";

// Vision UI Dashboard PRO React components
import VuiBox from "../VuiBox";
import VuiTypography from "../VuiTypography";
import VuiInput from "../VuiInput";
import VuiSelect from "../VuiSelect";

function VuiFormField({
  label,
  disabled,
  required,
  select,
  ...rest
}: {
  [x: string]: any;
  label: any;
  disabled?: boolean;
  required?: boolean;
  select?: boolean;
}) {
  return (
    <VuiBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <VuiTypography
          component="label"
          variant="caption"
          sx={{
            color: ({ palette }) =>
              disabled ? palette.grey[400] : palette.white.main,
          }}
          fontWeight="bold"
          textTransform="capitalize"
        >
          {required ? label + " *" : label}
        </VuiTypography>
      </VuiBox>

      {select ? (
        <VuiSelect {...{ ...rest, isDisabled: disabled }} />
      ) : (
        <VuiInput {...{ ...rest, disabled, required }} />
      )}
    </VuiBox>
  );
}

// Setting default values for the props of VuiFormField
VuiFormField.defaultProps = {
  label: " ",
  disabled: false,
  required: false,
  select: false,
};

// Typechecking props for VuiFormField
VuiFormField.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  select: PropTypes.bool,
};

export default VuiFormField;

import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Icon, Menu, MenuItem, Divider } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import borders from "@/assets/theme/base/borders";
import colors from "@/assets/theme/base/colors";

function CatalogCard({
  catalogId,
  color,
  name,
  image,
  tags,
  theme,
  noDivider,
  onClick,
  deleteCatalog,
}) {
  const { borderWidth } = borders;

  const [openMenu, setOpenMenu] = useState(null);

  const { t } = useTranslation();

  const handleOpenMenu = (e) => {
    e.stopPropagation();

    setOpenMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleDeleteCatalog = () => {
    handleCloseMenu();
    deleteCatalog(catalogId);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onClick={(e) => e.stopPropagation()}
      keepMounted
    >
      <MenuItem onClick={handleDeleteCatalog}>
        {t("common.buttons.delete")}
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <VuiBox
        component="li"
        width="100%"
        pr={2}
        mt={0.5}
        py={1}
        borderLeft={`${borderWidth[4]} solid ${colors[color].main}`}
        onClick={onClick}
        sx={{
          listStyle: "none",
          cursor: "pointer",
          "&:hover": {
            marginLeft: `-${borderWidth[4]}`,
            transition: "all 100ms ease-in-out",
          },
        }}
      >
        <VuiBox width="100%">
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            pl={0.5}
          >
            <VuiBox display="flex" alignItems="center" width="16rem">
              {image ? (
                <VuiBox
                  component="img"
                  onLoad={() => console.log("load")}
                  ml={2.5}
                  mr={2}
                  src={image}
                  alt={name}
                  sx={({ borders: { borderRadius }, palette }) => ({
                    width: "48px",
                    height: "48px",
                    objectFit: "contain",
                    boxShadow: "0 0 2px rgba(255, 255, 255, 1);",
                    borderRadius: borderRadius.md,
                  })}
                />
              ) : (
                <VuiBox
                  ml={2.5}
                  mr={2}
                  sx={({ borders: { borderRadius }, palette }) => ({
                    width: "48px",
                    minHeight: "48px",
                    borderRadius: borderRadius.lg,
                    backgroundColor: palette.dark.main,
                  })}
                />
              )}

              <VuiTypography variant="button" fontWeight="medium" color="white">
                {name}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              width="8rem"
              lineHeight={1}
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.catalogs.data.theme")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {theme || "-"}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              width="10rem"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: { display: "none" },
              })}
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.catalogs.data.id")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {catalogId}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              width="12rem"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: { display: "none" },
              })}
              mx={{ xs: 0, sm: "auto" }}
              lineHeight={1}
            >
              <VuiTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="white"
              >
                {t("modules.catalogs.data.tags")}
              </VuiTypography>
              <VuiTypography variant="caption" fontWeight="bold" color="white">
                {tags || "-"}
              </VuiTypography>
            </VuiBox>

            <VuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
              <Icon
                fontSize="default"
                sx={{ cursor: "pointer", color: "#fff" }}
                onClick={handleOpenMenu}
              >
                more_horiz
              </Icon>
            </VuiBox>
            {renderMenu()}
          </VuiBox>
        </VuiBox>
      </VuiBox>
      {noDivider ? null : <Divider light sx={{ marginBottom: 0 }} />}
    </>
  );
}

// Setting default values for the props of Todo
CatalogCard.defaultProps = {
  color: "info",
  noDivider: false,
};

// Typechecking props for the Todo
CatalogCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  catalogId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  tags: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
};

export default CatalogCard;

import "./App.css";
import React from "react";
import Routes from "@/Components/Routes/Routes";
import { AuthProvider, useAuth } from "@/context/Auth/AuthContext";
import AuthWindow from "@/Pages/Auth/AuthWindow";
import Sidenav from "@/Components/VuiSideNav";

import Snackbar from "@/Components/Snackbar/Snackbar";
import { SnackbarProvider } from "./context/Snackbar/SnackbarContext";
import DashboardLayout from "./Components/VuiDashboardLayout";
import Footer from "./Components/Footer";
import DashboardNavbar from "./Components/VuiDashboardNavbar";

import { routeInfoState } from "./states/navbar";
import { useAtomValue } from "jotai";
import { currentUserRoleState, currentUserState } from "./states/auth";

const Content = () => {
  const { isLoading } = useAuth();

  const currentUserRole = useAtomValue(currentUserRoleState);

  const routeInfo = useAtomValue(routeInfoState);

  return (
    <>
      <Snackbar />

      {isLoading ? (
        <></>
      ) : currentUserRole ? (
        <>
          <DashboardLayout>
            <DashboardNavbar routeInfo={routeInfo} />
            <Sidenav />
            <Routes />
            <Footer full={true} alignEnd={true} />
          </DashboardLayout>
        </>
      ) : (
        <AuthWindow />
      )}
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <SnackbarProvider>
        <Content />
      </SnackbarProvider>
    </AuthProvider>
  );
};

export var mainApp = App;

export default App;

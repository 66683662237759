import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiFormField from "@/Components/VuiFormField";
import VuiTypography from "@/Components/VuiTypography";

import DateInput from "@/Components/DateInput/DateInput";
import { ProjectFormInput } from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";
import CheckBox from "@mui/material/Checkbox";

const ProjectForm = ({
  isNewProject,
  projectDetails,
  setProjectDetails,
}: {
  isNewProject: boolean;
  projectDetails: ProjectFormInput;
  setProjectDetails: (project: ProjectFormInput) => void;
}) => {
  const [isHQFBX, setIsHQFBX] = useState(false);
  const onNameChange = (e) => {
    const { Name, ...rest } = projectDetails;
    setProjectDetails({ Name: e.target.value, ...rest });
  };

  const onAddressChange = (e) => {
    const { Address, ...rest } = projectDetails;
    setProjectDetails({ Address: e.target.value, ...rest });
  };

  const onStartDateChange = (e: {
    day: number;
    month: number;
    year: number;
  }) => {
    const { StartDate, ...rest } = projectDetails;

    setProjectDetails({ StartDate: e, ...rest });
  };

  const onEndDateChange = (e: { day: number; month: number; year: number }) => {
    const { EndDate, ...rest } = projectDetails;

    setProjectDetails({ EndDate: e, ...rest });
  };

  const { t } = useTranslation();

  const handleIsHQFBXChange = (e) => {
    const check = e.target.checked;
    handleToggleSwitch(check, setIsHQFBX);

    const { isHQFBX, ...rest } = projectDetails;

    setProjectDetails({ isHQFBX: check, ...rest });
  };

  const handleToggleSwitch = (state, setState) => {
    setState(state);
  };

  return (
    <VuiBox>
      <VuiTypography variant="h5" color="white">
        {isNewProject
          ? t("modules.projects.section_titles.new_project")
          : t("modules.projects.section_titles.edit_project")}
      </VuiTypography>
      <VuiBox mt={3}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <VuiFormField
              type="text"
              label={t("modules.projects.data.name")}
              placeholder={`${t("modules.projects.data.name")}...`}
              value={projectDetails.Name}
              onChange={onNameChange}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <VuiFormField
              type="text"
              label={t("modules.projects.data.address")}
              placeholder={`${t("modules.projects.data.address")}...`}
              value={projectDetails.Address}
              onChange={onAddressChange}
            />
          </Grid>
        </Grid>
      </VuiBox>
      <VuiBox mt={2}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <DateInput
              label={t("modules.projects.data.start_date")}
              value={projectDetails.StartDate}
              onChangeDate={onStartDateChange}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DateInput
              label={t("modules.projects.data.end_date")}
              value={projectDetails.EndDate}
              onChangeDate={onEndDateChange}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <VuiBox mt={1}>
              <VuiBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <VuiTypography
                  color="white"
                  component="label"
                  variant="h7"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t("modules.projects.data.is_hqfbx")}
                </VuiTypography>
              </VuiBox>
              <VuiBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <CheckBox
                  name="isHQFBX"
                  checked={isHQFBX || false}
                  onChange={handleIsHQFBXChange}
                />
              </VuiBox>
            </VuiBox>
          </Grid>
        </Grid>
      </VuiBox>
    </VuiBox>
  );
};

export default ProjectForm;

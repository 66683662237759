import React from "react";

import { Grid, Card } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import VuiFormField from "@/Components/VuiFormField";
import { User } from "@/DatabaseControls/DataTypes";

import _ from "lodash";

import { useTranslation } from "react-i18next";

const UserInfo = ({
  userState,
  setUserState,
  passwordInput,
  setPasswordInput,
  isEdit,
  isLoading,
}: {
  userState: User;
  setUserState: (user: User) => void;
  passwordInput: string;
  setPasswordInput: (password: string) => void;
  isEdit: boolean;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { value, name } = e.target;

    const newUserState = _.cloneDeep(userState);

    newUserState[name] = value;

    setUserState(newUserState);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;

    setPasswordInput(value);
  };

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox>
        <VuiBox mb={1.5} lineHeight={0} display="flex" flexDirection="column">
          <VuiTypography variant="h5" color="white" fontWeight="bold">
            {t("modules.users.section_titles.information")}
          </VuiTypography>
        </VuiBox>

        <VuiBox mt={1}>
          <Grid container flexDirection="column" spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} sm={12}>
                <VuiFormField
                  required
                  type="text"
                  name="Username"
                  label={t("modules.users.data.username")}
                  value={userState?.Username}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={12} sm={6}>
                <VuiFormField
                  required
                  type="text"
                  name="Email"
                  label={t("modules.users.data.email")}
                  value={userState?.Email}
                  onChange={handleInputChange}
                  disabled={isEdit || isLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <VuiFormField
                  type="text"
                  name="PhoneNo"
                  label={t("modules.users.data.phone")}
                  value={userState?.PhoneNo}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            {!isEdit && (
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <VuiFormField
                    required
                    type="password"
                    name="Email"
                    label={t("modules.users.data.password")}
                    onChange={handlePasswordChange}
                    disabled={isEdit || isLoading}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default UserInfo;

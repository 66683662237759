import React, { useEffect, useState } from "react";

import BaseModal from "@/Components/BaseModal";
import VuiBox from "@/Components/VuiBox";

import FurnitureWindow from "@/Pages/FurnitureManagement/FurnitureWindow";

import { Catalog, Furniture, ModelBrand } from "@/DatabaseControls/DataTypes";

import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useTranslation } from "react-i18next";

import { useAtom } from "jotai";
import { furnituresModalState } from "@/states/catalogs/modals";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { getDefaultFurniture } from "@/DatabaseControls/DataDefaultValues";

interface FurnituresModalPropsType {
  catalogState: Catalog;
  setCatalogState: (catalog: Catalog) => void;
  refreshData: () => void;
  modelBrandList: ModelBrand[];
}

const FurnituresModal = (props: FurnituresModalPropsType) => {
  const { catalogState, setCatalogState, refreshData, modelBrandList } = props;

  const [furnituresModal, setFurnituresModal] = useAtom(furnituresModalState);
  const [furnitureState, setFurnitureState] = useState<Furniture | null>(null);
  const [selectedFurniture, setSelectedFurniture] = useState<Furniture | null>(
    null,
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const isEdit = !!selectedFurniture;

  useEffect(() => {
    if (furnituresModal.isShow) {
      if (furnituresModal.props.selectedFurniture) {
        setSelectedFurniture(furnituresModal.props.selectedFurniture);
        setFurnitureState(furnituresModal.props.selectedFurniture);
      } else {
        setFurnitureState(getDefaultFurniture());
      }

      setIsLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [furnituresModal.isShow, catalogState]);

  const saveFurniture = async () => {
    resetSnackbarProps();

    if (isEdit) {
      await firebaseSingleton.updateFurniture(furnitureState).then(() => {
        handleClose();
        refreshData();
      });

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Furniture record updated successfully.",
      });
    } else {
      const newFurnitureId = firebaseSingleton.getNewDocumentId("furniture");

      await firebaseSingleton
        .addFurniture(furnitureState, newFurnitureId, catalogState.id)
        .then(() => {
          handleClose();
          refreshData();

          setCatalogState({
            ...catalogState,
            Furnitures: [...catalogState.Furnitures, newFurnitureId],
          });
        });

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Furniture record created successfully.",
      });
    }
  };

  const deleteFurniture = async () => {
    if (selectedFurniture) {
      await firebaseSingleton
        .deleteFurniture(selectedFurniture.id, catalogState.id)
        .then(() => {
          handleClose();
          refreshData();

          setCatalogState({
            ...catalogState,
            Furnitures: [...catalogState.Furnitures],
          });

          setSnackbarProps({
            open: true,
            icon: "check_circle",
            content: "Furniture record deleted successfully.",
          });
        });
    }
  };

  const handleClose = (): void => {
    setSelectedFurniture(null);

    setIsLoaded(false);

    setFurnituresModal({
      ...furnituresModal,
      isShow: false,
      props: {},
    });
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    saveFurniture();
  };

  return (
    <BaseModal
      title={
        selectedFurniture
          ? t("modules.furnitures.section_titles.edit")
          : t("modules.furnitures.section_titles.create")
      }
      open={furnituresModal.isShow}
      onClose={handleClose}
      onDone={handleSubmit}
    >
      <VuiBox sx={{ minHeight: 600 }}>
        {isLoaded && (
          <FurnitureWindow
            furnitureState={furnitureState}
            setFurnitureState={setFurnitureState}
            modelBrandList={modelBrandList}
            onSave={saveFurniture}
            onDelete={deleteFurniture}
          />
        )}
      </VuiBox>
    </BaseModal>
  );
};

export default FurnituresModal;

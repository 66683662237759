export class CookieHelper {
  static cookieJar: string = document.cookie;
  static cookies: string[] = [];
  static cookieMap: Map<string, string> = new Map();

  static getCookie(cookieName: string) {
    console.log(document.cookie);
    if (this.cookies.length == 0 || this.cookieMap.size == 0) {
      this.cookieJar = this.cookieJar.replaceAll(" ", "");
      this.cookies = this.cookieJar.split(";");
      this.cookieMap = new Map();
      for (let i = 0; i < this.cookies.length; i++) {
        let currentCookie = this.cookies[i].split("=");
        this.cookieMap.set(currentCookie[0], currentCookie[1]);
      }
    }
    return this.cookieMap.get(cookieName);
  }

  static setCookie(cookieName: string, value: string) {
    let endDate: Date = new Date();
    endDate.setDate(endDate.getDate() + 7);
    console.log("Cookie expires at ", endDate);
    document.cookie = cookieName + "=" + value + "; Path=/; expires=" + endDate; //30 * 24 * 60 * 60;
    this.cookieJar = document.cookie;
    this.cookies = [];
    this.cookieMap = new Map();
  }
}

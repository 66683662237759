import React from "react";

import Card from "@mui/material/Card";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiSelect from "@/Components/VuiSelect";
import VuiFormField from "@/Components/VuiFormField";

import {
  ElectricalSwitch,
  ModelBrand,
  ModelVariantMapper,
} from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";

const ElectricalSwitchInfo = ({
  electricalSwitch,
  setElectricalSwitchState,
  modelBrandList,
}: {
  electricalSwitch?: ElectricalSwitch;
  setElectricalSwitchState: (x: ElectricalSwitch) => void;
  modelBrandList: ModelBrand[];
}) => {
  const { t } = useTranslation();

  const handleSetName = (e) => {
    const { Name, ...rest } = electricalSwitch;
    setElectricalSwitchState({ Name: e.target.value, ...rest });
  };

  const handleSetCost = (e) => {
    const { Cost, ...rest } = electricalSwitch;
    setElectricalSwitchState({ Cost: e.target.value, ...rest });
  };

  // const handleSetType = (e) => {
  //   const { FurnitureType, ...rest } = electricalSwitch;
  //   setElectricalSwitchState({ FurnitureType: e.value, ...rest });
  // };

  const handleSetVariant = (e) => {
    const { Variant, ...rest } = electricalSwitch;
    setElectricalSwitchState({ Variant: e.value, ...rest });
  };

  const handleSetBrand = (e) => {
    const { Brand, ...rest } = electricalSwitch;
    setElectricalSwitchState({ Brand: e.value, ...rest });
  };

  const handleSetPurchaseLink = (e) => {
    const { PurchaseURL, ...rest } = electricalSwitch;
    setElectricalSwitchState({ PurchaseURL: e.target.value, ...rest });
  };

  const brandOptions = modelBrandList.map((modelBrand) => ({
    value: modelBrand.id,
    label: modelBrand.Name,
  }));

  return (
    <Card sx={{ overflow: "visible", zIndex: 10 }}>
      <VuiBox>
        <VuiTypography mb={1.5} variant="h5" color="white">
          Electrical Switch Information
        </VuiTypography>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.electricalSwitches.data.name")}
            value={electricalSwitch?.Name}
            onChange={handleSetName}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.electricalSwitches.data.cost")}
            value={electricalSwitch?.Cost}
            onChange={handleSetCost}
          />
        </VuiBox>
        {/* <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.furnitures.data.furniture_type")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={{
              value: electricalSwitch?.FurnitureType || "0",
              label: FurnitureTypeMapper[furnitelectricalSwitchure?.FurnitureType || "0"],
            }}
            onChange={handleSetType}
            options={Object.keys(FurnitureTypeMapper).map((key) => ({
              value: key,
              label: FurnitureTypeMapper[key],
            }))}
          />
        </VuiBox> */}
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.electricalSwitches.data.variant")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={{
              value: electricalSwitch?.Variant || "0",
              label: ModelVariantMapper[electricalSwitch?.Variant || "0"],
            }}
            onChange={handleSetVariant}
            options={Object.keys(ModelVariantMapper).map((key) => ({
              value: key,
              label: ModelVariantMapper[key],
            }))}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.electricalSwitches.data.brand")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              electricalSwitch?.Brand
                ? brandOptions.find(
                    (brand) => brand.value === electricalSwitch?.Brand,
                  ) || null
                : null
            }
            onChange={handleSetBrand}
            options={brandOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.electricalSwitches.data.purchase_link")}
            value={electricalSwitch?.PurchaseURL}
            onChange={handleSetPurchaseLink}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default ElectricalSwitchInfo;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import ProjectCard from "./ProjectCard";
import VuiButton from "@/Components/VuiButton";
import VuiInput from "@/Components/VuiInput";

import { Project } from "@/DatabaseControls/DataTypes";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import moment from "moment";
import ProjectEdit from "./ProjectEdit";

function parseDate(dateString: string) {
  return moment(dateString).format("DD MMM YYYY / HH:mm");
}

function applySearch(searchTerm: string, listData: Project[]) {
  return listData.filter((project: Project) => {
    return (
      project.Name.includes(searchTerm) ||
      project.PhysicalAddress.includes(searchTerm) ||
      project.id.toString().includes(searchTerm)
    );
  });
}

const ProjectList = ({
  listData,
  refreshData,
}: {
  listData: Project[];
  refreshData: () => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPlaceholder, setSearchPlaceholder] = useState("");
  const handleSetSearchTerm = (e) => setSearchTerm(e.target.value);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToProjectWindow = () => {
    navigate(`/projects/new`, {});
  };

  const deleteProject = (id: string) =>
    firebaseSingleton.deleteProject(id).then(refreshData);
  const renameProject = (id: string, projectName: string) =>
    firebaseSingleton.updateProjectName(id, projectName).then(refreshData);

  useEffect(() => {
    setSearchPlaceholder(t("common.messages.search"));
  }, [t]);

  return (
    <Card>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center">
        <VuiTypography variant="lg" fontWeight="bold" color="white">
          {t("modules.projects.section_titles.list")}
        </VuiTypography>
        <VuiBox display="flex" justifyContent="space-between">
          <VuiBox pr={2} maxWidth={{ sm: "175px", md: "100%" }}>
            <VuiInput
              placeholder={searchPlaceholder}
              onChange={handleSetSearchTerm}
              icon={{ component: "search", direction: "left" }}
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  maxWidth: "80px",
                },
                [breakpoints.only("sm")]: {
                  maxWidth: "80px",
                },
                backgroundColor: "info.main !important",
              })}
            />
          </VuiBox>
          <VuiButton
            onClick={navigateToProjectWindow}
            variant="contained"
            color="info"
            sx={{ minWidth: "48px", minHeight: "36px", padding: 0 }}
          >
            <Icon>add</Icon>
          </VuiButton>
        </VuiBox>
      </VuiBox>
      <Divider />
      <VuiBox>
        <VuiBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {applySearch(searchTerm, listData).map(
            (project: Project, i: number) => {
              return (
                <ProjectCard
                  color="success"
                  onClick={() =>
                    navigate(`/projects/${project.id}`, {
                      state: {
                        project,
                      },
                    })
                  }
                  renameProject={renameProject}
                  deleteProject={deleteProject}
                  key={project.id}
                  title={project.Name}
                  date={parseDate(project.LastModified)}
                  project={project.id + ""}
                  address={project.PhysicalAddress}
                  noDivider={i === listData.length - 1}
                />
              );
            },
          )}
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default ProjectList;

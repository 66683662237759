export const FurnitureTypeMapper = {
  "0": "Seat",
  "1": "Door",
  "2": "Decoration",
  "3": "Window",
  "4": "Electrical",
  "5": "Bathroom",
  "6": "Toilet",
  "7": "Storage",
  "8": "Kitchen",
  "9": "Other",
};

export const ModelVariantMapper = {
  "0": "FBX",
  "1": "Unity Asset",
  "2": "Built-In Prefab",
};

export type MaterialTypesType = "Wall" | "Floor" | "Construction" | "Other";

export type Catalog = {
  id: string;
  order: string;
  parentId: string;
  Name: string;
  Theme: string;
  PreviewImageURL: string;
  Tags: string[];
  Furnitures: Furniture["id"][];
  WallMaterials: Material["id"][];
  FloorMaterials: Material["id"][];
  ConstructionMaterials: Material["id"][];
  OtherMaterials: Material["id"][];
  Lights: Light["id"][];
  ElectricalSwitches: ElectricalSwitch["id"][];
};

export type Furniture = {
  id: string;
  order: string;
  parentId: string;
  Brand: string;
  Cost: number;
  FurnitureType: string;
  ModelURL: string;
  Name: string;
  PurchaseLink: string;
  ThumbnailURL: string;
  Variant: number | string;
};

export type Light = {
  id: string;
  order: string;
  parentId: string;
  Brand: string;
  Cost: number;
  ModelURL: string;
  Name: string;
  PurchaseURL: string;
  ThumbnailURL: string;
  Variant: number | string;
  LightProperties: LightData;
};

export const LightShape = {
  Cone: 0,
  Box: 1,
  Spot: 2,
};

export const LightType = {
  Point: 0,
  Spot: 1,
};

export const LightMount = {
  Wall: 0,
  Ceiling: 1,
  Decor: 2,
};
export const PowerType = {
  Lumens: 0,
  Watts: 1,
};
export const LampType = {
  Custom: 0,
  HighPressureSodium: 2200,
  Incandescent: 2400,
  SoftWhiteIncandescent: 2550,
  SoftWhiteCCT: 2700,
  WarmWhiteCCT: 3000,
  Halogen: 3001,
  WarmMetalHalide: 3200,
  ClearMetalHalide: 4000,
  CoolWhiteCCT: 4100,
  FullSpectrumCCT: 5000,
  DaylightMetalHalide: 5500,
  Xenon: 6200,
  DaylightCCT: 6500,
};

export type LightData = {
  ColorHexadecimal: string;
  Shape: number;
  Type: number;
  Mount: number;
  PowerType: number;
  ColorType: number;
  Lumens: number;
  ColorTemperature: number;
  WhiteBalance: number;
  Wattage: number;
  Efficiency: number;
  Range: number;
  ConeAngle: number;
  Radius: number;
  BoxX: number;
  BoxY: number;
  isCustom: boolean;
  SourceDistance: number;
};

// Using name "ElectricalSwitch" instead of switch to prevent naming collisions with switch case
export type ElectricalSwitch = {
  id: string;
  order: string;
  parentId: string;
  Brand: string;
  Cost: number;
  ModelURL: string;
  Name: string;
  PurchaseURL: string;
  ThumbnailURL: string;
  Variant: number | string;
};

export type MaterialData = {
  color: string;
  shininess: number;
  smoothness: number;
  is_emissive: boolean;
  emissive_color: string;
  is_override: boolean;
};

export type Material = {
  id: string;
  order: string;
  parentId: string;
  Brand: string;
  CatalogueID: string;
  CatalogueName: string;
  Cost: number;
  Name: string;
  PurchaseURL: string;
  TextureURL: string;
  NormalURL: string;
  MaterialProperties: MaterialData;
};

export type ModelBrand = {
  id: string;
  Name: string;
  PhysicalAddress: string;
  WebsiteURL: string;
  LogoURL: string;
  PhoneNumber: string;
};

export type Company = {
  id: string;
  Name: string;
  Phone: string;
  Email: string;
  Address: string;
  Projects: string[];
  AccessibleCatalogues: string[];
  CompanyTheme: string;
};

export type Theme = {
  id: number | string;
  LogoURL: string;
  IconURL: string;
  BannerURL: string;
  PrimaryColor: string;
  SecondaryColor: string;
  TertiaryColor: string;
};

export type Project = {
  id: string;
  order: string;
  parentId: string;
  "3DModelPreviewImageUrl": string;
  Current3DModelLocation: string;
  Data: any;
  EndDate: string;
  LastModified: string;
  Name: string;
  PhysicalAddress: string;
  RealWorldImageUrl: string;
  StartDate: string;
  isHQFBX: boolean;
};

export type Vector3 = {
  x: number;
  y: number;
  z: number;
};

export type LocationInWorld = {
  RelativeLocation: Vector3;
  RelativeRotation: Vector3;
  RelativeScale: Vector3;
};

export type Project360Photo = {
  DatePosted: string;
  ImageLocation: string;
  OriginalPoster: string;
  ThumbnailLocation: string;
  id: string;
};

export type ProjectComments = {
  DatePosted: string;
  ImageLocation: string;
  InitialComment: string;
  LocationInWorld: LocationInWorld;
  OriginalPoster: string;
  ThumbnailLocation: string;
  Status: string;
  id: string;
};

export type ProjectFormInput = {
  Name?: string;
  Address?: string;
  StartDate?: { day: number; month: number; year: number };
  EndDate?: { day: number; month: number; year: number };
  isHQFBX?: boolean;
};

export type User = {
  id: string;
  UserId: string;
  FirstName: string;
  LastName: string;
  Username: string;
  ProfilePictureUrl: string;
  Email: string;
  PhoneNo: string;
  IsSuperAdmin: boolean;
  accessibleProjects: string[];
};

export type UserRole = {
  id: string;
  AccessLevel: string;
  AssociatedCompany: string;
  AssociatedUser: string;
};

export type JoinedUserRole = Omit<
  UserRole,
  "AssociatedCompany" | "AssociatedUser"
> & {
  AssociatedCompany: Company;
  AssociatedUser: User;
};

// @ts-nocheck

/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React, { useState, useEffect } from "react";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "../VuiBox";
import VuiTypography from "../VuiTypography";
import VuiSelect from "../VuiSelect";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMenuIcon,
  navbarMenuText,
} from "./styles";

// Vision UI Dashboard PRO React context
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../context/Vui";

import { firebaseSingleton } from "../../DatabaseControls/FirebaseController";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import { useAtomValue } from "jotai";
import { currentUserState } from "@/states/auth";

import { useTranslation } from "react-i18next";
import { LANGUAGE_OPTIONS } from "@/i18n";

function DashboardNavbar({ absolute, light, isMini, routeInfo }) {
  const [navbarType, setNavbarType] = useState<"sticky" | "static">();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;

  const navigate = useNavigate();
  const { setSnackbarProps } = useSnackbar();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  const handleNavigate = () => {
    if (routeInfo.path) {
      navigate(routeInfo.path);
    }
  };

  const currentUser = useAtomValue(currentUserState);
  const { t, i18n } = useTranslation();

  const languageOptions = LANGUAGE_OPTIONS.map((option) => {
    return {
      ...option,
      label: t(`languages.${option.key}`),
    };
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" sx={(theme) => navbarRow(theme, { isMini })}>
          <Icon
            fontSize="medium"
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
          >
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>

          {routeInfo.icon && (
            <Icon fontSize="small" sx={navbarMenuIcon} onClick={handleNavigate}>
              {routeInfo.icon}
            </Icon>
          )}

          <VuiTypography
            pl={1}
            sx={navbarMenuText}
            variant="button"
            fontWeight="medium"
            color="white"
            onClick={handleNavigate}
          >
            {t(`modules.${routeInfo.localeKey}.page_title`)}
          </VuiTypography>
        </VuiBox>

        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox sx={{ width: "20%", ml: "auto" }}>
              <VuiSelect
                size="extraSmall"
                options={languageOptions}
                value={languageOptions.find(
                  (option) => option.value === i18n.resolvedLanguage,
                )}
                onChange={(option) => {
                  i18n.changeLanguage(option.value);
                }}
              />
            </VuiBox>

            <VuiBox color={light ? "white" : "inherit"}>
              <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: white.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <VuiTypography
                    variant="button"
                    fontWeight="medium"
                    color="white"
                  >
                    {currentUser.Username}
                  </VuiTypography>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon>home</Icon>
              </IconButton>
              <IconButton
                size="small"
                color="white"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={async () => {
                  await firebaseSingleton.signOut();
                  setSnackbarProps({
                    open: true,
                    icon: "notifications",
                    iconColor: "info",
                    content: `Signed out!`,
                  });
                }}
              >
                <Icon>exit_to_app</Icon>
              </IconButton>
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

import React from "react";

import Grid from "@mui/material/Grid";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";

import LightImage from "./LightImage";
import LightInfo from "./LightInfo";

import { Light, ModelBrand } from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";
import LightProperties from "./LightProperties";

const LightWindow = ({
  lightState,
  setLightState,
  modelBrandList,
  onSave,
  onDelete,
}: {
  lightState: Light;
  setLightState: (light: Light) => void;
  modelBrandList: ModelBrand[];
  onSave: () => void;
  onDelete: () => void;
}) => {
  const { t } = useTranslation();

  const isEdit = !!lightState.id;

  const handleSave = async () => {
    onSave();
  };

  const handleDelete = async (i) => {
    onDelete();
  };

  return (
    <>
      {lightState && (
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <VuiBox my={3}>
              <VuiBox mx={1} mt={1} mb={3}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} lg={6}>
                    <VuiTypography
                      variant="h4"
                      fontWeight="medium"
                      color="white"
                    >
                      {lightState?.Name || "New Light"}
                    </VuiTypography>
                    <VuiBox mt={1} mb={2}>
                      <VuiTypography variant="body2" color="text">
                        {t("common.pages.edit_description", {
                          title: t("modules.lights.title_singular"),
                        })}
                      </VuiTypography>
                    </VuiBox>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <VuiBox display="flex" justifyContent="flex-end" gap={1}>
                      {isEdit && (
                        <VuiButton
                          variant="contained"
                          color="error"
                          onClick={handleDelete}
                        >
                          {t("common.buttons.delete")}
                        </VuiButton>
                      )}

                      <VuiButton
                        variant="contained"
                        color="info"
                        onClick={handleSave}
                      >
                        {t("common.buttons.save")}
                      </VuiButton>
                    </VuiBox>
                  </Grid>
                </Grid>
              </VuiBox>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12}>
                  <LightInfo
                    light={lightState}
                    setLightState={setLightState}
                    modelBrandList={modelBrandList}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LightProperties
                    light={lightState}
                    setLightState={setLightState}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LightImage
                    light={lightState}
                    setLightState={setLightState}
                  />
                </Grid>
              </Grid>
            </VuiBox>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LightWindow;

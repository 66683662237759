import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import VuiBox from "../VuiBox";
import VuiTypography from "../VuiTypography";
import VuiSelect from "../VuiSelect";

const generateDays = (dayCount: number) => {
  return new Array(dayCount).fill(undefined).map((_, i) => {
    return { value: i + 1, label: i + 1 };
  });
};

const months = [
  { value: "january", label: "January", days: 31, order: 0 },
  { value: "february", label: "February", days: 28, order: 1 },
  { value: "march", label: "March", days: 31, order: 2 },
  { value: "april", label: "April", days: 30, order: 3 },
  { value: "may", label: "May", days: 31, order: 4 },
  { value: "june", label: "June", days: 30, order: 5 },
  { value: "july", label: "July", days: 31, order: 6 },
  { value: "august", label: "August", days: 31, order: 7 },
  { value: "september", label: "September", days: 30, order: 8 },
  { value: "october", label: "October", days: 31, order: 9 },
  { value: "november", label: "November", days: 30, order: 10 },
  { value: "december", label: "December", days: 31, order: 11 },
];

const getMonthSelection = (value: string) => {
  return Array.from(months).find((month) => month.value === value);
};

const years = new Array(150).fill(undefined).map((_, i) => {
  return { value: 1900 + i, label: 1900 + i };
});

type FormDate = {
  day: number;
  month: number;
  year: number;
};

type DateInputType = {
  day: number;
  month: string;
  year: number;
};

function toFormDate(dateInput: DateInputType): FormDate {
  return {
    day: dateInput.day,
    month: Array.from(months).find((month) => month.value == dateInput.month)
      .order,
    year: dateInput.year,
  };
}

function toDateInput(formDate: FormDate): DateInputType {
  return {
    day: formDate.day,
    month: Array.from(months)[formDate.month].value,
    year: formDate.year,
  };
}

const DateInput = ({
  label,
  value,
  onChangeDate,
}: {
  label: string;
  value?: FormDate;
  onChangeDate?: (formDate: FormDate) => void;
}) => {
  const [date, changeDate] = useState({
    day: 1,
    month: "january",
    year: 2023,
  });
  const [days, changeDayCount] = useState(generateDays(31));
  const [day, changeDay] = useState(date.day);
  const [month, changeMonth] = useState(date.month);
  const [year, changeYear] = useState(date.year);

  useEffect(() => {
    if (value) {
      const newDate = toDateInput(value);

      changeDay(newDate.day);
      changeMonth(newDate.month);
      changeYear(newDate.year);
      changeDate(newDate);
    }
  }, [value]);

  const onDayChanged = (e) => {
    const newDate = { day: e.value, month: date.month, year: date.year };
    changeDay(e.value);
    changeDate(newDate);
    onChangeDate && onChangeDate(toFormDate(newDate));
  };

  const onMonthChanged = (e) => {
    if (e.value === "february") {
      if (year % 4 === 0) {
        changeDayCount(generateDays(29));
      } else {
        changeDayCount(generateDays(28));
      }
    } else if (e.days !== days.length) {
      changeDayCount(generateDays(e.days));
    }
    const newDate = { day: date.day, month: e.value, year: date.year };
    changeMonth(e.value);
    changeDate(newDate);
    onChangeDate && onChangeDate(toFormDate(newDate));
  };

  const onYearChanged = (e) => {
    if (month === "february") {
      const isLeapYear = e.value % 4 === 0;
      if (isLeapYear && days.length !== 29) {
        changeDayCount(generateDays(29));
      } else if (!isLeapYear && days.length !== 28) {
        changeDayCount(generateDays(28));
      }
    }
    const newDate = { day: date.day, month: date.month, year: e.value };
    changeYear(e.value);
    changeDate(newDate);
    onChangeDate && onChangeDate(toFormDate(newDate));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              color="white"
              textTransform="capitalize"
            >
              {label}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            placeholder={1}
            options={days}
            value={days[day - 1]}
            onChange={onDayChanged}
            selected
          />
        </VuiBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <VuiSelect
            placeholder={"January"}
            options={months}
            value={getMonthSelection(month)}
            onChange={onMonthChanged}
            selected
          />
        </VuiBox>
      </Grid>
      <Grid item xs={12} sm={4}>
        <VuiBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <VuiSelect
            placeholder={2023}
            options={years}
            value={years[year - 1900]}
            onChange={onYearChanged}
            selected
          />
        </VuiBox>
      </Grid>
    </Grid>
  );
};

export default DateInput;

import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

import { Grid, Card, Stepper, Step, StepLabel, Box } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiButton from "@/Components/VuiButton";
import VuiTypography from "@/Components/VuiTypography";

import ProjectForm from "./ProjectForm";
import ProjectMedia, {
  DropzoneContent,
  convertUrlToBlobUrl,
  extractExtension,
} from "./ProjectMedia";

import { Project, ProjectFormInput } from "@/DatabaseControls/DataTypes";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { getDefaultProject } from "@/DatabaseControls/DataDefaultValues";

import _ from "lodash";
import { useAtomValue } from "jotai";
import { currentUserRoleState } from "@/states/auth";
import { projectID } from "firebase-functions/params";

const getSteps = () => {
  return [
    { key: "project_info", label: "Project Info" },
    { key: "real_world_preview", label: "2. Real World Preview" },
    { key: "3d_model_preview", label: "3. 3D Model Preview" },
    { key: "model_fbx", label: "4. Model FBX" },
    { key: "model_placement", label: "5. Model Placement" },
  ];
};

const ConfigureStartingPosition = ({
  modelFbx,
  projectId,
  idToken,
}: {
  modelFbx: DropzoneContent;
  projectId: string;
  idToken: string;
}) => {
  const { t } = useTranslation();
  console.log(projectId);
  console.log(idToken);
  return (
    <VuiBox>
      <VuiTypography variant="h5" color="white" mb={3}>
        {t("modules.projects.section_titles.configure_starting_position")}
      </VuiTypography>
      {modelFbx ? (
        <VuiBox>
          <iframe
            title={projectId}
            allow={"fullscreen"}
            width={"100%"}
            height={"400px"}
            src={`https://jbuild-model-starting-placer.web.app/?ProjectID=${projectId}&FirebaseToken=${idToken}`}
          />
        </VuiBox>
      ) : (
        <VuiTypography mb={3} variant="body2" color="white">
          No FBX provided
        </VuiTypography>
      )}
    </VuiBox>
  );
};

const ConfigureProjectMedia = ({
  key,
  title,
  label,
  dropzoneContentState,
}: {
  key: string;
  title: string;
  label: string;
  dropzoneContentState: [DropzoneContent, (x: DropzoneContent) => void];
}) => {
  const { t } = useTranslation();
  return (
    <ProjectMedia
      key={key}
      title={t(title)}
      label={label}
      dropzoneContentState={dropzoneContentState}
    />
  );
};

const ConfigureProjectDetails = ({
  isNewProject,
  projectDetailsState,
  setProjectDetails,
}: {
  isNewProject: boolean;
  projectDetailsState: ProjectFormInput;
  setProjectDetails: (input: ProjectFormInput) => void;
}) => {
  return (
    <ProjectForm
      isNewProject={isNewProject}
      projectDetails={projectDetailsState}
      setProjectDetails={setProjectDetails}
    />
  );
};

const getStepContent = (
  isNewProject,
  stepIndex,
  projectDetails,
  setProjectDetails,
  realWorldPreview,
  setRealWorldPreview,
  modelPreview,
  setModelPreview,
  modelFbx,
  setModelFbx,
  projectId,
  idToken,
  t,
) => {
  switch (stepIndex) {
    case 0:
      return (
        <ProjectForm
          isNewProject={isNewProject}
          projectDetails={projectDetails}
          setProjectDetails={setProjectDetails}
        />
      );
    case 1:
      return (
        <ProjectMedia
          key="RealWorldImage"
          title={t("modules.projects.section_titles.real_world_preview")}
          label={"Upload Real World Preview Image"}
          dropzoneContentState={[realWorldPreview, setRealWorldPreview]}
        />
      );
    case 2:
      return (
        <ProjectMedia
          key="3DModelPreviewImage"
          title={t("modules.projects.section_titles.3d_model_preview")}
          label={"Upload 3D Model Preview Image"}
          dropzoneContentState={[modelPreview, setModelPreview]}
        />
      );
    case 3:
      return (
        <ProjectMedia
          key="3DModelFBX"
          title={t("modules.projects.section_titles.model_fbx")}
          label={"Upload 3D Model FBX"}
          dropzoneContentState={[modelFbx, setModelFbx]}
        />
      );
    case 4:
      return (
        <VuiBox>
          <VuiTypography variant="h5" color="white" mb={3}>
            {t("modules.projects.section_titles.configure_starting_position")}
          </VuiTypography>
          {projectId && modelFbx ? (
            <VuiBox>
              <iframe
                title={projectId}
                allow={"fullscreen"}
                width={"100%"}
                height={"400px"}
                src={`https://jbuild-model-starting-placer.web.app/?ProjectID=${projectId}&FirebaseToken=${idToken}`}
              />
            </VuiBox>
          ) : (
            <VuiTypography mb={3} variant="body2" color="white">
              No FBX provided
            </VuiTypography>
          )}
        </VuiBox>
      );
    default:
      return null;
  }
};

export const DateTimeToDateInput = (dateString: string) => {
  const dateTime: Date = new Date(dateString);
  return {
    day: dateTime.getDate(),
    month: dateTime.getMonth(),
    year: dateTime.getFullYear(),
  };
};

export const DateInputToDateTime = ({
  day,
  month,
  year,
}: {
  day: number;
  month: number;
  year: number;
}) => {
  const dateTime: Date = new Date(year, month, day);
  return dateTime.toString();
};

const ProjectWindow = ({ project }: { project?: Project }) => {
  const [projectState, setProjectState] = useState<Project | null>(null);
  const [projectDetails, setProjectDetails] = useState<ProjectFormInput>({
    Name: "New Project",
    Address: "",
    StartDate: { day: 1, month: 1, year: new Date().getFullYear() },
    EndDate: { day: 1, month: 1, year: new Date().getFullYear() },
    isHQFBX: false,
  });

  const [realWorldPreview, setRealWorldPreview] = useState<DropzoneContent>();
  const [modelPreview, setModelPreview] = useState<DropzoneContent>();
  const [modelFbx, setModelFbx] = useState<DropzoneContent>();
  const [idToken, setIdToken] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [updatingProject, setUpdatingProject] = useState(false);

  const currentUserRole = useAtomValue(currentUserRoleState);

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { setSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const steps = getSteps().map((step) => {
    return t(`modules.projects.section_titles.${step.key}`);
  });
  const isSecondLastStep = activeStep === steps.length - 2;
  const isLastStep = activeStep === steps.length - 1;
  const isNewProject = !params.id;

  const initStates = async (project?: Project) => {
    if (project) {
      setProjectState(project);
      setProjectDetails({
        Name: project.Name,
        Address: project.PhysicalAddress,
        StartDate: DateTimeToDateInput(project.StartDate),
        EndDate: DateTimeToDateInput(project.EndDate),
        isHQFBX: project.isHQFBX,
      });

      await convertUrlToBlobUrl(project.RealWorldImageUrl, setRealWorldPreview);
      await convertUrlToBlobUrl(
        project["3DModelPreviewImageUrl"],
        setModelPreview,
      );
      await convertUrlToBlobUrl(project.Current3DModelLocation, setModelFbx);
    } else {
      setProjectState(getDefaultProject());
    }

    firebaseSingleton.getCurrentUserToken().then((token) => {
      setIdToken(token);
    });
  };

  useEffect(() => {
    if (params.id) {
      if (location.state?.project) {
        initStates(location.state.project);
      } else {
        getProject().then((result) => {
          if (result.status) {
            initStates(result.data);
          } else {
            navigate("/projects");
          }
        });
      }
    } else {
      initStates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, location.state]);

  const getProject = async () => {
    return await firebaseSingleton.getProject(params.id);
  };

  const handleMediaUpdate = async (
    project: Project,
    dropzoneContent: DropzoneContent,
    imageLocation: string,
    imageName: string,
    propertyName: string,
  ): Promise<void> => {
    const extension = extractExtension(dropzoneContent?.file?.name);
    const fileName = imageName + "-" + project.id + extension;

    if (dropzoneContent.file) {
      return new Promise<void>((res, rej) => {
        firebaseSingleton
          .uploadMedia(imageLocation, fileName, dropzoneContent.file)
          .then(() => {
            firebaseSingleton
              .getMediaUrl(imageLocation, fileName)
              .then((newUrl: string) => {
                project[propertyName] = newUrl;
                res();
              });
          })
          .catch(() => {
            rej();
          });
      });
    } else if (project[propertyName]) {
      const extension = extractExtension(project[propertyName]);
      const fileName = imageName + "-" + project.id + extension;

      project[propertyName] = "";

      return firebaseSingleton.deleteMedia(imageLocation, fileName);
    }
  };

  const saveProject = async () => {
    setUpdatingProject(true);

    const newProject = _.cloneDeep(projectState);

    newProject.id =
      newProject.id || firebaseSingleton.getNewDocumentId("project");
    newProject.Name = projectDetails.Name;
    newProject.PhysicalAddress = projectDetails.Address;
    newProject.StartDate = DateInputToDateTime(projectDetails.StartDate);
    newProject.EndDate = DateInputToDateTime(projectDetails.EndDate);
    newProject.isHQFBX = projectDetails.isHQFBX;

    const promiseList: Promise<void>[] = [];

    setProjectState(newProject);

    if (realWorldPreview && realWorldPreview.hasNewFile) {
      promiseList.push(
        handleMediaUpdate(
          newProject,
          realWorldPreview,
          "ProjectImages",
          "thumbnail",
          "RealWorldImageUrl",
        ),
      );
    }

    if (modelPreview && modelPreview.hasNewFile) {
      promiseList.push(
        handleMediaUpdate(
          newProject,
          modelPreview,
          "ProjectImages",
          "preview",
          "3DModelPreviewImageUrl",
        ),
      );
    }

    if (modelFbx && modelFbx.hasNewFile) {
      promiseList.push(
        handleMediaUpdate(
          newProject,
          modelFbx,
          "ProjectModels",
          "model",
          "Current3DModelLocation",
        ),
      );
    }

    Promise.all(promiseList).then(async () => {
      if (params.id) {
        await firebaseSingleton.updateProject(newProject).then(() => {
          setActiveStep(activeStep + 1);
          setSnackbarProps({
            open: true,
            icon: "check_circle",
            content: "Project record updated successfully.",
          });
        });
      } else {
        await firebaseSingleton
          .addProject(newProject, newProject.id)
          .then(async () => {
            await assignToCompany(newProject.id);

            setActiveStep(activeStep + 1);

            setSnackbarProps({
              open: true,
              icon: "check_circle",
              content: "Project record created successfully.",
            });
          });
      }

      setUpdatingProject(false);
    });
  };

  const assignToCompany = async (projectId: Project["id"]): Promise<void> => {
    const userRoleCompany = currentUserRole.AssociatedCompany;

    return await firebaseSingleton
      .getCompany(userRoleCompany.id)
      .then(async (result) => {
        if (result.status) {
          const company = result.data;

          company.Projects = _.sortBy(
            _.uniq([...(company.Projects || []), projectId]),
          );

          return await firebaseSingleton.updateCompany(company);
        }
      });
  };

  const handleNext = async () => {
    if (isSecondLastStep) {
      await saveProject();
    } else if (isLastStep) {
      navigate("/projects");
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/projects");
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const stepContent = getStepContent(
    isNewProject,
    activeStep,
    projectDetails,
    setProjectDetails,
    realWorldPreview,
    setRealWorldPreview,
    modelPreview,
    setModelPreview,
    modelFbx,
    setModelFbx,
    projectState?.id,
    idToken,
    t,
  );

  return (
    <VuiBox
      sx={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      mt={6}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              mx: "auto",
              width: { xs: "100%", lg: "50vw" },
              maxWidth: { xs: "100%", lg: "800px" },
            }}
          >
            {steps.map((label, labelIndex) => (
              <Step key={label}>
                <StepLabel>{`${labelIndex + 1}. ${label}`}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ overflow: "visible" }}>
            <VuiBox p={2}>
              <VuiBox>
                {activeStep == 0 ? (
                  <ConfigureProjectDetails
                    isNewProject={true}
                    projectDetailsState={projectDetails}
                    setProjectDetails={setProjectDetails}
                  />
                ) : activeStep == 1 ? (
                  <ConfigureProjectMedia
                    key="RealWorldImage"
                    title={t(
                      "modules.projects.section_titles.real_world_preview",
                    )}
                    label={"Upload Real World Preview Image"}
                    dropzoneContentState={[
                      realWorldPreview,
                      setRealWorldPreview,
                    ]}
                  />
                ) : activeStep == 2 ? (
                  <ConfigureProjectMedia
                    key="3DModelPreviewImage"
                    title={t(
                      "modules.projects.section_titles.3d_model_preview",
                    )}
                    label={"Upload 3D Model Preview Image"}
                    dropzoneContentState={[modelPreview, setModelPreview]}
                  />
                ) : activeStep == 3 ? (
                  <ConfigureProjectMedia
                    key="3DModelFBX"
                    title={t("modules.projects.section_titles.model_fbx")}
                    label={"Upload 3D Model FBX"}
                    dropzoneContentState={[modelFbx, setModelFbx]}
                  />
                ) : activeStep == 4 ? (
                  <ConfigureStartingPosition
                    modelFbx={modelFbx}
                    projectId={projectState?.id}
                    idToken={idToken}
                  />
                ) : null}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  mt={4}
                  width="100%"
                >
                  {!isLastStep && (
                    <VuiButton
                      variant="gradient"
                      color="light"
                      onClick={handleBack}
                      disabled={updatingProject}
                    >
                      {t("common.buttons.back")}
                    </VuiButton>
                  )}
                  {isLastStep && <Box flex={1} />}
                  <VuiButton
                    variant="contained"
                    color="info"
                    onClick={handleNext}
                    disabled={updatingProject}
                  >
                    {isSecondLastStep
                      ? t("common.buttons.save")
                      : isLastStep
                      ? t("common.buttons.done")
                      : t("common.buttons.next")}
                  </VuiButton>
                </Box>
              </VuiBox>
            </VuiBox>
          </Card>
        </Grid>
      </Grid>
    </VuiBox>
  );
};

export default ProjectWindow;

import React, { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash";
import { VuiColor as VuiColorOptionsType } from "@/Components/VuiBadgeDot";

export interface OptionalSnackbarPropsType {
  color?: VuiColorOptionsType;
  icon?: React.ReactElement | string;
  iconColor?: VuiColorOptionsType;
  title?: string;
  dateTime?: string;
  content: React.ReactElement | string;
  close?: () => void;
  open: boolean;
}

interface SnackbarPropsType {
  color: VuiColorOptionsType;
  icon: React.ReactElement | string;
  iconColor: VuiColorOptionsType;
  title: string;
  dateTime: string;
  content: React.ReactElement | string;
  close: () => void;
  open: boolean;
}

interface SnackbarContextType {
  snackbarProps: SnackbarPropsType;
  setSnackbarProps: (snackbarProps: OptionalSnackbarPropsType) => void;
  resetSnackbarProps: () => void;
}

const DEFAULT_SNACKBAR_PROPS: SnackbarPropsType = {
  color: "dark",
  icon: null,
  iconColor: "success",
  title: "",
  dateTime: "",
  content: "",
  close: () => {},
  open: false,
};

const SnackbarContext = createContext<SnackbarContextType>({
  snackbarProps: _.cloneDeep(DEFAULT_SNACKBAR_PROPS),
  setSnackbarProps: () => {},
  resetSnackbarProps: () => {},
});

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = (props) => {
  const [snackbarProps, setSnackbarProps] = useState<SnackbarPropsType>(
    _.cloneDeep(DEFAULT_SNACKBAR_PROPS),
  );

  const setSnackbarPropsHandler = (
    snackbarProps: OptionalSnackbarPropsType,
  ) => {
    setSnackbarProps({
      ..._.cloneDeep(DEFAULT_SNACKBAR_PROPS),
      ...snackbarProps,
    });
  };

  const resetSnackbarPropsHandler = () => {
    setSnackbarProps(_.cloneDeep(DEFAULT_SNACKBAR_PROPS));
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbarProps,
        setSnackbarProps: setSnackbarPropsHandler,
        resetSnackbarProps: resetSnackbarPropsHandler,
      }}
    >
      {props.children}
    </SnackbarContext.Provider>
  );
};

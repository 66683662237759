import React, { useState } from "react";

import { Grid, Icon } from "@mui/material";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";

import borders from "@/assets/theme/base/borders";
import colors from "@/assets/theme/base/colors";
import {
  ElectricalSwitch,
  Furniture,
  Light,
  Material,
} from "@/DatabaseControls/DataTypes";

const FixtureCard = <
  T extends Furniture | Light | Material | ElectricalSwitch,
>({
  fixtureId,
  fixtureType,
  name,
  color,
  fixtureData,
  onClick,
  onDelete,
  onEdit,
  customAction,
}: {
  fixtureId: T["id"];
  fixtureType: "Material" | "Light" | "Furniture" | "ElectricalSwitch";
  fixtureData: any[];
  name: string;
  color: string;
  onClick: () => void;
  deleteFixture?: (id?: T["id"]) => void;
  onDelete?: (id: T["id"]) => void;
  onEdit?: (id?: T["id"]) => void;
  customAction?: () => JSX.Element;
}) => {
  const { borderWidth } = borders;

  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
    event.stopPropagation();
  };
  const handleCloseMenu = () => setOpenMenu(null);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      onClick={(e) => e.stopPropagation()}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          onEdit(fixtureId);
        }}
        sx={{ color: ({ palette }) => palette.info.main }}
      >
        <Grid container spacing={0.5} alignItems="center">
          <Grid item>
            <Icon sx={{ display: "flex" }}>edit</Icon>
          </Grid>
          <Grid item>Edit</Grid>
        </Grid>
      </MenuItem>

      {onDelete && (
        <MenuItem
          onClick={() => onDelete(fixtureId)}
          sx={{ color: ({ palette }) => palette.error.light }}
        >
          <Grid container spacing={0.5} alignItems="center">
            <Grid item>
              <Icon sx={{ display: "flex" }}>delete</Icon>
            </Grid>
            <Grid item>Delete</Grid>
          </Grid>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <>
      <VuiBox
        component="li"
        width="100%"
        py={1}
        borderLeft={`${borderWidth[4]} solid ${colors[color].main}`}
        onClick={onClick}
        sx={{
          listStyle: "none",
          cursor: "pointer",
          "&:hover": {
            background: ({ palette }) => palette.secondary.main,
            transform: "translateY(-1px)",
            transition: "all 100ms ease-in-out",
          },
        }}
      >
        <VuiBox width="100%">
          <VuiBox
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            pl={0.5}
          >
            {fixtureData.map((data) => {
              return (
                <>
                  {data.type === "image" ? (
                    <VuiBox
                      display="flex"
                      alignItems="center"
                      sx={{ flex: 1.5 }}
                    >
                      {data.content ? (
                        <VuiBox
                          component="img"
                          ml={2.5}
                          mr={2}
                          src={data.content}
                          alt={name}
                          sx={({ borders: { borderRadius } }) => ({
                            width: "48px",
                            height: "48px",
                            borderRadius: borderRadius.lg,
                          })}
                        />
                      ) : (
                        <VuiBox
                          ml={2.5}
                          mr={2}
                          sx={({ borders: { borderRadius }, palette }) => ({
                            width: "48px",
                            height: "48px",
                            borderRadius: borderRadius.lg,
                            backgroundColor: palette.dark.main,
                          })}
                        />
                      )}

                      <VuiTypography
                        variant="button"
                        fontWeight="medium"
                        color="white"
                      >
                        {name}
                      </VuiTypography>
                    </VuiBox>
                  ) : (
                    <VuiBox
                      lineHeight={1}
                      sx={{ flex: 1 }}
                      mb={{ xs: 1, sm: 0 }}
                    >
                      <VuiTypography
                        display="block"
                        variant="caption"
                        fontWeight="regular"
                        color="white"
                      >
                        {data.title}
                      </VuiTypography>
                      <VuiTypography
                        variant="caption"
                        fontWeight="bold"
                        color="white"
                      >
                        {data.content}
                      </VuiTypography>
                    </VuiBox>
                  )}
                </>
              );
            })}

            {customAction ? (
              customAction()
            ) : (
              <VuiBox ml="auto" color="secondary" pr={3} lineHeight={0}>
                <Icon
                  fontSize="default"
                  sx={{ cursor: "pointer", color: "#fff" }}
                  onClick={handleOpenMenu}
                >
                  more_horiz
                </Icon>

                {renderMenu()}
              </VuiBox>
            )}
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <Divider light sx={{ my: 1 }} />
    </>
  );
};

export default FixtureCard;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import BasicLayout from "@/Components/BasicLayout";
import GradientBorder from "@/Components/GradientBorder";
import VuiBox from "@/Components/VuiBox";
import VuiButton from "@/Components/VuiButton";
import VuiInput from "@/Components/VuiInput";
import VuiTypography from "@/Components/VuiTypography";
import borders from "@/assets/theme/base/borders";
import radialGradient from "@/assets/theme/functions/radialGradient";

import bgBasic from "@/assets/background-basic-auth.png";
import palette from "@/assets/theme/base/colors";
import { Divider } from "@mui/material";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import _ from "lodash";

import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

import { useTranslation } from "react-i18next";

const ResetPasswordWindow = (props): JSX.Element => {
  const { setCurrentPage } = props;

  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>("");

  const handleResetPassword = async () => {
    setIsLoading(true);
    resetSnackbarProps();

    if (emailInput) {
      await firebaseSingleton.resetPassword(emailInput).then((res) => {
        if (res.status) {
          setSnackbarProps({
            open: true,
            icon: "mark_email_read",
            iconColor: "success",
            content: `A reset password email has been sent to ${_.upperFirst(
              emailInput,
            )}.`,
          });

          setCurrentPage(0);
        } else {
          setSnackbarProps({
            open: true,
            icon: "error",
            iconColor: "error",
            content: `Account with email address ${_.upperFirst(
              emailInput,
            )} is not created.`,
          });
        }
      });
    }

    setIsLoading(false);
  };

  const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value.trim());
  };

  return (
    <BasicLayout
      title={t("modules.auth.reset_password.title")}
      description={t("modules.auth.reset_password.subtitle")}
      image={bgBasic}
    >
      <GradientBorder
        borderRadius={borders.borderRadius.form}
        minWidth="100%"
        maxWidth="100%"
      >
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.main,
          })}
        >
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("modules.auth.fields.email")}
              </VuiTypography>
            </VuiBox>

            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <VuiInput
                type="email"
                placeholder={t("modules.auth.fields.email_placeholder")}
                value={emailInput}
                onChange={handleEmailInputChange}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>

          <VuiBox mt={4} mb={1}>
            <VuiButton
              fullWidth
              color="info"
              onClick={handleResetPassword}
              disabled={isLoading}
            >
              {t("modules.auth.buttons.reset_password")}
            </VuiButton>
          </VuiBox>
          <VuiBox mt={3} textAlign="center">
            <VuiBox>
              <VuiTypography
                component={Link}
                onClick={() => setCurrentPage(1)}
                variant="button"
                fontWeight="medium"
                sx={{
                  color: (theme) => theme.palette.text.main,

                  "&:hover": { color: (theme) => theme.palette.white.main },
                }}
              >
                {t("modules.auth.messages.create_new_account")}
              </VuiTypography>
            </VuiBox>

            <Divider sx={{ m: 1 }} light={true} />

            <VuiBox>
              <VuiTypography
                component={Link}
                onClick={() => setCurrentPage(0)}
                variant="button"
                fontWeight="medium"
                sx={{
                  color: (theme) => theme.palette.text.main,

                  "&:hover": { color: (theme) => theme.palette.white.main },
                }}
              >
                {t("modules.auth.messages.back_to_sign_in")}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </BasicLayout>
  );
};

export default ResetPasswordWindow;

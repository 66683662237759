import { CookieHelper } from "@/CookieHelper";
import { auth, firebaseClass } from "@/DatabaseControls/FirebaseController";
import {
  allUserRolesState,
  currentUserRoleState,
  currentUserState,
} from "@/states/auth";
import {
  User,
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
} from "firebase/auth";
import { useAtom, useSetAtom } from "jotai";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSnackbar } from "../Snackbar/SnackbarContext";
import useLocalStorage from "@/hooks/useLocalStorage";

interface AuthContextType {
  isLoading: boolean;
  // currentUser: User | null;
  firebaseSingleton: unknown;
}

const AuthContext = createContext<AuthContextType>({
  isLoading: true,
  // currentUser: null,
  firebaseSingleton: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = (props) => {
  const setCurrentUser = useSetAtom(currentUserState);
  const setAllUserRoles = useSetAtom(allUserRolesState);
  const setCurrentUserRole = useSetAtom(currentUserRoleState);

  const [isLoading, setIsLoading] = useState(true);

  const firebaseSingleton = new firebaseClass();

  const [userRoleIdCahce, setUserRoleIdCahce] = useLocalStorage("user_role_id");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      handleAuthChanged(user);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserRole = (userRoles) => {
    if (userRoles.length === 1) {
      setCurrentUserRole(userRoles[0]);
      setUserRoleIdCahce(userRoles[0].id);
    } else {
      const id = userRoleIdCahce;

      const userRole = userRoles.find((role) => {
        return role.id === id;
      });

      if (userRole) {
        setCurrentUserRole(userRole);
      }
    }
  };

  const handleAuthChanged = async (user) => {
    if (user) {
      firebaseSingleton.listenUser(
        user.uid,
        async (user) => {
          await firebaseSingleton
            .getJoinedUserRolesByUserId(user.id)
            .then(async (userRoles) => {
              setCurrentUser(user);
              setAllUserRoles(userRoles);
              handleUserRole(userRoles);
            });

          setIsLoading(false);
        },
        true,
      );
    } else {
      setCurrentUser(null);
      setCurrentUserRole(null);
      setAllUserRoles([]);
      setIsLoading(false);
      setUserRoleIdCahce();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        // currentUser,
        firebaseSingleton,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

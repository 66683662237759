import React, { useEffect, useState } from "react";

import { Divider, Icon, Card } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiInput from "@/Components/VuiInput";
import VuiButton from "@/Components/VuiButton";
import VuiTypography from "@/Components/VuiTypography";

import ModelBrandCard from "./ModelBrandCard";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { ModelBrand } from "@/DatabaseControls/DataTypes";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useSnackbar } from "@/context/Snackbar/SnackbarContext";
import _ from "lodash";

async function getDatabaseList(
  setModelBrandList: (modelBrandList: ModelBrand[]) => void,
) {
  const modelBrandList: Map<string, ModelBrand> =
    await firebaseSingleton.getDatabaseList("modelBrand");
  setModelBrandList(
    Array.from(modelBrandList, ([_, modelBrand]) => modelBrand),
  );
}

function applySearch(searchTerm: string, listData: ModelBrand[]) {
  return listData.filter((modelBrand: ModelBrand) => {
    return _.lowerCase(modelBrand.Name).includes(_.lowerCase(searchTerm));
  });
}

const ModelBrandIndexWindow = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchPlaceholder, setSearchPlaceholder] = useState("");
  const handleSetSearchTerm = (e) => setSearchTerm(e.target.value);
  const [hasRetrievedData, setHasRetrievedData] = useState(false);
  const [modelBrandList, setModelBrandList] = useState<ModelBrand[]>([]);

  const navigate = useNavigate();
  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const refreshData = () => getDatabaseList(setModelBrandList);

  const navigateToModelBrandWindow = () => {
    navigate(`/model-brands/new`, {});
  };

  const deleteModelBrand = async (id) => {
    resetSnackbarProps();

    await firebaseSingleton.deleteModelBrand(id).then(async () => {
      refreshData();

      setSnackbarProps({
        open: true,
        icon: "check_circle",
        content: "Model brand record deleted successfully.",
      });
    });
  };

  if (!hasRetrievedData) {
    refreshData();
    setHasRetrievedData(true);
  }

  useEffect(() => {
    setSearchPlaceholder(t("common.messages.search"));
  }, [t]);

  return (
    <VuiBox mt={6}>
      <VuiBox>
        <Card>
          <VuiBox p={3}>
            <VuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <VuiTypography variant="lg" fontWeight="bold" color="white">
                {t("modules.model_brands.section_titles.list")}
              </VuiTypography>
              <VuiBox display="flex" justifyContent="space-between">
                <VuiBox pr={2} maxWidth={{ sm: "175px", md: "100%" }}>
                  <VuiInput
                    placeholder={searchPlaceholder}
                    onChange={handleSetSearchTerm}
                    icon={{ component: "search", direction: "left" }}
                    sx={({ breakpoints }) => ({
                      [breakpoints.down("sm")]: {
                        maxWidth: "80px",
                      },
                      [breakpoints.only("sm")]: {
                        maxWidth: "80px",
                      },
                      backgroundColor: "info.main !important",
                    })}
                  />
                </VuiBox>
                <VuiButton
                  onClick={navigateToModelBrandWindow}
                  variant="contained"
                  color="info"
                  sx={{ minWidth: "48px", minHeight: "36px", padding: 0 }}
                >
                  <Icon>add</Icon>
                </VuiButton>
              </VuiBox>
            </VuiBox>
            <Divider />
            <VuiBox>
              <VuiBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
              >
                {applySearch(searchTerm, modelBrandList).map(
                  (modelBrand: ModelBrand, modelBrandIndex: number) => {
                    return (
                      <ModelBrandCard
                        key={modelBrand.id}
                        modelBrand={modelBrand}
                        color="success"
                        onClick={() =>
                          navigate(`/model-brands/${modelBrand.id}`, {
                            state: {
                              modelBrand,
                            },
                          })
                        }
                        deleteModelBrand={deleteModelBrand}
                        noDivider={
                          modelBrandIndex === modelBrandList.length - 1
                        }
                      />
                    );
                  },
                )}
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </Card>
      </VuiBox>
    </VuiBox>
  );
};

export default ModelBrandIndexWindow;

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSetAtom } from "jotai";
import {
  electricalSwitchModalState,
  furnituresModalState,
  lightsModalState,
  materialsModalState,
} from "@/states/catalogs/modals";

import { Card, Icon, IconButton, Grid } from "@mui/material";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiButton from "@/Components/VuiButton";
import VuiFormField from "@/Components/VuiFormField";
import VuiInput from "@/Components/VuiInput";

import {
  Catalog,
  Furniture,
  ElectricalSwitch,
  FurnitureTypeMapper,
  Light,
  Material,
} from "@/DatabaseControls/DataTypes";

import FixtureCard from "./FixtureCard";
import ProjectMedia, {
  DropzoneContent,
  convertUrlToBlobUrl,
  extractExtension,
} from "../ProjectManagement/ProjectMedia";

import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";
import { useSnackbar } from "@/context/Snackbar/SnackbarContext";

import _ from "lodash";

const CatalogItemList = <
  T extends Furniture | Light | Material | ElectricalSwitch,
>({
  title,
  onItemAdd,
  onItemClick,
  onItemDelete,
  dataList,
  dataType,
  searchFields,
}: {
  title: string;
  onItemAdd: () => void;
  onItemClick: (item: T) => void;
  onItemDelete: (id: T["id"]) => void;
  dataList: T[];
  dataType: "Material" | "Light" | "Furniture" | "ElectricalSwitch";
  searchFields?: string[];
}) => {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>("");

  const [displayDataList, setDisplayDataList] = useState<T[]>([]);

  const handleSearch = useMemo(() => {
    return _.debounce((value) => {
      const isSearched = (input, searchString) => {
        if (input && searchString) {
          return input
            .toString()
            .toLowerCase()
            .includes(searchString.toLowerCase());
        } else {
          return false;
        }
      };

      setDisplayDataList(
        value
          ? dataList.filter((data) => {
              return searchFields.some((f) => {
                return isSearched(data[f], value);
              });
            })
          : dataList,
      );
    }, 500);
  }, [dataList, searchFields]);

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      handleSearch(searchInput);
      handleSearch.flush();
    }
  };

  const handleOnSearch = (e) => {
    const { value }: { value: string } = e.target;
    setSearchInput(value);

    handleSearch(value);
  };

  useEffect(() => {
    setDisplayDataList(dataList);
  }, [dataList]);

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  return (
    <Card>
      <VuiBox
        mb={2.5}
        lineHeight={0}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <VuiTypography variant="h5" color="white" fontWeight="bold">
          {title}
        </VuiTypography>

        <VuiBox display="flex" justifyContent="space-between" gap={2}>
          {searchFields && searchFields.length > 0 && (
            <VuiBox maxWidth={{ sm: "175px", md: "100%" }}>
              <VuiInput
                placeholder={t("common.messages.search")}
                value={searchInput}
                onChange={handleOnSearch}
                onKeyDown={handleSearchEnter}
                icon={{
                  component: "search",
                  direction: "left",
                }}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("sm")]: {
                    maxWidth: "80px",
                  },
                  [breakpoints.only("sm")]: {
                    maxWidth: "80px",
                  },
                })}
              />
            </VuiBox>
          )}

          <VuiBox display="flex" gap={1}>
            <VuiButton
              variant="contained"
              color="success"
              size="small"
              sx={{ p: 0 }}
              onClick={onItemAdd}
            >
              {t("common.buttons.add")}
            </VuiButton>

            <IconButton color="white" onClick={() => setIsShow(!isShow)}>
              <Icon>{isShow ? "expand_more" : "expand_less"}</Icon>
            </IconButton>
          </VuiBox>
        </VuiBox>
      </VuiBox>

      <VuiBox
        sx={{
          maxHeight: isShow ? 1000 : 350,
          transition: "max-height 0.5s ease",
          overflow: "auto",
        }}
      >
        <Grid container flexDirection="column" spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={12} sx={{ overflow: "auto" }}>
              {displayDataList.length > 0 ? (
                displayDataList.map((item: T, itemIndex: number) => {
                  let fixtureData = [];

                  switch (dataType) {
                    case "Furniture":
                    case "ElectricalSwitch":
                    case "Light":
                      fixtureData = [
                        {
                          title: "",
                          //@ts-expect-error
                          content: item.ThumbnailURL,
                          type: "image",
                        },
                        {
                          title: t("modules.furnitures.data.variant"),
                          //@ts-expect-error
                          content: item.Variant,
                          type: "text",
                        },
                        {
                          title: t("modules.furnitures.data.furniture_type"),
                          //@ts-expect-error
                          content: item.FurnitureType
                            ? //@ts-expect-error
                              FurnitureTypeMapper[item.FurnitureType]
                            : "Electrical",
                          type: "text",
                        },
                        {
                          title: t(
                            `modules.${
                              dataType === "Furniture" ? "furnitures" : "lights"
                            }.data.id`,
                          ),
                          content: item.id,
                          type: "text",
                        },
                      ];
                      break;
                    case "Material":
                      fixtureData = [
                        {
                          title: "",
                          //@ts-expect-error
                          content: item.TextureURL,
                          type: "image",
                        },

                        {
                          title: t("modules.materials.data.cost"),
                          content: item.Cost,
                          type: "text",
                        },
                        {
                          title: t("modules.materials.data.brand_id"),
                          content: item.Brand || "-",
                          type: "text",
                        },
                        {
                          title: t("modules.materials.data.id"),
                          content: item.id,
                          type: "text",
                        },
                      ];

                      break;
                  }

                  return (
                    <FixtureCard<T>
                      key={item.id}
                      fixtureId={item.id}
                      fixtureType={dataType}
                      color="success"
                      fixtureData={fixtureData}
                      onDelete={(id) => onItemDelete(id)}
                      onClick={() => onItemClick(item)}
                      onEdit={() => onItemClick(item)}
                      name={item.Name}
                    />
                  );
                })
              ) : (
                <VuiTypography variant="body">
                  {t("common.messages.no_item_added")}
                </VuiTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
};

const ImageField = ({
  src,
  label,
  onSave,
  disabled,
}: {
  src: string;
  label?: string;
  onSave: (contentState: DropzoneContent) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(!!!src);
  const [isLoading, setIsLoading] = useState(false);

  const [contentState, setContentState] = useState<DropzoneContent>();

  useEffect(() => {
    convertUrlToBlobUrl(src, setContentState);
  }, [src]);

  const isInput = !!!src || isEditing;

  const handleSave = async () => {
    setIsLoading(true);

    await onSave(contentState);

    setIsLoading(false);
    setIsEditing(false);
  };

  return (
    <VuiBox>
      <VuiBox mb={1} ml={0.5}>
        <VuiTypography
          component="label"
          variant="caption"
          sx={{
            color: ({ palette }) =>
              disabled ? palette.grey[400] : palette.white.main,
          }}
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label || "Image"}
        </VuiTypography>
      </VuiBox>

      <VuiBox border={1} p={1.5} borderRadius="xl">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          minHeight={200}
        >
          {isInput ? (
            <Grid
              item
              xs={12}
              sx={{
                ...(disabled ? { opacity: 0.5, pointerEvents: "none" } : {}),
              }}
            >
              <ProjectMedia
                title={""}
                label={""}
                dropzoneContentState={[contentState, setContentState]}
                noMargin
              />
            </Grid>
          ) : (
            <Grid item xs={8}>
              <VuiBox
                component="img"
                src={src}
                alt="Product Image"
                borderRadius="lg"
                shadow="lg"
                width="100%"
              />
            </Grid>
          )}
        </Grid>

        <VuiBox display="flex" mt={2}>
          <VuiButton
            variant="contained"
            color={disabled ? "dark" : isInput ? "warning" : "info"}
            size="small"
            sx={{ mr: "8px" }}
            onClick={isInput ? handleSave : () => setIsEditing(true)}
            disabled={isLoading || disabled}
          >
            {isInput ? t("common.buttons.save") : t("common.buttons.edit")}
          </VuiButton>

          {isInput && (
            <VuiButton
              variant="contained"
              color="dark"
              size="small"
              onClick={() => setIsEditing(false)}
              disabled={isLoading || disabled}
            >
              {t("common.buttons.cancel")}
            </VuiButton>
          )}
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
};

const CatalogInfo = ({
  catalogState,
  setCatalogState,
  furnitureList,
  electricalSwitchList,
  lightList,
  wallMaterialList,
  floorMaterialList,
  constructionMaterialList,
  otherMaterialList,
  isEdit,
  isLoading,
  setIsLoading,
}: {
  catalogState: Catalog;
  setCatalogState: (catalog: Catalog) => void;
  furnitureList: Furniture[];
  electricalSwitchList: ElectricalSwitch[];
  lightList: Light[];
  wallMaterialList: Material[];
  floorMaterialList: Material[];
  constructionMaterialList: Material[];
  otherMaterialList: Material[];
  isEdit: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) => {
  const setFurnituresModal = useSetAtom(furnituresModalState);
  const setLightsModal = useSetAtom(lightsModalState);
  const setMaterialsModal = useSetAtom(materialsModalState);
  const setElectricalSwitchModal = useSetAtom(electricalSwitchModalState);
  const { setSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const newCatalogState = _.cloneDeep(catalogState);

    newCatalogState[name] = value;

    setCatalogState(newCatalogState);
  };

  const handleTagInputChange = (
    items: { label: string; value: string }[],
    detail,
  ) => {
    const { name } = detail;
    const newCatalogState = _.cloneDeep(catalogState);

    newCatalogState[name] = items.map(({ label, value }) => value);

    setCatalogState(newCatalogState);
  };

  const handleModal = (modalSetter: (state: any) => void, props = {}) => {
    modalSetter((currentState) => {
      return { ...currentState, isShow: !currentState.isShow, props: props };
    });
  };

  const handleImageSave = async (
    dropzoneContent: DropzoneContent,
  ): Promise<void> => {
    setIsLoading(true);

    if (dropzoneContent.file) {
      const extension = extractExtension(dropzoneContent?.file?.name);
      const imageName = "previewImage";
      const fileName = `${imageName}-${catalogState.id}${extension}`;

      return await firebaseSingleton
        .uploadMedia("CatalogImage", fileName, dropzoneContent.file)
        .then(async () => {
          await firebaseSingleton
            .getMediaUrl("CatalogImage", fileName)
            .then(async (newUrl: string) => {
              await firebaseSingleton
                .updateCatalog({ ...catalogState, PreviewImageURL: newUrl })

                .then(() => {
                  const newCatalogState = _.cloneDeep(catalogState);
                  newCatalogState.PreviewImageURL = newUrl;

                  setCatalogState(newCatalogState);
                  setIsLoading(false);
                });
            });
        });
    } else if (catalogState.PreviewImageURL) {
      const extension = extractExtension(catalogState.PreviewImageURL);
      const imageName = "previewImage";
      const fileName = `${imageName}-${catalogState.id}${extension}`;

      return await firebaseSingleton
        .deleteMedia("CatalogImage", fileName)
        .then(async () => {
          await firebaseSingleton
            .updateCatalog({ ...catalogState, PreviewImageURL: "" })
            .then(() => {
              const newCatalogState = _.cloneDeep(catalogState);

              newCatalogState.PreviewImageURL = "";
              setCatalogState(newCatalogState);

              setIsLoading(false);
            });
        });
    }
  };

  const handleRemoveCatalogItem = async (
    name:
      | "Furnitures"
      | "ElectricalSwitches"
      | "Lights"
      | "WallMaterials"
      | "FloorMaterials"
      | "ConstructionMaterials"
      | "OtherMaterials",
    id: Furniture["id"] | ElectricalSwitch["id"] | Light["id"],
  ): Promise<void> => {
    if (id) {
      const method =
        name === "Furnitures"
          ? firebaseSingleton.deleteFurniture
          : name === "Lights"
          ? firebaseSingleton.deleteLight
          : name === "ElectricalSwitches"
          ? firebaseSingleton.deleteElectricalSwitch
          : firebaseSingleton.deleteMaterial;

      let parameters = [id, catalogState.id];

      if (name.match("Materials")) {
        parameters = [id, name.replace("Materials", ""), catalogState.id];
      }

      await method.apply(firebaseSingleton, parameters).then(() => {
        setSnackbarProps({
          open: true,
          icon: "check_circle",
          content: `${name} record deleted successfully.`,
        });
        const newCatalogState = _.cloneDeep(catalogState);
        newCatalogState[name] = newCatalogState[name].filter((i) => i !== id);

        setCatalogState(newCatalogState);
      });
    }
  };

  return (
    <>
      <Grid container direction="column" gap={4} wrap="nowrap">
        <Grid item xs={12}>
          <Card sx={{ overflow: "visible" }}>
            <VuiBox
              mb={2.5}
              lineHeight={0}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <VuiTypography variant="h5" color="white" fontWeight="bold">
                {t("modules.catalogs.section_titles.information")}
              </VuiTypography>
            </VuiBox>

            <VuiBox>
              <Grid container flexDirection="column" spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <VuiFormField
                      required
                      type="text"
                      name="Name"
                      label={t("modules.catalogs.data.name")}
                      value={catalogState.Name}
                      onChange={handleInputChange}
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <VuiFormField
                      required
                      type="text"
                      name="Theme"
                      label={t("modules.catalogs.data.theme")}
                      value={catalogState.Theme}
                      onChange={handleInputChange}
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <VuiFormField
                      required
                      select
                      isMulti
                      freesolo
                      type="text"
                      name="Tags"
                      label={t("modules.catalogs.data.tags")}
                      value={catalogState.Tags.map((tag) => {
                        return { label: tag, value: tag };
                      })}
                      onChange={handleTagInputChange}
                      disabled={isLoading}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <ImageField
                      label={t("modules.catalogs.data.image")}
                      src={catalogState.PreviewImageURL}
                      onSave={handleImageSave}
                      disabled={isLoading || !isEdit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </VuiBox>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <CatalogItemList<Furniture>
            title={t("modules.furnitures.title")}
            onItemAdd={() => handleModal(setFurnituresModal)}
            onItemClick={(furniture: Furniture) =>
              handleModal(setFurnituresModal, {
                selectedFurniture: furniture,
              })
            }
            onItemDelete={(id: Furniture["id"]) =>
              handleRemoveCatalogItem("Furnitures", id)
            }
            dataList={furnitureList}
            dataType="Furniture"
            searchFields={["Name", "Brand", "id"]}
          />
        </Grid>

        <Grid item xs={12}>
          <CatalogItemList<ElectricalSwitch>
            title={t("modules.catalogs.section_titles.switch")}
            onItemAdd={() => handleModal(setElectricalSwitchModal)}
            onItemClick={(electricalSwitch: ElectricalSwitch) =>
              handleModal(setElectricalSwitchModal, {
                selectedElectricalSwitch: electricalSwitch,
              })
            }
            onItemDelete={(id: ElectricalSwitch["id"]) =>
              handleRemoveCatalogItem("ElectricalSwitches", id)
            }
            dataList={electricalSwitchList}
            dataType="ElectricalSwitch"
            searchFields={["Name", "Brand", "id"]}
          />
        </Grid>

        <Grid item xs={12}>
          <CatalogItemList<Light>
            title={t("modules.catalogs.section_titles.lights")}
            onItemAdd={() => handleModal(setLightsModal)}
            onItemClick={(light: Light) =>
              handleModal(setLightsModal, {
                selectedLight: light,
              })
            }
            onItemDelete={(id: Light["id"]) =>
              handleRemoveCatalogItem("Lights", id)
            }
            dataList={lightList}
            dataType="Light"
            searchFields={["Name", "Brand", "id"]}
          />
        </Grid>

        <Grid item xs={12}>
          <CatalogItemList<Material>
            title={t("modules.catalogs.section_titles.wall_materials")}
            onItemAdd={() => handleModal(setMaterialsModal, { type: "Wall" })}
            onItemClick={(material: Material) =>
              handleModal(setMaterialsModal, {
                selectedMaterial: material,
                type: "Wall",
              })
            }
            onItemDelete={(id: Material["id"]) =>
              handleRemoveCatalogItem("WallMaterials", id)
            }
            dataList={wallMaterialList}
            dataType="Material"
            searchFields={["Name", "Brand", "id"]}
          />
        </Grid>

        <Grid item xs={12}>
          <CatalogItemList<Material>
            title={t("modules.catalogs.section_titles.floor_materials")}
            onItemAdd={() => handleModal(setMaterialsModal, { type: "Floor" })}
            onItemClick={(material: Material) =>
              handleModal(setMaterialsModal, {
                selectedMaterial: material,
                type: "Floor",
              })
            }
            onItemDelete={(id: Material["id"]) =>
              handleRemoveCatalogItem("FloorMaterials", id)
            }
            dataList={floorMaterialList}
            dataType="Material"
            searchFields={["Name", "Brand", "id"]}
          />
        </Grid>

        <Grid item xs={12}>
          <CatalogItemList<Material>
            title={t("modules.catalogs.section_titles.construction_materials")}
            onItemAdd={() =>
              handleModal(setMaterialsModal, { type: "Construction" })
            }
            onItemClick={(material: Material) =>
              handleModal(setMaterialsModal, {
                selectedMaterial: material,
                type: "Construction",
              })
            }
            onItemDelete={(id: Material["id"]) =>
              handleRemoveCatalogItem("ConstructionMaterials", id)
            }
            dataList={constructionMaterialList}
            dataType="Material"
            searchFields={["Name", "Brand", "id"]}
          />
        </Grid>

        <Grid item xs={12}>
          <CatalogItemList<Material>
            title={t("modules.catalogs.section_titles.other_materials")}
            onItemAdd={() => handleModal(setMaterialsModal, { type: "Other" })}
            onItemClick={(material: Material) =>
              handleModal(setMaterialsModal, {
                selectedMaterial: material,
                type: "Other",
              })
            }
            onItemDelete={(id: Material["id"]) =>
              handleRemoveCatalogItem("OtherMaterials", id)
            }
            dataList={otherMaterialList}
            dataType="Material"
            searchFields={["Name", "Brand", "id"]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CatalogInfo;

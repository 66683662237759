import "@/App.css";

import React, { useState } from "react";
import { firebaseSingleton } from "@/DatabaseControls/FirebaseController";

import { Link } from "react-router-dom";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiInput from "@/Components/VuiInput";
import VuiButton from "@/Components/VuiButton";

import GradientBorder from "@/Components/GradientBorder";

import BasicLayout from "@/Components/BasicLayout";

import radialGradient from "@/assets/theme/functions/radialGradient";
import palette from "@/assets/theme/base/colors";
import borders from "@/assets/theme/base/borders";

import bgBasic from "@/assets/background-basic-auth.png";
import {
  OptionalSnackbarPropsType,
  useSnackbar,
} from "@/context/Snackbar/SnackbarContext";

import { useTranslation } from "react-i18next";

type SignUpState = {
  email: string;
  pass: string;
  displayName: string;
};

type SignUpError = {
  hasError: boolean;
  error: string;
};

export async function signUp(
  { email, pass, displayName }: SignUpState,
  setSignUpError: (err: SignUpError) => void,
  setSnackbarProps: (snackbarProps: OptionalSnackbarPropsType) => void,
  resetSnackbarProps: () => void,
) {
  resetSnackbarProps();
  setSignUpError({
    hasError: false,
    error: "",
  });

  if (!email) {
    setSignUpError({
      hasError: true,
      error: "",
    });

    setSnackbarProps({
      open: true,
      icon: "report",
      iconColor: "error",
      content: "Sign up failed! Please enter a valid email address.",
    });
    return;
  } else if (!pass || pass.length < 8) {
    setSignUpError({
      hasError: true,
      error: "",
    });

    setSnackbarProps({
      open: true,
      icon: "report",
      iconColor: "error",
      content:
        "Sign up failed! Your password must be at least 8 characters in length.",
    });
    return;
  } else if (!displayName) {
    setSignUpError({
      hasError: true,
      error: "Sign up failed. Please enter a display name.",
    });
    return;
  }

  return await firebaseSingleton
    .signUp({ email, pass, displayName })
    .then((credentials) => {
      if (credentials) {
        setSignUpError({ hasError: false, error: "" });
        resetSnackbarProps();

        setSnackbarProps({
          open: true,
          icon: "notifications",
          iconColor: "info",
          content: `Account created successfully! Welcome, ${displayName}`,
        });
      } else {
        throw new Error();
      }
    })
    .catch(() => {
      setSnackbarProps({
        open: true,
        icon: "report",
        iconColor: "error",
        content: `Sign up failed! Do you already have an account?`,
      });

      setSignUpError({
        hasError: true,
        error: "",
      });
    });
}

export default function SignUpWindow(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [signUpState, setSignUpState] = useState<SignUpState>({
    email: "",
    pass: "",
    displayName: "",
  });
  const [signUpError, setSignUpError] = useState<SignUpError>({
    hasError: false,
    error: "",
  });

  const { setSnackbarProps, resetSnackbarProps } = useSnackbar();
  const { t } = useTranslation();

  const handleSetEmail = (e) => {
    const { email, ...rest } = signUpState;
    setSignUpState({ email: e.target.value, ...rest });
  };

  const handleSetPass = (e) => {
    const { pass, ...rest } = signUpState;
    setSignUpState({ pass: e.target.value, ...rest });
  };

  const handleSetDisplayName = (e) => {
    const { displayName, ...rest } = signUpState;
    setSignUpState({ displayName: e.target.value, ...rest });
  };

  const handleSignUp = async () => {
    setIsLoading(true);

    await signUp(
      signUpState,
      setSignUpError,
      setSnackbarProps,
      resetSnackbarProps,
    );

    setIsLoading(false);
  };

  const navigateToSignIn = () => {
    props.setCurrentPage(0);
  };

  return (
    <BasicLayout
      title={t("modules.auth.sign_up.title")}
      description={t("modules.auth.sign_up.subtitle")}
      image={bgBasic}
    >
      <GradientBorder
        borderRadius={borders.borderRadius.form}
        minWidth="100%"
        maxWidth="100%"
      >
        <VuiBox
          component="form"
          role="form"
          borderRadius="inherit"
          p="45px"
          sx={({ palette: { secondary } }) => ({
            backgroundColor: secondary.main,
          })}
        >
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("modules.auth.fields.email")}
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <VuiInput
                type="email"
                placeholder={t("modules.auth.fields.email_placeholder")}
                onChange={handleSetEmail}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("modules.auth.fields.password")}
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              borderRadius={borders.borderRadius.lg}
              padding="1px"
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <VuiInput
                type="password"
                placeholder={t("modules.auth.fields.password_placeholder")}
                onChange={handleSetPass}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
                error={signUpError.hasError}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mb={2}>
            <VuiBox mb={1} ml={0.5}>
              <VuiTypography
                component="label"
                variant="button"
                color="white"
                fontWeight="medium"
              >
                {t("modules.auth.fields.display_name")}
              </VuiTypography>
            </VuiBox>
            <GradientBorder
              minWidth="100%"
              padding="1px"
              borderRadius={borders.borderRadius.lg}
              backgroundImage={radialGradient(
                palette.gradients.borderLight.main,
                palette.gradients.borderLight.state,
                palette.gradients.borderLight.angle,
              )}
            >
              <VuiInput
                type="text"
                placeholder={t("modules.auth.fields.display_name_placeholder")}
                onChange={handleSetDisplayName}
                sx={({ typography: { size } }) => ({
                  fontSize: size.sm,
                })}
              />
            </GradientBorder>
          </VuiBox>
          <VuiBox mt={4} mb={1}>
            <VuiButton
              fullWidth
              color="info"
              onClick={handleSignUp}
              disabled={isLoading}
            >
              {t("modules.auth.buttons.sign_up")}
            </VuiButton>
          </VuiBox>
          <VuiBox mt={3} textAlign="center">
            <VuiTypography variant="button" color="text" fontWeight="regular">
              {t("modules.auth.messages.have_account")}{" "}
              <VuiTypography
                component={Link}
                onClick={navigateToSignIn}
                variant="button"
                fontWeight="medium"
                sx={{
                  color: (theme) => theme.palette.light.main,

                  "&:hover": {
                    color: (theme) => theme.palette.white.main,
                  },
                }}
              >
                {t("modules.auth.buttons.sign_in")}
              </VuiTypography>
            </VuiTypography>
          </VuiBox>
        </VuiBox>
      </GradientBorder>
    </BasicLayout>
  );
}

import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CheckBox from "@mui/material/Checkbox";

import VuiBox from "@/Components/VuiBox";
import VuiTypography from "@/Components/VuiTypography";
import VuiFormField from "@/Components/VuiFormField";

import {
  Light,
  LightType,
  LightShape,
  LightMount,
  PowerType,
  LampType,
} from "@/DatabaseControls/DataTypes";

import { useTranslation } from "react-i18next";
import VuiSelect from "@/Components/VuiSelect";

import { SketchPicker } from "react-color";

const LightProperties = ({
  light,
  setLightState,
}: {
  light: Light;
  setLightState: (x: Light) => void;
}) => {
  const { t } = useTranslation();

  const [showColor, setShowColor] = useState(
    light.LightProperties?.ColorHexadecimal,
  );
  const [showColorPicker, setShowColorPicker] = useState(false);

  // const [customCheck, setCustomCheck] = useState(
  //   light.LightProperties?.isCustom
  // );

  useEffect(() => {
    setShowColor(light.LightProperties?.ColorHexadecimal);
  }, [light.LightProperties?.ColorHexadecimal]);

  const handleSetColorHexadecimal = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        ColorHexadecimal: e.hex,
      },
    });
  };

  const handleSetLightShape = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Shape: e.value,
      },
    });
  };

  const handleSetLightType = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Type: e.value,
      },
    });
  };

  const handleSetLightMount = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Mount: e.value,
      },
    });
  };

  const handleSetPowerType = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        PowerType: e.value,
      },
    });
  };

  const handleSetColorType = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        ColorType: e.value,
      },
    });
  };

  const handleSetColorTemperature = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        ColorTemperature: validateNumber(e.target.value),
      },
    });
  };

  const handleSetWhiteBalance = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        WhiteBalance: validateNumber(e.target.value),
      },
    });
  };

  const handleSetWattage = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Wattage: validateNumber(e.target.value),
      },
    });
  };

  const handleSetEfficiency = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Efficiency: validateNumber(e.target.value),
      },
    });
  };

  const handleSetLumens = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Lumens: validateNumber(e.target.value),
      },
    });
  };

  const handleSetRange = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Range: validateNumber(e.target.value),
      },
    });
  };
  const handleSetConeAngle = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        ConeAngle: validateNumber(e.target.value),
      },
    });
  };

  const handleSetRadius = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        Radius: validateNumber(e.target.value),
      },
    });
  };

  const handleSetBoxX = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        BoxX: validateNumber(e.target.value),
      },
    });
  };

  const handleSetBoxY = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        BoxY: validateNumber(e.target.value),
      },
    });
  };

  const handleSetSourceDistance = (e) => {
    const { ...rest } = light;
    setLightState({
      ...rest,
      LightProperties: {
        ...rest.LightProperties,
        SourceDistance: validateNumber(e.target.value),
      },
    });
  };

  const validateNumber = (value) => {
    const val = value;
    const regex = /^\d*$/;
    if (regex.test(val)) {
      if (val === "") {
        return 0;
      } else {
        const num = parseInt(val, 10);
        return num;
      }
    } else {
      if (val.length <= 1) {
        return 0;
      } else {
        return val.slice(0, -1);
      }
    }
  };
  const handleToggleClose = (setCallback) => {
    setCallback(false);
  };

  const handleToggleColorPicker = (setCallback, isShowColorPicker) => {
    setCallback(!isShowColorPicker);
  };

  const handleToggleColorChange = (setCallback, newColor) => {
    setCallback(newColor);
  };
  const handleToggleSwitch = (event, setCallback) => {
    setCallback(event);
  };

  const lightShapeArr = Object.entries(LightShape);
  const shapeOptions = lightShapeArr.map((data) => ({
    label: data[0],
    value: data[1],
  }));

  const lightTypeArr = Object.entries(LightType);
  const typeOptions = lightTypeArr.map((data) => ({
    label: data[0],
    value: data[1],
  }));

  const lightMountArr = Object.entries(LightMount);
  const mountOptions = lightMountArr.map((data) => ({
    label: data[0],
    value: data[1],
  }));

  const lightPowerArr = Object.entries(PowerType);
  const powerOptions = lightPowerArr.map((data) => ({
    label: data[0],
    value: data[1],
  }));

  const colorTypeArr = Object.entries(LampType);
  const colorTypeOptions = colorTypeArr.map((data) => ({
    label: data[0],
    value: data[1],
  }));

  return (
    <Card sx={{ overflow: "visible" }}>
      <VuiBox>
        <VuiTypography mb={1.5} variant="h5" color="white">
          Light Properties
        </VuiTypography>
        <VuiBox mt={1}>
          <VuiTypography
            color="white"
            component="label"
            variant="h7"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {t("modules.lights.properties.color_hexadecimal")}
          </VuiTypography>
          <div
            style={{
              backgroundColor: light.LightProperties?.ColorHexadecimal,
              width: "36px",
              height: "36px",
            }}
            onClick={() =>
              handleToggleColorPicker(setShowColorPicker, showColorPicker)
            }
          />
          {showColorPicker ? (
            <div style={{ position: "absolute", zIndex: "2" }}>
              <div
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={() => handleToggleClose(setShowColorPicker)}
              />
              <SketchPicker
                color={light.LightProperties?.ColorHexadecimal}
                onChangeComplete={handleSetColorHexadecimal}
              />
            </div>
          ) : null}
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.lights.properties.light_shape")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              shapeOptions.find(
                (shape) => shape.value === light.LightProperties?.Shape,
              ) || null
            }
            onChange={handleSetLightShape}
            options={shapeOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.lights.properties.light_type")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              typeOptions.find(
                (type) => type.value === light.LightProperties?.Type,
              ) || null
            }
            onChange={handleSetLightType}
            options={typeOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.lights.properties.light_mount")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              mountOptions.find(
                (mount) => mount.value === light.LightProperties?.Mount,
              ) || null
            }
            onChange={handleSetLightMount}
            options={mountOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.lights.properties.power_type")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              powerOptions.find(
                (powerType) =>
                  powerType.value === light.LightProperties?.PowerType,
              ) || null
            }
            onChange={handleSetPowerType}
            options={powerOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <VuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
              color="white"
            >
              {t("modules.lights.properties.color_type")}
            </VuiTypography>
          </VuiBox>
          <VuiSelect
            defaultValue={
              colorTypeOptions.find(
                (colorType) =>
                  colorType.value === light.LightProperties?.ColorType,
              ) || null
            }
            onChange={handleSetColorType}
            options={colorTypeOptions}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.lumens")}
            value={light.LightProperties?.Lumens}
            onChange={handleSetLumens}
          />
        </VuiBox>
        {light.LightProperties?.ColorType !== 0 ? (
          <VuiBox mt={1}>
            <VuiFormField
              mt={1}
              type="text"
              label={t("modules.lights.properties.color_temperature")}
              value={light.LightProperties?.ColorTemperature}
              onChange={handleSetColorTemperature}
            />
          </VuiBox>
        ) : null}
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.white_balance")}
            value={light.LightProperties?.WhiteBalance}
            onChange={handleSetWhiteBalance}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.wattage")}
            value={light.LightProperties?.Wattage}
            onChange={handleSetWattage}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.efficiency")}
            value={light.LightProperties?.Efficiency}
            onChange={handleSetEfficiency}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.range")}
            value={light.LightProperties?.Range}
            onChange={handleSetRange}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.cone_angle")}
            value={light.LightProperties?.ConeAngle}
            onChange={handleSetConeAngle}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.radius")}
            value={light.LightProperties?.Radius}
            onChange={handleSetRadius}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.box_x")}
            value={light.LightProperties?.BoxX}
            onChange={handleSetBoxX}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.box_y")}
            value={light.LightProperties?.BoxY}
            onChange={handleSetBoxY}
          />
        </VuiBox>
        <VuiBox mt={1}>
          <VuiFormField
            mt={1}
            type="text"
            label={t("modules.lights.properties.source_distance")}
            value={light.LightProperties?.SourceDistance}
            onChange={handleSetSourceDistance}
          />
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default LightProperties;

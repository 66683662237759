import { useLayout } from "@/hooks/useLayout";
import React from "react";

import { Outlet } from "react-router-dom";

const CatalogManagementWindow = (props) => {
  useLayout(props.routeInfo);

  return <Outlet />;
};

export default CatalogManagementWindow;

/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import React, { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "../VuiBox";
import VuiTypography from "../VuiTypography";

// Vision UI Dashboard PRO React example components
import PageLayout from "../PageLayout";

// Authentication layout components
import Footer from "../Footer";
import VuiSelect from "../VuiSelect";

import { LANGUAGE_OPTIONS } from "@/i18n";
import { useTranslation } from "react-i18next";

function BasicLayout({ title, description, image, children }) {
  const [langValue, setLangValue] = useState(null);
  const { t, i18n } = useTranslation();

  const languageOptions = LANGUAGE_OPTIONS.map((option) => {
    return {
      ...option,
      label: t(`languages.${option.key}`),
    };
  });

  return (
    <PageLayout>
      <VuiBox
        sx={{
          backgroundImage: image && `url(${image})`,
          backgroundSize: "100% 50vh",
          // backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <VuiBox
          sx={{
            position: "absolute",
            top: 40,
            right: 40,
            ml: "auto",
            width: "10%",
          }}
        >
          <VuiSelect
            size="extraSmall"
            options={languageOptions}
            value={
              langValue ||
              languageOptions.find(
                (option) => option.value === i18n.resolvedLanguage,
              )
            }
            onChange={(option) => {
              i18n.changeLanguage(option.value);

              setLangValue({
                ...option,
                label: t(`languages.${option.key}`),
              });
            }}
          />
        </VuiBox>

        <Grid
          container
          spacing={3}
          mb={{ xs: "30px", lg: "40px" }}
          justifyContent="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item xs={10} lg={4}>
            <VuiBox mt={6} mb={1}>
              <VuiTypography
                variant="h1"
                color="white"
                fontWeight="bold"
                mb="20px"
              >
                {title}
              </VuiTypography>
            </VuiBox>
            <VuiBox
              mb={2}
              px={{ md: "160px", lg: "0px", xl: "60px", xxl: "140px" }}
            >
              <VuiTypography variant="body2" color="white" fontWeight="regular">
                {description}
              </VuiTypography>
            </VuiBox>
          </Grid>
        </Grid>
        <VuiBox
          display="flex"
          justifyContent="center"
          mx={{ xs: "auto", lg: "auto" }}
          maxWidth={{ xs: "90%", md: "436px" }}
        >
          {children}
        </VuiBox>
      </VuiBox>
      <Footer full alignEnd={false} />
    </PageLayout>
  );
}

// Setting default values for the props of BasicLayout
BasicLayout.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
